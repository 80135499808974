import React, { useState } from "react";
import { useGetReadyBionsQuery } from "../../../features/bion/bionApiSlice";
import BionCard from "../../../components/Bions/BionCard/BionCard";
import BionImg from "../../../assets/images/bion.png";
import { Button } from "../../../components/UI/Shadcn/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/UI/Shadcn/Card";
import { PlusCircle, Loader2, RefreshCw } from "lucide-react";

const Bions = () => {
  const {
    data: readyBions,
    isLoading,
    error,
    refetch,
  } = useGetReadyBionsQuery();
  const [isRefetching, setIsRefetching] = useState(false);

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  const runningProcesses =
    readyBions?.filter(
      (bion) =>
        bion.bionMode === "PROCESS_ACTIVE" ||
        bion.bionMode === "REPLICATION_ACTIVE"
    ).length || 0;

  const normalProcesses =
    readyBions?.filter((bion) => bion.bionMode === "PROCESS_ACTIVE").length ||
    0;

  const replicationProcesses =
    readyBions?.filter((bion) => bion.bionMode === "REPLICATE_ACTIVE").length ||
    0;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4 text-destructive">
        Error loading Bions. Please try again.
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 space-y-8">
      <header className="space-y-4">
        <div className="flex items-center gap-4">
          <img src={BionImg} alt="BioProcessor" className="w-12 h-12" />
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl text-foreground">
            Bions
          </h1>
        </div>
        <p className="text-muted-foreground max-w-3xl">
          Welcome to the BIONS Dashboard, your central hub for managing and
          monitoring your connected bioreactors. Stay informed about the status
          and performance of your bioreactors, empowering you to optimize their
          functionality and ensure smooth operations.
        </p>
      </header>

      <Card className="shadow-md bg-card">
        <CardHeader>
          <div className="flex justify-between items-center">
            <div>
              <CardTitle className="text-2xl">Overview</CardTitle>
              <CardDescription>Quick stats about your Bions</CardDescription>
            </div>
            <Button
              onClick={handleRefetch}
              disabled={isRefetching}
              variant="outline"
              className="transition-all duration-200 hover:bg-primary hover:text-primary-foreground"
            >
              {isRefetching ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <RefreshCw className="mr-2 h-4 w-4" />
              )}
              Refresh
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <StatCard title="Linked Bions" value={readyBions?.length || 0} />
            <StatCard title="Running Processes" value={runningProcesses} />
            <StatCard title="Normal Processes" value={normalProcesses} />
            <StatCard
              title="Replication Processes"
              value={replicationProcesses}
            />
          </div>
        </CardContent>
      </Card>

      <div className="flex justify-between items-center">
        <h2 className="scroll-m-20 text-2xl font-bold tracking-tight text-foreground">
          Your Bions
        </h2>
        <Button className="transition-all duration-200 hover:bg-primary hover:text-primary-foreground">
          <PlusCircle className="mr-2 h-4 w-4" />
          Add Bion
        </Button>
      </div>

      {readyBions && readyBions.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {readyBions.map((bion) => (
            <BionCard key={bion.bionId} bion={bion} />
          ))}
        </div>
      ) : (
        <Card className="p-8 text-center bg-card">
          <CardDescription>
            No Bions available. Try refreshing or add a new Bion.
          </CardDescription>
        </Card>
      )}
    </div>
  );
};

const StatCard = ({ title, value }) => (
  <Card className="transition-all duration-200 hover:shadow-lg bg-card">
    <CardContent className="p-4">
      <p className="text-sm font-medium text-muted-foreground mb-1">{title}</p>
      <p className="text-2xl font-bold text-foreground">{value}</p>
    </CardContent>
  </Card>
);

export default Bions;
