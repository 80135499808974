import Select from "react-select";

import ModalWrapper from "../../../Wrappers/ModalWrapper/ModalWrapper";
import styles from "./ManualData.module.css";

import { useStoreSensorDataMutation } from "../../../../features/sensor/sensorApiSlice";
import { useState } from "react";

const ManualData = ({ onClose, process }) => {
  const [selectedType, setSelectedType] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [value, setValue] = useState();
  const [storeSensorDataReq] = useStoreSensorDataMutation();

  const sensors = [
    { value: "CO2", label: "CO2", units: [5] },
    { value: "PH", label: "PH", units: [3] },
    { value: "ETHANOL", label: "ETHANOL", units: [4, 6] },
    { value: "OXYGEN", label: "OXYGEN", units: [5] },
    { value: "NITROGEN", label: "NITROGEN", units: [5] },
    { value: "TEMPERATURE", label: "TEMPERATURE", units: [0, 1, 2] },
  ];

  const units = [
    { value: "CELSIUS", label: "CELSIUS" },
    { value: "KELVIN", label: "KELVIN" },
    { value: "FAHRENHEIT", label: "FAHRENHEIT" },
    { value: "PH", label: "PH" },
    { value: "VOLUME_PERCENT", label: "VOLUME_PERCENTA" },
    { value: "SATURATION_PERCENT", label: "SATURATION_PERCENT" },
    { value: "PPM", label: "PPM" },
  ];

  const sendDataHandler = async () => {
    await storeSensorDataReq({
      processId: process.processId,
      sensorType: selectedType,
      sensorValue: +value,
      sensorUnit: selectedUnit,
    });
  };

  const selectedSensor = sensors.find(
    (sensor) => sensor.value === selectedType
  );

  let filteredOptions = [];
  if (selectedSensor) {
    const sensorUnits = selectedSensor.units;
    filteredOptions = sensorUnits.map((unitIdx) => units[unitIdx]);
  }

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles["main_container"]}>
        <div>
          <div>
            <p>Sensor Type</p>
            <Select
              onChange={(selectedOption) =>
                setSelectedType(selectedOption.value)
              }
              options={sensors}
              classNames={{
                control: (state) => styles["control"],
                menu: () => styles["menu"],
                input: () => styles["input"],
              }}
            />
          </div>
          <div>
            <label style={{ display: "block" }}>Value</label>
            <input
              onChange={(event) => {
                setValue(event.currentTarget.value);
              }}
            />
          </div>
          <div>
            <p>Sensor Unit</p>
            <Select
              onChange={(selectedOption) =>
                setSelectedUnit(selectedOption.value)
              }
              options={filteredOptions}
              classNames={{
                control: (state) => styles["control2"],
                menu: () => styles["menu"],
                input: () => styles["input"],
              }}
            />
          </div>
        </div>
        <button onClick={sendDataHandler}>Submit Data</button>
      </div>
    </ModalWrapper>
  );
};

export default ManualData;
