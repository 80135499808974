import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Animate } from "react-move";
import { easeQuadInOut } from "d3-ease";

import styles from "./CircularProgressBar.module.css";

class AnimatedProgressProvider extends React.Component {
  interval = undefined;

  state = {
    isAnimated: false,
  };

  static defaultProps = {
    valueStart: 0,
  };

  componentDidMount() {
    if (this.props.repeat) {
      this.interval = window.setInterval(() => {
        this.setState({
          isAnimated: !this.state.isAnimated,
        });
      }, this.props.duration * 1000);
    } else {
      this.setState({
        isAnimated: !this.state.isAnimated,
      });
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  render() {
    return (
      <Animate
        start={() => ({
          value: this.props.valueStart,
        })}
        update={() => ({
          value: [
            this.state.isAnimated ? this.props.valueEnd : this.props.valueStart,
          ],
          timing: {
            duration: this.props.duration * 1000,
            ease: this.props.easingFunction,
          },
        })}
      >
        {({ value }) => this.props.children(value)}
      </Animate>
    );
  }
}

const CircularProgressBar = ({ children, value, unit, colorRanges = [] }) => {
  const maxValue = colorRanges[colorRanges.length - 1].max;

  const getColor = () => {
    for (const range of colorRanges) {
      if (value >= range.min && value <= range.max) {
        return range.color;
      }
    }
    return "#fff";
  };

  const getProgress = (value) => {
    return (value / maxValue) * 100;
  };

  // Function to format the value with one decimal place if it has decimals
  const formatValue = (value) => {
    const oneDecimal = value.toFixed(1);
    return oneDecimal.endsWith(".0")
      ? Math.floor(value).toString()
      : oneDecimal;
  };

  return (
    <AnimatedProgressProvider
      valueStart={0}
      valueEnd={value ?? 0}
      duration={1.4}
      easingFunction={easeQuadInOut}
      // repeat={true}
    >
      {(curValue) => {
        // const roundedValue = Math.round(curValue);
        const displayValue = formatValue(curValue);
        return (
          <div className={styles["circular-progress-bar__container"]}>
            <CircularProgressbarWithChildren
              value={getProgress(curValue)}
              styles={buildStyles({
                pathColor: getColor(),
                trailColor: "#4A4A4A",

                /* This is important to include, because if you're fully managing the
                animation yourself, you'll want to disable the CSS animation. */
                pathTransition: "none",
              })}
            >
              <span>
                {value === null || value === undefined
                  ? "-"
                  : `${displayValue}${unit ? unit : ""}`}
              </span>
            </CircularProgressbarWithChildren>
            <p className={styles["level-name"]}>{children}</p>
          </div>
        );
      }}
    </AnimatedProgressProvider>
  );
};

export default CircularProgressBar;
