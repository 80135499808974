import { Outlet } from "react-router-dom";

import styles from "./BionsManagementAppWrapper.module.css";

const BionsManagementAppWrapper = () => {
  return (
    <div className={styles["bions-management__app-wrapper"]}>
      <Outlet />
    </div>
  );
};

export default BionsManagementAppWrapper;
