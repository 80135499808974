import { useEffect } from "react";

import { HiOutlineDevicePhoneMobile as PhoneIcon } from "react-icons/hi2";
import { RiSignalTowerFill as BionIcon } from "react-icons/ri";
import { IoMdCheckmark as CheckIcon } from "react-icons/io";
import { BounceLoader } from "react-spinners";

import { useLazyGetUserDistanceFromBionQuery } from "../../../features/bion/bionApiSlice";

import useGeolocationAPI from "../../../hooks/use-geolocation";

import styles from "./Step2.module.css";

const Step2 = ({ bionId, onSubmit }) => {
  // ==> 1) Don't forget to use the unused values
  const {
    coords,
    positionError,
    isGeolocationAvailable,
    stopWatchingGeolocation,
  } = useGeolocationAPI({
    watchPosition: true,
  });

  const [getUserDistanceFromBionQuery, { data }] =
    useLazyGetUserDistanceFromBionQuery();

  useEffect(() => {
    if (coords) {
      getUserDistanceFromBionQuery({
        bionId: bionId,
        longitude: coords.longitude,
        latitude: coords.latitude,
      });
    }
  }, [coords, bionId, getUserDistanceFromBionQuery]);

  useEffect(() => {
    if (data?.valid) {
      stopWatchingGeolocation();
      onSubmit(coords);
    }
  }, [data?.valid, stopWatchingGeolocation, coords, onSubmit]);

  let ComponentToRender;
  if (!data) {
    // Display loading spinner when data is not available yet
    ComponentToRender = <BounceLoader color="#fcfcfc" />;
  } else if (!data?.valid) {
    ComponentToRender = (
      <>
        <p className={styles["help"]}>
          Please come closer to the machine to continue.
        </p>
        <p className={styles["distance"]}>
          <span>{data.distance}</span> away
        </p>
      </>
    );
  }

  return (
    <div className={`${styles["step"]} ${data?.valid ? styles["valid"] : ""}`}>
      <h3 className={styles["step-info"]}>
        To ensure security and accuracy, we need to track your location. Please
        enable location services on your device.
      </h3>

      <div className={styles["mid-screen__container"]}>
        <div className={styles["center__container"]}>{ComponentToRender}</div>

        <div className={styles["middle-line__top"]}></div>
        <div className={styles["middle-line__bottom"]}></div>

        <div className={styles["top-part"]}>
          <div className={styles["line"]}></div>
          <div className={styles["bion-place"]}>
            <div className={styles["icon"]}>
              <BionIcon />
            </div>
            <p>Bion</p>
          </div>
        </div>

        <div className={styles["bottom-part"]}>
          <div className={styles["user-place"]}>
            <div className={styles["icon"]}>
              <PhoneIcon />
            </div>
            <p>You</p>
          </div>
          <div className={styles["line"]}></div>
        </div>

        <div className={styles["center__container"]}>
          <div>
            <CheckIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
