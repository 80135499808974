import ActuatorButton from "../../ActuatorButton/ActuatorButton";

import VacuumImg from "../../../../../assets/images/Actuators/vacuum.png";

import styles from "./Vacuum.module.css";

const Vacuum = ({ process, sendActuatorData }) => {
  return (
    <ActuatorButton
      enabled={process.vacuumState}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Vacuum</h5> */}
            <img src={VacuumImg} alt="Vacuum" />
          </div>
        );
      }}
      onClick={sendActuatorData.bind(null, {
        actuatorName: "vacuum",
        vacuumState: !process.vacuumState,
      })}
    />
  );
};

export default Vacuum;
