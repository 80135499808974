import Select from "react-select";

import styles from "./PlannerSelect.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const getOptions = (options, name) => {
  return options[name];
};

const PlannerSelect = ({
  dataItem,
  itemIdx,
  placeholderName,
  options,
  selectedValuesChangeHandler,
}) => {
  const currentOptions = getOptions(options, placeholderName);

  // console.log(dataItem[placeholderName]);

  const currentValue = dataItem[placeholderName]
    ? currentOptions.find(
        (option) => option.valueBackend === dataItem[placeholderName]
      )
    : null;

  return (
    <Select
      options={getOptions(options, placeholderName)}
      value={
        dataItem[placeholderName]
          ? {
              label: currentValue.value,
              value: currentValue.value,
            }
          : null
      }
      classNames={{
        container: () => cx("select__container"),
        control: () => cx(["select__control"]),
        indicatorSeparator: () => cx("select__seperator"),
        indicatorsContainer: () => cx(["select__indicator-container"]),
        input: () => cx("select__input"),
        singleValue: () => cx("select__single-value"),
        valueContainer: () => cx("select__value-container"),
        menu: () => cx("select__menu"),
        menuList: () => cx("select__menu-list"),
        option: ({ isFocused, isSelected }) => {
          return cx("select__option", {
            "select__option-is-focused": isFocused,
            "select__option-is-selected": isSelected,
          });
        },
      }}
      onChange={({ valueBackend }) => {
        selectedValuesChangeHandler(itemIdx, placeholderName, valueBackend);
      }}
    />
  );
};

export default PlannerSelect;
