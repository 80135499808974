import useTimer from "../../../hooks/use-timer";

import styles from "./Timer.module.css";

const Timer = ({ shutDownDate }) => {
  const timeLeftBeforeDisconnecting =
    10 * 60 - Math.floor((new Date() - new Date(shutDownDate)) / 1000);

  const { secondsLeft } = useTimer(timeLeftBeforeDisconnecting, {
    startAutomatically: true,
    direction: "desc",
  });

  // Function to convert seconds into "mm:ss" format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div
      className={styles["circular-progress"]}
      style={{
        background: `conic-gradient(#A6CEE3 ${
          (secondsLeft / 600) * 100
        }%, #262626 0deg)`,
      }}
    >
      <p className={styles["text-time"]}>{formatTime(secondsLeft)}</p>
    </div>
  );
};

export default Timer;
