import React from "react";

import styles from "./PlannerInput.module.css";

import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const PlannerInput = ({
  dataItem,
  itemIdx,
  placeholderName,
  selectedValuesChangeHandler,
}) => {
  return (
    <input
      className={cx("input")}
      value={dataItem[placeholderName]}
      onChange={(event) => {
        selectedValuesChangeHandler(
          itemIdx,
          placeholderName,
          event.target.value
        );
      }}
    />
  );
};

export default PlannerInput;
