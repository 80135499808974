import ModalWrapper from "../../../Wrappers/ModalWrapper/ModalWrapper";
import { motion } from "framer-motion";

import {
  useFinishAndSaveProcessMutation,
  useFinishAndDeleteProcessMutation,
} from "../../../../features/process/processApiSlice";

import styles from "./FinishProcess.module.css";
import LoadingText from "../../../UI/LoadingText/LoadingText";

const FinishProcess = ({ process, bionId, onClose }) => {
  const [
    finishAndSaveProcessReq,
    { isLoading: finishAndSaveProcessReqIsLoading },
  ] = useFinishAndSaveProcessMutation();
  const [
    finishAndDeleteProcessReq,
    { isLoading: finishAndDeleteProcessReqIsLoading },
  ] = useFinishAndDeleteProcessMutation();

  const finishAndSaveProcessHandler = () => {
    finishAndSaveProcessReq({ processId: process.processId });
  };

  const finishAndDeleteProcessHandler = () => {
    finishAndDeleteProcessReq({ processId: process.processId, bionId: bionId });
  };

  // console.log(finishAndSaveProcessReqIsLoading);

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles["finish-process__modal"]}>
        <h4>Finish Process Confirmation</h4>
        <p>Before finishing, please choose how to handle your process data:</p>
        <p className={styles["info"]}>
          <span>Save Data & Finish:</span> Securely store your process data and
          complete the process.
        </p>
        <p className={styles["info"]}>
          <span>Finish Without Saving:</span> Proceed without saving your
          process data, which will be permanently deleted.
        </p>
        <div className={styles["action-btns"]}>
          {!process?.isPure && (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 1 }}
              transition={{ type: "spring", duration: 0.5, bounce: 0.5 }}
              onClick={finishAndSaveProcessHandler}
            >
              {finishAndSaveProcessReqIsLoading ? (
                <LoadingText />
              ) : (
                "Save Data & Finish"
              )}
            </motion.button>
          )}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            onClick={finishAndDeleteProcessHandler}
          >
            {finishAndDeleteProcessReqIsLoading ? (
              <LoadingText />
            ) : (
              "Finish Without Saving"
            )}
          </motion.button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default FinishProcess;
