import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import { aiApiSlice } from "./api/aiApiSlice";
import { aiApiSlice as aiApiSlice2 } from "./api/aiApiSlice2";

import authReducer from "../features/auth/authSlice";
// import processReducer from "../features/process/processSlice";
// import uiReducer from "../features/ui/uiSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    // Add the generated reducer as a specific top-level slice
    [apiSlice.reducerPath]: apiSlice.reducer,
    [aiApiSlice.reducerPath]: aiApiSlice.reducer,
    [aiApiSlice2.reducerPath]: aiApiSlice2.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      aiApiSlice.middleware,
      aiApiSlice2.middleware
    ),
  // .concat(aiApiSlice.middleware),
  devTools: true,
});

// const combinedReducer = combineReducers({
//   auth: authReducer,
//   ui: uiReducer,
//   process: processReducer,
//   [apiSlice.reducerPath]: apiSlice.reducer,
// });

// const rootReducer = (state, action) => {
//   if (action.type === "auth/logOut") {
//     localStorage.removeItem("accessToken");
//     localStorage.removeItem("refreshToken");
//     state = { auth: {}, ui: {}, process: {} };
//   }

//   return combinedReducer(state, action);
// };

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
//   devTools: true,
// });
