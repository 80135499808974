import { useEffect, useRef, memo, useState } from "react";
import { useDispatch } from "react-redux";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import styles from "./Chart.module.css";

import { useGetSensorDataForProcessQuery } from "../../../../features/sensor/sensorApiSlice";

const Chart = ({ bion, processId }) => {
  const [singleCharts, setSingleCharts] = useState(true);
  const chartRefs1 = useRef(new Array(6));
  const [subscribedToPusher, setSubscribedToPusher] = useState(false);

  const dispatch = useDispatch();

  const {
    data: sensorsData = [],
    isSuccess: getSensorDataForProcessIsSuccess,
  } = useGetSensorDataForProcessQuery(processId);

  // Extract data from sensorsData and format it for Highcharts
  const formattedSensorsData = sensorsData.map((sensor) => {
    return {
      name: sensor.name,
      data: sensor.data.map((point) => [
        new Date(point[0]).getTime(), // DateTime Stamp
        // new Date(point[0]), // DateTime Stamp
        point[1], // Sensor value
      ]),
    };
  });

  // Check if the sensor data retrieval was successful
  // useEffect(() => {
  //   console.log("run");
  //   if (!getSensorDataForProcessIsSuccess && !chartRefs1.current[0]) {
  //     console.log("return");
  //     return;
  //   }

  //   // if (subscribedToPusher) return;

  //   // Initialize Pusher for real-time updates
  //   pusher.initializePusher();

  //   setSubscribedToPusher(true);

  //   console.log("subscribing!");

  //   // Subscribe to a private Pusher channel for each sensor
  //   pusher.subscribeToChannels(
  //     {
  //       channelName: `private-sensors-channel-${bion.bionId}`,
  //       eventName: "sensors-status-changed",
  //     },
  //     (receivedData) => {
  //       console.log(chartRefs1.current[0]);
  //       // Process received data for the corresponding sensor and update the chart
  //       formattedSensorsData.forEach((sensorData, index) => {
  //         const dataPoint = [
  //           new Date(receivedData.createdDate).getTime(),
  //           receivedData[sensorData.name.toLowerCase()],
  //         ];

  //         chartRefs1.current[index].chart.series[0].addPoint(dataPoint);
  //       });

  //       dispatch(
  //         apiSlice.util.updateQueryData(
  //           "getSensorDataForProcess",
  //           processId,
  //           (draftSensors) => {
  //             draftSensors.forEach((draftSensor) => {
  //               const draftSensorName =
  //                 draftSensor.name.charAt(0).toLowerCase() +
  //                 draftSensor.name.slice(1);

  //               if (draftSensorName in receivedData) {
  //                 draftSensor.data.push([
  //                   receivedData.createdDate,
  //                   receivedData[draftSensorName],
  //                 ]);
  //               }
  //             });
  //           }
  //         )
  //       );
  //     }
  //   );

  //   // Unsubscribe from the Pusher channels when the component is unmounted
  //   return () => {
  //     console.log("hiiii");
  //     // pusher.unsubscribeFromChannels(`private-sensors-channel-${bion.bionId}`);
  //   };
  // }, [getSensorDataForProcessIsSuccess, formattedSensorsData, bion.bionId]);

  const options = {
    chart: {
      type: "spline",
      backgroundColor: "#101010",
      zoomType: "x",
    },
    title: {
      text: "Real-Time Sensor Insights",
      align: "left",
      style: {
        color: "#fff",
      },
    },
    // subtitle: {
    // text: "Monitoring Bioreactor Metrics as They Happen",
    // align: "left",
    // },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        enabled: true,
        format: "{value:%d/%m/%y %H:%M}",
        rotation: 20,
        style: { color: "#fff" },
      },
      title: {
        text: "Date",
        style: {
          color: "#fff",
        },
      },
      // tickInterval: 60 * 1000,
    },
    yAxis: {
      title: {
        text: "Values",
        style: {
          color: "#fff",
        },
      },
      labels: {
        style: { color: "#fff" },
      },
      min: 0,
      max: 100,
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      pointFormat: "<p>{point.x:%e. %b}: {point.y:.2f}</p>",
      // shared: true,
      // crosshairs: true,
    },
    // legend: { itemStyle: { color: "#fcfcfc", opacity: 1 } },
    legend: { enabled: false },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle",
          fillColor: "#FFFFFF",
          enabled: true,
          radius: 2.5,
          lineWidth: 1,
          lineColor: null,
        },
      },
    },

    colors: ["#00FFFF", "#39FF14", "#800080", "#DC143C", "#FFD700", "#40E0D0"],

    // series: [...formattedSensorsData],
  };

  const addChartRef1 = (index, element) => {
    chartRefs1.current[index] = element;
  };

  return (
    <div>
      <div className={styles["toggle-btns"]}>
        <button
          className={`${singleCharts ? styles["active"] : ""}`}
          onClick={() => {
            setSingleCharts(true);
          }}
        >
          Single
        </button>
        <button
          onClick={() => setSingleCharts(false)}
          className={`${!singleCharts ? styles["active"] : ""}`}
        >
          Combined
        </button>
      </div>
      <div className={styles["chart__container"]}>
        {singleCharts ? (
          formattedSensorsData.length &&
          formattedSensorsData.map((sensorData, index) => (
            <div
              key={sensorData.name}
              className={styles["chart__single__container"]}
            >
              <HighchartsReact
                ref={addChartRef1.bind(null, index)}
                highcharts={Highcharts}
                options={{
                  ...options,
                  title: {
                    text: `${sensorData.name}`,
                    align: "left",
                    style: {
                      color: "#fff",
                    },
                  },
                  yAxis: {
                    title: {
                      text: "Values",
                      style: {
                        color: "#fff",
                      },
                    },
                    labels: {
                      style: { color: "#fff" },
                    },
                    min: 0,
                    max:
                      sensorData.name === "Temperature"
                        ? 40
                        : sensorData.name === "Acidity"
                        ? 14
                        : 100,
                    tickInterval: sensorData.name === "Acidity" ? 2 : undefined,
                  },
                  series: [
                    {
                      name: sensorData.name,
                      data: sensorData.data,
                    },
                  ],
                }}
              />
            </div>
          ))
        ) : (
          <>
            <div
              className={styles["chart__single__container"]}
              style={{ width: "100%" }}
            >
              <HighchartsReact
                key="Multi"
                highcharts={Highcharts}
                options={{
                  ...options,
                  title: {
                    text: `Sensors`,
                    align: "left",
                    style: {
                      color: "#fff",
                    },
                  },
                  yAxis: {
                    title: {
                      text: "Values",
                      style: {
                        color: "#fff",
                      },
                    },
                    labels: {
                      style: { color: "#fff" },
                    },
                    min: 0,
                    max: 100,
                  },
                  legend: {
                    enabled: true,
                    itemStyle: {
                      color: "#fcfcfc",
                      opacity: 1,
                      fontSize: "16px",
                    },
                    itemHoverStyle: { color: "gray" },
                    verticalAlign: "top",
                    itemMarginBottom: 30,
                  },
                  // series: [{ name: sensorData.name, data: sensorData.data }],
                  series: formattedSensorsData,
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Chart);
