import ActuatorButton from "../../ActuatorButton/ActuatorButton";

import PhImg from "../../../../../assets/images/Actuators/ph.png";

import styles from "./Ph.module.css";

const Ph = ({ process, sendActuatorData }) => {
  return (
    <ActuatorButton
      enabled={process?.vacuumState}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Vacuum</h5> */}
            <img src={PhImg} alt="Ph" />
          </div>
        );
      }}
      //   onClick={sendActuatorData.bind(null, {
      // actuatorName: "vacuum",
      // vacuumState: !process.vacuumState,
      //   })}
    />
  );
};

export default Ph;
