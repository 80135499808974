//src/features/sensor/sensorApiSlice.js

import { apiSlice } from "../../app/api/apiSlice";

export const sensorApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // Retrieves the status of the sensor calibration
    getSensorCalibrationStatus: build.query({
      query: (bionId) => `/sensors/calibration?bionId=${bionId}`,
    }),

    // Updates the sensor calibration status
    updateSensorCalibrationStatus: build.mutation({
      query: ({ bionId, calibrationActive, sensorType }) => ({
        url: "/sensors/calibration",
        method: "PUT",
        body: { bionId, calibrationActive, sensorType },
      }),
    }),

    // Stores manually measured sensor data with external sensors
    storeSensorData: build.mutation({
      query: ({ processId, sensorType, sensorValue, sensorUnit }) => ({
        url: "/sensors/external",
        method: "POST",
        body: { processId, sensorType, sensorValue, sensorUnit },
      }),
    }),

    startSensorCalibration: build.mutation({
      query: ({ bionId, calibrationActive, sensorType, step }) => ({
        url: "/sensors/calibration",
        method: "POST",
        body: { bionId, calibrationActive, sensorType, step },
      }),
    }),

    // Obtains all data of the sensors for a specific process
    getSensorDataForProcess: build.query({
      query: (processId) => `/sensors/${processId}`,
      keepUnusedDataFor: 0,
      transformResponse: (responseData) => {
        // Define the types of sensors present in the data
        const sensors = [
          "temperature",
          "oxygen",
          "volume",
          "ethanol",
          "acidity",
          "carbonDioxide",
        ];

        // Create the output array using the sensors and mapping over each sensor to create the desired structure
        const transformedData = sensors.map((sensor) => {
          return {
            // Convert the sensor to title case
            name: sensor.charAt(0).toUpperCase() + sensor.slice(1),
            // Map over each object in the input array to create an array of [Date, value] pairs
            data: responseData.map((data) => [
              // new Date(data.createdDate).toISOString(),
              data.createdDate,
              data[sensor],
            ]),
          };
        });

        return transformedData;
      },
    }),
  }),
});

export const {
  useGetSensorDataForProcessQuery,
  useStoreSensorDataMutation,
  useStartSensorCalibrationMutation,
} = sensorApiSlice;
