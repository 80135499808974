import { apiSlice } from "../../app/api/apiSlice";

export const actuatorApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getActuatorData: build.query({
      query: (processId) => `/actuators/${processId}`,
      transformResponse: (responseData) => {
        // console.log(responseData);
        const transformedData = [];
        transformedData.push({
          name: "Light Actuator",
          data: responseData.lightActuatorList.map((light) => [
            light.createdDate,
            light.lightBright,
          ]),
        });
        transformedData.push({
          name: "Stir Actuator",
          data: responseData.stirActuatorList.map((stir) => [
            stir.createdDate,
            stir.stirringSpeed,
          ]),
        });
        transformedData.push({
          name: "Temperature Actuator",
          data: responseData.temperatureActuatorList.map((temp) => [
            temp.createdDate,
            temp.temperatureValue,
          ]),
        });
        // transformedData.push({
        //   name: "Gas Actuator",
        //   data: responseData.gasActuatorList.map((gas) => [
        //     gas.createdDate,
        //     gas.gasserValue,
        //   ]),
        // });
        transformedData.push({
          name: "UltraSound Actuator",
          data: responseData.ultraSoundActuatorList.map((ultraSound) => [
            ultraSound.createdDate,
            ultraSound.ultraSoundState ? 1 : 0,
          ]),
        });
        transformedData.push({
          name: "Vacuum Actuator",
          data: responseData.vacuumActuatorList.map((vacuum) => [
            vacuum.createdDate,
            vacuum.vacuumState ? 1 : 0,
          ]),
        });
        transformedData.push({
          name: "UltraViolet Actuator",
          data: responseData.ultraVioletActuatorList.map((ultraViolet) => [
            ultraViolet.createdDate,
            ultraViolet.ultraVioletState ? 1 : 0,
          ]),
        });

        return transformedData;
      },
    }),
  }),
});

export const { useGetActuatorDataQuery } = actuatorApiSlice;
