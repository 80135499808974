import React, { useState } from "react";
import { Button } from "../../UI/Shadcn/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../UI/Shadcn/Table";
import { Input } from "../../UI/Shadcn/Input";
import { Badge } from "../../UI/Shadcn/Badge";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../UI/Shadcn/Card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../UI/Shadcn/Tabs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../UI/Shadcn/DropdownMenu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../UI/Shadcn/Dialog";
import {
  Search,
  ListFilter,
  FileDown,
  PlusCircle,
  MoreHorizontal,
  ArrowUpDown,
} from "lucide-react";
import { useToast } from "../../../hooks/use-toast";

const formatDuration = (durationInMs) => {
  const seconds = Math.floor(durationInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks > 0) return `${weeks} week${weeks > 1 ? "s" : ""}`;
  if (days > 0) return `${days} day${days > 1 ? "s" : ""}`;
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""}`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  return `${seconds} second${seconds !== 1 ? "s" : ""}`;
};

const MaximizeProduction = () => {
  const [flows, setFlows] = useState([
    {
      id: 1,
      processName: "Flow 1",
      status: "Active",
      startDate: "2023-09-01",
      finishDate: null,
      bioprocessDuration: 4320000000, // 50 days in milliseconds
    },
    {
      id: 2,
      processName: "Flow 2",
      status: "Draft",
      startDate: "2023-08-15",
      finishDate: null,
      bioprocessDuration: 2160000000, // 25 days in milliseconds
    },
    {
      id: 3,
      processName: "Flow 3",
      status: "Completed",
      startDate: "2023-07-30",
      finishDate: "2023-10-08",
      bioprocessDuration: 8640000000, // 100 days in milliseconds
    },
    {
      id: 4,
      processName: "Flow 4",
      status: "Active",
      startDate: "2023-09-10",
      finishDate: null,
      bioprocessDuration: 6480000000, // 75 days in milliseconds
    },
    {
      id: 5,
      processName: "Flow 5",
      status: "Draft",
      startDate: "2023-08-20",
      finishDate: null,
      bioprocessDuration: 0,
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("processName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [activeTab, setActiveTab] = useState("all");
  const { toast } = useToast();

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedFlows = [...flows].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const filteredFlows = sortedFlows
    .filter((flow) =>
      flow.processName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((flow) => {
      if (activeTab === "all") return true;
      return flow.status.toLowerCase() === activeTab;
    });

  const handleCreateFlow = (flowName) => {
    const newFlow = {
      id: flows.length + 1,
      processName: flowName,
      status: "Draft",
      startDate: new Date().toISOString().split("T")[0],
      finishDate: null,
      bioprocessDuration: 0,
    };
    setFlows([...flows, newFlow]);
    toast({
      title: "Flow Created",
      description: `New flow "${flowName}" has been created.`,
    });
  };

  const handleDeleteFlow = (id) => {
    setFlows(flows.filter((flow) => flow.id !== id));
    toast({
      title: "Flow Deleted",
      description: "The flow has been deleted successfully.",
      variant: "destructive",
    });
  };

  return (
    <div className="p-6 bg-background text-foreground">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-6">
        <h1 className="text-3xl font-bold tracking-tight mb-4 md:mb-0">
          Maximize Production
        </h1>
        <div className="w-full md:w-auto">
          <div className="relative">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search flows..."
              className="pl-8 w-full md:w-[300px]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <Tabs
        defaultValue="all"
        className="space-y-4"
        onValueChange={setActiveTab}
      >
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
          <TabsList>
            <TabsTrigger value="all">All Flows</TabsTrigger>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="draft">Draft</TabsTrigger>
            <TabsTrigger value="completed">Completed</TabsTrigger>
          </TabsList>
          <div className="flex items-center space-x-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="h-8 hover:bg-primary hover:text-primary-foreground transition-colors"
                >
                  <ListFilter className="mr-2 h-4 w-4" />
                  Filter
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[200px]">
                <DropdownMenuLabel>Filter by Status</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>Active</DropdownMenuItem>
                <DropdownMenuItem>Draft</DropdownMenuItem>
                <DropdownMenuItem>Completed</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <Button
              variant="outline"
              size="sm"
              className="h-8 hover:bg-primary hover:text-primary-foreground transition-colors"
            >
              <FileDown className="mr-2 h-4 w-4" />
              Export
            </Button>
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  size="sm"
                  className="h-8 bg-primary text-primary-foreground hover:bg-primary/90 transition-colors"
                >
                  <PlusCircle className="mr-2 h-4 w-4" />
                  New Flow
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Create New Flow</DialogTitle>
                  <DialogDescription>
                    Enter a name for your new production flow.
                  </DialogDescription>
                </DialogHeader>
                <Input id="name" placeholder="Flow name" />
                <DialogFooter>
                  <Button
                    type="submit"
                    onClick={() =>
                      handleCreateFlow(document.getElementById("name").value)
                    }
                    className="bg-primary text-primary-foreground hover:bg-primary/90 transition-colors"
                  >
                    Create
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <TabsContent value="all" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle>All Flows</CardTitle>
              <CardDescription>
                A list of all your production flows. Manage and monitor your
                flows here.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead
                      className="cursor-pointer hover:bg-muted/50 transition-colors"
                      onClick={() => handleSort("processName")}
                    >
                      Name
                      {sortColumn === "processName" && (
                        <ArrowUpDown className="ml-2 h-4 w-4 inline" />
                      )}
                    </TableHead>
                    <TableHead
                      className="cursor-pointer hover:bg-muted/50 transition-colors"
                      onClick={() => handleSort("status")}
                    >
                      Status
                      {sortColumn === "status" && (
                        <ArrowUpDown className="ml-2 h-4 w-4 inline" />
                      )}
                    </TableHead>
                    <TableHead
                      className="cursor-pointer hover:bg-muted/50 transition-colors"
                      onClick={() => handleSort("startDate")}
                    >
                      Start Date
                      {sortColumn === "startDate" && (
                        <ArrowUpDown className="ml-2 h-4 w-4 inline" />
                      )}
                    </TableHead>
                    <TableHead
                      className="cursor-pointer hover:bg-muted/50 transition-colors"
                      onClick={() => handleSort("finishDate")}
                    >
                      Finish Date
                      {sortColumn === "finishDate" && (
                        <ArrowUpDown className="ml-2 h-4 w-4 inline" />
                      )}
                    </TableHead>
                    <TableHead
                      className="cursor-pointer hover:bg-muted/50 transition-colors"
                      onClick={() => handleSort("bioprocessDuration")}
                    >
                      Duration
                      {sortColumn === "bioprocessDuration" && (
                        <ArrowUpDown className="ml-2 h-4 w-4 inline" />
                      )}
                    </TableHead>
                    <TableHead className="text-right">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredFlows.map((flow) => (
                    <TableRow
                      key={flow.id}
                      className="hover:bg-muted/50 transition-colors"
                    >
                      <TableCell className="font-medium">
                        {flow.processName}
                      </TableCell>
                      <TableCell>
                        <Badge
                          variant={
                            flow.status === "Active"
                              ? "default"
                              : flow.status === "Draft"
                              ? "secondary"
                              : "outline"
                          }
                        >
                          {flow.status}
                        </Badge>
                      </TableCell>
                      <TableCell>{flow.startDate}</TableCell>
                      <TableCell>{flow.finishDate || "-"}</TableCell>
                      <TableCell>
                        {formatDuration(flow.bioprocessDuration)}
                      </TableCell>
                      <TableCell className="text-right">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="ghost"
                              className="h-8 w-8 p-0 hover:bg-muted/50 transition-colors"
                            >
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem className="cursor-pointer hover:bg-muted/50 transition-colors">
                              Edit Flow
                            </DropdownMenuItem>
                            <DropdownMenuItem className="cursor-pointer hover:bg-muted/50 transition-colors">
                              Duplicate Flow
                            </DropdownMenuItem>
                            <DropdownMenuItem className="cursor-pointer hover:bg-muted/50 transition-colors">
                              Archive Flow
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              className="text-destructive focus:text-destructive cursor-pointer hover:bg-destructive/10 transition-colors"
                              onClick={() => handleDeleteFlow(flow.id)}
                            >
                              Delete Flow
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter>
              <div className="text-sm text-muted-foreground">
                Showing <strong>{filteredFlows.length}</strong> out of{" "}
                <strong>{flows.length}</strong> flows
              </div>
            </CardFooter>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default MaximizeProduction;
