import ModalWrapper from "../../../Wrappers/ModalWrapper/ModalWrapper";
import { motion } from "framer-motion";

import { useFinishReplicatingProcessMutation } from "../../../../features/process/processApiSlice";

import styles from "./FinishProcess.module.css";
import LoadingText from "../../../UI/LoadingText/LoadingText";

const FinishProcess = ({ process, bionId, onClose }) => {
  const [
    finishReplicationProcessReq,
    { isLoading: finishReplicationProcessReqIsLoading },
  ] = useFinishReplicatingProcessMutation();

  const finishAndSaveProcessHandler = () => {
    finishReplicationProcessReq({ processId: process.processId });
  };

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles["finish-process__modal"]}>
        <h4>Finish Replication Process Confirmation</h4>
        <p>Are you sure you want to finish the process?</p>
        <div className={styles["action-btns"]}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            transition={{ type: "spring", duration: 0.5, bounce: 0.5 }}
            onClick={finishAndSaveProcessHandler}
          >
            {finishReplicationProcessReqIsLoading ? <LoadingText /> : "Finish"}
          </motion.button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default FinishProcess;
