import { useState } from "react";

import PlannerSelect from "../components/Bion/CreateProcess/ProcessPlanner/UI/PlannerSelect/PlannerSelect";
import PlannerInput from "../components/Bion/CreateProcess/ProcessPlanner/UI/PlannerInput/PlannerInput";

const usePromptEngine = (
  prompt,
  options,
  dataItems,
  setDataItems,
  globalName,
  linkedName = null,
  nestedIdx
) => {
  const selectedValuesChangeHandler = (itemIdx, name, value) => {
    const keysToRemove = [...prompt.matchAll(/{[^}:]+:([^}]+)}/g)]
      .map(([, keyName]) => keyName)
      .filter((_, idx, arr) => idx > arr.indexOf(name));

    setDataItems((prevState) => {
      const newState = { ...prevState };

      const updateItemsArray = (items) =>
        items.map((item, idx) =>
          idx !== itemIdx
            ? item
            : {
                ...Object.entries(item)
                  .filter(([key]) => !keysToRemove.includes(key))
                  .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {}),
                [name]: value,
              }
        );

      const updateNestedArray = (
        nestedArray,
        nestedIdx,
        itemIdx,
        name,
        value
      ) =>
        nestedArray.map((arr, idx) =>
          idx !== nestedIdx
            ? arr
            : arr.map((item, idx) =>
                idx !== itemIdx ? item : { ...item, [name]: value }
              )
        );

      const updateState = (state, key, nestedIdx = null) => {
        if (key === "MEDIA_LINE_CTRL" && nestedIdx !== null) {
          state[key] = updateNestedArray(
            state[key],
            nestedIdx,
            itemIdx,
            name,
            value
          );
        } else if (Array.isArray(state[key])) {
          state[key] = updateItemsArray(state[key]);
        } else if (typeof state[key] === "object" && state[key] !== null) {
          for (const nestedKey in state[key]) {
            if (Array.isArray(state[key][nestedKey])) {
              state[key][nestedKey] = updateItemsArray(state[key][nestedKey]);
            }
          }
        } else {
          console.warn(
            `The key "${key}" does not point to an array or an object.`
          );
        }
      };

      if (newState[globalName]) {
        updateState(newState, globalName, nestedIdx);
      } else {
        let found = false;
        for (const controlName in newState) {
          if (newState[controlName][globalName]) {
            updateState(newState[controlName], globalName, nestedIdx);
            found = true;
            break;
          }
        }
        if (!found) {
          console.warn(`globalName "${globalName}" not found in the state.`);
        }
      }

      return newState;
    });
  };

  const tokenize = (input) => {
    const regex =
      /\s*(=>|&&|\|\||==|!=|[()=]|[^()\s=&&\|\|!]+(?:\s+[^()\s=&&\|\|!]+)*)\s*/g;
    return input.match(regex).filter((token) => token.trim());
  };

  function parse(tokens) {
    let position = 0;

    function parseExpression() {
      let node = parseTerm();
      while (position < tokens.length && tokens[position] === "||") {
        const operator = tokens[position++];
        const right = parseTerm();
        node = { type: "LogicalExpression", operator, left: node, right };
      }
      return node;
    }

    function parseTerm() {
      let node = parseFactor();
      while (position < tokens.length && tokens[position] === "&&") {
        const operator = tokens[position++];
        const right = parseFactor();
        node = { type: "LogicalExpression", operator, left: node, right };
      }
      return node;
    }

    function parseFactor() {
      if (tokens[position] === "(") {
        position++; // Consume '('
        const node = parseExpression();
        if (tokens[position] === ")") {
          position++; // Consume ')'
        } else {
          throw new SyntaxError("Expected closing parenthesis");
        }
        return node;
      }
      return parseCondition();
    }

    function parseCondition() {
      const left = tokens[position++];
      if (tokens[position] === "==" || tokens[position] === "!=") {
        const operator = tokens[position++]; // Consume '==' or '!='
        const right = tokens[position++]; // Consume right value
        return { type: "BinaryExpression", operator, left, right };
      }
      // Handle standalone variables
      return { type: "Variable", name: left };
    }

    return parseExpression();
  }

  function evaluate(node, context) {
    switch (node.type) {
      case "LogicalExpression":
        if (node.operator === "||") {
          return evaluate(node.left, context) || evaluate(node.right, context);
        } else if (node.operator === "&&") {
          return evaluate(node.left, context) && evaluate(node.right, context);
        }
        break;
      case "BinaryExpression":
        const leftValue = node.left in context ? context[node.left] : false; // Get the value corresponding to the left key
        if (!leftValue) return node.operator === "!=" ? true : false;
        const rightValue = node.right;
        if (node.operator === "==") {
          return leftValue === rightValue;
        } else if (node.operator === "!=") {
          return leftValue !== rightValue;
        }
        break;
      case "Variable":
        return node.name in context && !!context[node.name];
      default:
        throw new SyntaxError("Invalid AST node");
    }
  }

  const renderPrompt = (itemIdx) => {
    const dataItem = dataItems[itemIdx];

    const subPrompts = prompt
      .replaceAll("@", itemIdx + 1)
      .split("\n")
      .filter((line) => line.trim() !== "")
      .slice(0, -2);

    return subPrompts.map((prompt) => {
      const condition = prompt.match(/\[(.*?)\]\s*$/)[1];

      if (condition && !evaluate(parse(tokenize(condition)), dataItem))
        return null;

      const cleanPrompt = prompt.replace(/\[(.*?)\]\s*$/, "");
      const parts = cleanPrompt.split(/({[^{}]+})/);

      return parts.map((part, index) => {
        if (!part.match(/{([^{}]+)}/)) return <span key={index}>{part}</span>;

        const placeholder = part.replace(/[{}]/g, "");
        const [type, placeholderName] = placeholder.split(":");

        switch (type) {
          case "select":
            return (
              <PlannerSelect
                dataItem={dataItem}
                itemIdx={itemIdx}
                placeholderName={placeholderName}
                options={options}
                selectedValuesChangeHandler={selectedValuesChangeHandler}
              />
            );
          case "input":
            return (
              <PlannerInput
                key={index}
                dataItem={dataItem}
                itemIdx={itemIdx}
                placeholderName={placeholderName}
                selectedValuesChangeHandler={selectedValuesChangeHandler}
              />
            );
        }
      });
    });
  };

  const checkItemForCompletion = (itemIdx) => {
    const dataItem = dataItems[itemIdx];
    const condition = prompt.match(/\$(.*?)\$/)[1];
    return evaluate(parse(tokenize(condition)), dataItem);
  };

  const checkAllItemsForCompletion = () => {
    return dataItems.every((_, idx) => checkItemForCompletion(idx));
  };

  const checkItemForStart = (itemIdx) => {
    const dataItem = dataItems[itemIdx];
    return Object.keys(dataItem).some((key) => Boolean(dataItem[key]));
  };

  const checkItemForCreation = (itemIdx, dataItems) => {
    const dataItem = dataItems[itemIdx];
    const condition = prompt.match(/\#(.*?)\#/)[1];
    return condition ? evaluate(parse(tokenize(condition)), dataItem) : true;
  };

  const addDataItem = () => {
    setDataItems((prevState) => {
      const newState = { ...prevState };

      const addToArray = (array) => [...array, {}];

      const addItemToState = (
        state,
        key,
        linkedKey = null,
        nestedIdx = null
      ) => {
        if (key === "MEDIA_LINE_CTRL" && nestedIdx !== null) {
          // Ensure MEDIA_LINE_CTRL exists and is an array
          if (Array.isArray(state[key])) {
            // Ensure nestedIdx is within bounds
            console.log(state[key], "hiiiiiiiiiiiiiii", nestedIdx);
            if (nestedIdx >= 0 && nestedIdx < state[key].length) {
              // Ensure the nested array exists and is an array
              if (Array.isArray(state[key][nestedIdx])) {
                state[key][nestedIdx] = [...state[key][nestedIdx], {}]; // Add new object to the nested array
              } else {
                console.warn(`Nested array at index ${nestedIdx} not found.`);
              }
            } else {
              console.warn(`Index ${nestedIdx} out of bounds for ${key}.`);
            }
          } else {
            console.warn(`${key} is not an array.`);
          }
        } else if (Array.isArray(state[key])) {
          state[key] = addToArray(state[key]);
          if (linkedKey && Array.isArray(state[linkedKey])) {
            console.log("heyyyy", state[linkedKey]);
            state[linkedKey] = [...state[linkedKey], [{}]];
          }
        } else if (typeof state[key] === "object" && state[key] !== null) {
          for (const nestedKey in state[key]) {
            if (Array.isArray(state[key][nestedKey])) {
              state[key][nestedKey] = addToArray(state[key][nestedKey]);
              if (
                linkedKey &&
                state[linkedKey] &&
                Array.isArray(state[linkedKey][nestedKey])
              ) {
                state[linkedKey][nestedKey] = [
                  ...state[linkedKey][nestedKey],
                  {},
                ];
              }
            }
          }
        } else {
          console.warn(
            `The key "${key}" does not point to an array or an object.`
          );
        }
      };

      if (newState[globalName]) {
        addItemToState(newState, globalName, linkedName, nestedIdx);
      } else {
        let found = false;
        for (const controlName in newState) {
          if (newState[controlName][globalName]) {
            addItemToState(
              newState[controlName],
              globalName,
              linkedName,
              nestedIdx
            );
            found = true;
            break;
          }
        }
        if (!found) {
          console.warn(`globalName "${globalName}" not found in the state.`);
        }
      }

      return newState;
    });
  };

  return {
    dataItems,
    renderPrompt,
    checkItemForCompletion,
    checkAllItemsForCompletion,
    checkItemForCreation,
    checkItemForStart,
    addDataItem,
  };
};

export default usePromptEngine;
