import { useNavigate } from "react-router-dom";
import { Button } from "./UI/Shadcn/Button";
import { Card, CardContent } from "./UI/Shadcn/Card";

export default function TrainingNotFound() {
  const navigate = useNavigate();

  return (
    <div className="bg-background min-h-screen flex items-center justify-center p-4">
      <Card className="w-full max-w-md overflow-hidden">
        <div className="bg-blue-500 text-white py-4 px-6 text-center text-xl font-semibold">
          Training Not Found
        </div>
        <CardContent className="text-center pt-8 pb-6">
          <div className="bg-blue-100 inline-flex rounded-full p-4 mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-blue-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <h2 className="text-xl font-semibold mb-2">
            Oops! We couldn't find that training.
          </h2>
          <p className="text-gray-600">
            The training you're looking for doesn't exist or has been removed.
          </p>
        </CardContent>
        <div className="px-6 pb-6 text-center">
          <Button
            onClick={() => navigate("/trainings")}
            className="bg-blue-500 text-white hover:bg-blue-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Back to Trainings
          </Button>
        </div>
      </Card>
    </div>
  );
}
