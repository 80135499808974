import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import ModalWrapper from "../../../../Wrappers/ModalWrapper/ModalWrapper";
import ActuatorButton from "../../ActuatorButton/ActuatorButton";
import Switch from "react-switch";
import UltravioletImg from "../../../../../assets/images/Actuators/ultraviolet.png";

import styles from "./Ultraviolet.module.css";

const ULTRAVIOLETS = [
  {
    label: "Type A",
    mode: "A",
  },
  { label: "Type C", mode: "C" },
];

const UltravioletModal = ({ process, sendActuatorData, onClose }) => {
  const [ultraVioletModes, setUltraVioletModes] = useState(
    process.ultraVioletModes || []
  );
  const [ultraVioletState, setUltraVioletState] = useState(
    process.ultraVioletModes && !!process.ultraVioletModes.length
  );

  // console.log(ultraVioletModes, ultraVioletState);

  // Animation related state variables
  const [modalAnimationComplete, setModalAnimationComplete] = useState(false);

  const sendActuatorDataHandler = () => {
    // Identify changes between ultraVioletModes (UI state) and process.ultraVioletModes (backend state)
    const changes = ultraVioletModes.filter((uiMode) => {
      const backendMode = process.ultraVioletModes.find(
        (backendMode) => backendMode.ultraVioletMode === uiMode.ultraVioletMode
      );
      // If the mode doesn't exist in the backend or the state is different, it's a change
      return (
        !backendMode || backendMode.ultraVioletState !== uiMode.ultraVioletState
      );
    });

    // Send a request for each change
    changes.forEach((change) => {
      // console.log(change);
      sendActuatorData({
        actuatorName: "ultra-violet",
        ultraVioletModes: [change],
      });
    });

    onClose();
  };

  const modalAnimationCompleteHandler = () => {
    setModalAnimationComplete(true);
  };

  // Determine if the expandable area should be shown
  const showExpandableArea = ultraVioletState && modalAnimationComplete;

  // Determine if the data is valid for submission
  // const validForSubmit = ultraVioletState !== !!process.ultraVioletState;
  //  || ultraVioletMode !== process.ultraVioletMode;

  const ultraVioletStateChangeHandler = (checked) => {
    setUltraVioletState(checked);
  };

  function handleClick(ultravioletMode) {
    setUltraVioletModes((prevModes) => {
      const index = prevModes.findIndex(
        (mode) => mode.ultraVioletMode === ultravioletMode
      );

      if (index > -1) {
        // Toggle the state of an existing mode
        return prevModes.map((mode, idx) =>
          idx === index
            ? { ...mode, ultraVioletState: !mode.ultraVioletState }
            : mode
        );
      } else {
        // Add a new mode with ultraVioletState set to true if it doesn't exist
        return [
          ...prevModes,
          { ultraVioletMode: ultravioletMode, ultraVioletState: true },
        ];
      }
    });
  }

  // console.log(ultraVioletModes);

  return (
    <ModalWrapper
      onClose={onClose}
      onAnimationComplete={modalAnimationCompleteHandler}
    >
      <div className={styles["ultraviolet-actuator__modal"]}>
        {/* Header */}
        <header>
          <h4>Ultraviolet Actuator Configuration</h4>
          <div className={styles["actuator-decoration"]}>
            <img src={UltravioletImg} alt="Ultraviolet" />
          </div>
        </header>

        {/* Main Modal Content */}
        <div>
          <p className={styles["info-text-1"]}>
            Configure the ultraviolet (UV) light settings for your bioreactor.
          </p>

          {/* <div className={styles["switch__container"]}>
            <Switch
              checked={ultraVioletState}
              onChange={ultraVioletStateChangeHandler}
            />
            <p>Toggle the switch to turn the UltraViolet Actuator</p>
          </div> */}

          {/* Expandable area (2nd step)  */}

          <div className={styles["expandable-area"]}>
            <ul className={styles["options"]}>
              {ULTRAVIOLETS.map((ultraviolet) => {
                const isSelected =
                  ultraVioletModes &&
                  ultraVioletModes.some((uvMode) => {
                    return (
                      uvMode.ultraVioletMode === ultraviolet.mode &&
                      uvMode.ultraVioletState
                    );
                  });

                // console.log("Selected ", isSelected);

                return (
                  <li key={ultraviolet.mode}>
                    <button
                      className={isSelected ? styles["selected"] : ""}
                      onClick={handleClick.bind(null, ultraviolet.mode)}
                    >
                      {ultraviolet.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>

          <button
            // className={`${styles["submit-btn"]}
            // ${validForSubmit ? styles["active"] : ""}
            // `}
            className={`${styles["submit-btn"]} ${styles["active"]}`}
            // disabled={!validForSubmit}
            onClick={sendActuatorDataHandler}
          >
            SET
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

const Ultraviolet = ({ process, sendActuatorData }) => {
  // const ultravioletIsEnabled = process.ultraVioletModes;
  const ultravioletIsEnabled =
    process.ultraVioletModes &&
    process.ultraVioletModes.some((uvMode) => uvMode.ultraVioletState);

  return (
    <ActuatorButton
      enabled={ultravioletIsEnabled}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Ultraviolet</h5> */}
            <div className={styles["row"]}>
              <img src={UltravioletImg} alt="Ultraviolet" />
              <div>
                {process.ultraVioletModes.map((uvMode) => {
                  if (uvMode.ultraVioletState) {
                    return (
                      <p
                        key={uvMode.ultravioletMode}
                        className={styles["label__info"]}
                      >
                        Mode: <span>Type {uvMode.ultraVioletMode}</span>
                      </p>
                    );
                  }
                })}
                {!process.ultraVioletModes.some(
                  (uvMode) => uvMode.ultraVioletState
                ) && (
                  <p className={styles["label__info"]}>
                    Mode: <span>N/S</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      }}
      // onClick={sendActuatorData.bind(null, {
      //   actuatorName: "ultra-violet",
      //   ultraVioletState: !process.ultraVioletState,
      // })}
      showChildrenOnSingleClick
    >
      <UltravioletModal process={process} sendActuatorData={sendActuatorData} />
    </ActuatorButton>
  );
};

export default Ultraviolet;
