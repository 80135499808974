import React, { useState } from "react";
import { Button } from "../../../components/UI/Shadcn/Button";
import { Input } from "../../../components/UI/Shadcn/Input";
import { Loader2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/UI/Shadcn/Dialog";

const TrainingNameModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const [trainingName, setTrainingName] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (trainingName.trim()) {
      onSubmit(trainingName);
      setError("");
    } else {
      setError("Please enter a valid training name.");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.slice(0, 30);
    setTrainingName(value);
    if (value.length === 30) {
      setError("Maximum length reached (30 characters)");
    } else {
      setError("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] p-0 overflow-hidden bg-card">
        <DialogHeader className="pt-6 px-6">
          <DialogTitle className="text-xl font-semibold">
            Create New Training
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="px-6 pb-6 space-y-4">
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">
              Enter a name for your new training (max 30 characters).
            </p>
            <Input
              value={trainingName}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Training Name"
              maxLength={30}
              autoFocus
              className="w-full bg-background"
            />
          </div>
          <div className="flex justify-between items-center">
            <p className="text-sm text-muted-foreground">
              {trainingName.length}/30 characters
            </p>
            {error && <p className="text-destructive text-sm">{error}</p>}
          </div>
          <Button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white"
            disabled={isLoading || trainingName.trim().length === 0}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Creating...
              </>
            ) : (
              "Create"
            )}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TrainingNameModal;
