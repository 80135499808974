import { useState, useEffect } from "react";
import { motion, animate, AnimatePresence } from "framer-motion";
import InputSlider from "react-input-slider";
import ActuatorButton from "../../ActuatorButton/ActuatorButton";
import Switch from "react-switch";
import MagneticStirringImg from "../../../../../assets/images/Actuators/agitation.png";

import styles from "./MagneticStirring.module.css";
import ModalWrapper from "../../../../Wrappers/ModalWrapper/ModalWrapper";

const STIRRING_SPEED_MIN = 0;
const STIRRING_SPEED_MAX = 2000;
const STIRRING_OPTIONS = [
  { label: "Standard", mode: "STANDARD" },
  { label: "Regulation", mode: "REGULATION" },
];

const MagneticStirringModal = ({ process, sendActuatorData, onClose }) => {
  const [stirringMode, setStirringMode] = useState(process.stirringMode);
  const [stirringSpeed, setStirringSpeed] = useState(
    process.stirringSpeed ?? 0
  );

  const [stirringState, setStirringState] = useState(
    process.stirringMode && process.stirringMode !== "OFF"
  );

  // Animation related state variables
  const [modalAnimationComplete, setModalAnimationComplete] = useState(false);
  const [sliderAnimationComplete, setSliderAnimationComplete] = useState(false);

  const sendActuatorDataHandler = () => {
    sendActuatorData({
      actuatorName: "stir",
      stirringMode: stirringState ? stirringMode : "OFF",
      stirringSpeed: !stirringState
        ? 0
        : stirringMode === "STANDARD"
        ? 1000
        : stirringSpeed,
    });
    onClose();
  };

  // sendActuatorData.bind(null, {
  //     //   actuatorName: "stir",
  //     //   stirringMode: magneticStirringIsEnabled ? "OFF" : "STANDARD",
  //     //   stirringSpeed: magneticStirringIsEnabled ? 0 : 50,
  //     // })

  const modalAnimationCompleteHandler = () => {
    setModalAnimationComplete(true);
  };

  // Determine if the expandable area should be shown
  const showExpandableArea =
    stirringMode &&
    stirringMode !== "OFF" &&
    stirringMode !== "STANDARD" &&
    modalAnimationComplete;

  // Determine if the data is valid for submission
  // const validForSubmit =
  // stirringMode !== process.stirringMode ||
  // stirringSpeed !== process.stirringSpeed;
  const validForSubmit = true;

  // Use useEffect for animations when the expandable area is shown
  useEffect(() => {
    if (showExpandableArea) {
      animate(0, process.stirringSpeed ?? 0, {
        onUpdate: (latestValue) => setStirringSpeed(Math.trunc(latestValue)),
        onComplete: () => setSliderAnimationComplete(true),
        duration: 0.6,
        ease: "easeIn",
      });
    }
  }, [showExpandableArea, process.stirringSpeed]);

  return (
    <ModalWrapper
      onClose={onClose}
      onAnimationComplete={modalAnimationCompleteHandler}
    >
      <div className={styles["stirring-actuator__modal"]}>
        <header>
          <h4>Stirring Actuator Configuration</h4>
          <div className={styles["actuator-decoration"]}>
            <img src={MagneticStirringImg} alt="Gas" />
          </div>
        </header>

        {/* Main Modal Content */}
        <div>
          <p className={styles["info-text-1"]}>
            Configure the gas settings for your bioreactor.
          </p>

          <div className={styles["switch__container"]}>
            <Switch
              checked={stirringState}
              onChange={(checked) => {
                setStirringState(checked);
              }}
            />
            <p>Toggle the switch to turn the Stirring Actuator</p>
          </div>

          <AnimatePresence>
            {stirringState && (
              <motion.div
                initial={{ opacity: 0, height: 0, y: -25 }}
                animate={{ opacity: 1, height: "auto", y: 0 }}
                exit={{
                  opacity: 0,
                  height: 0,
                  y: -25,
                  transition: { height: { delay: 0.2 } },
                }}
                transition={{ opacity: { delay: 0.2 }, y: { delay: 0.2 } }}
              >
                <ul className={styles["options"]}>
                  {STIRRING_OPTIONS.map((option) => (
                    <li key={option.mode}>
                      <button
                        className={
                          option.mode === stirringMode ? styles["selected"] : ""
                        }
                        onClick={setStirringMode.bind(null, option.mode)}
                      >
                        {option.label}
                      </button>
                    </li>
                  ))}
                </ul>

                {/* Expandable area (2nd step)  */}
                <AnimatePresence>
                  {showExpandableArea && (
                    <motion.div
                      initial={{ opacity: 0, height: 0, y: -25 }}
                      animate={{ opacity: 1, height: "auto", y: 0 }}
                      exit={{
                        opacity: 0,
                        height: 0,
                        y: -25,
                        transition: { height: { delay: 0.2 } },
                      }}
                      transition={{
                        opacity: { delay: 0.2 },
                        y: { delay: 0.2 },
                      }}
                      className={styles["expandable-area"]}
                    >
                      <p className={styles["info-text-2"]}>
                        Use the slider to set the desired revolutions per minute
                        (RPM) for regulation mode.
                      </p>

                      <div className={styles["input-slider__container"]}>
                        {/* <img src={lightBrightImg} alt="" /> */}
                        <span>{stirringSpeed}rpm</span>
                        <InputSlider
                          xmin={STIRRING_SPEED_MIN}
                          xmax={STIRRING_SPEED_MAX}
                          x={stirringSpeed}
                          onChange={({ x }) => setStirringSpeed(x)}
                          styles={{
                            track: {
                              backgroundColor: "#151414",
                              width: "100%",
                              height: "36px",
                              border: "1px solid #656565",
                              cursor: "pointer",
                              overflow: "hidden",
                            },
                            active: {
                              backgroundColor: "rgba(91, 91, 91, 0.20)",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            },
                            thumb: {
                              width: "1px",
                              height: "34px",
                              backgroundColor: "#656565",
                            },
                          }}
                        />
                      </div>
                      <p className={styles["info-text-3"]}>
                        Click the "Set" button to save your stirring
                        configuration.
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
          <button
            className={`${styles["submit-btn"]} ${
              validForSubmit ? styles["active"] : ""
            }`}
            disabled={!validForSubmit}
            onClick={sendActuatorDataHandler}
          >
            SET
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

const MagneticStirring = ({ process, sendActuatorData }) => {
  const magneticStirringIsEnabled =
    process.stirringMode && process.stirringMode !== "OFF";

  return (
    <ActuatorButton
      enabled={magneticStirringIsEnabled}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Stirring</h5> */}
            <div className={styles["row"]}>
              <img src={MagneticStirringImg} alt="Stirring" />
              <div>
                <p className={styles["label__info"]}>
                  Mode:{" "}
                  <span>
                    {process?.stirringMode ? process.stirringMode : "N/S"}
                  </span>
                </p>
                <p className={styles["label__info"]}>
                  Value:{" "}
                  <span>
                    {process?.stirringSpeed
                      ? process.stirringSpeed + "rpm"
                      : "N/S"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      }}
      multiAction
      // onClick={sendActuatorData.bind(null, {
      //   actuatorName: "stir",
      //   stirringMode: magneticStirringIsEnabled ? "OFF" : "STANDARD",
      //   stirringSpeed: magneticStirringIsEnabled ? 0 : 50,
      // })}
      showChildrenOnSingleClick
    >
      <MagneticStirringModal
        process={process}
        sendActuatorData={sendActuatorData}
      />
    </ActuatorButton>
  );
};

export default MagneticStirring;
