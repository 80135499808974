"use client";

import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Edit, AlertTriangle } from "lucide-react";

import { Button } from "../../../components/UI/Shadcn/Button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/UI/Shadcn/Card";
import { Separator } from "../../../components/UI/Shadcn/Separator";
import { Badge } from "../../../components/UI/Shadcn/Badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/UI/Shadcn/Tooltip";

import CircularProgressBar from "./CircularProgressBar/CircularProgressBar";
import Ultrasound from "./Actuators/Ultrasound/Ultrasound";
import Vacuum from "./Actuators/Vacuum/Vacuum";
import Ultraviolet from "./Actuators/Ultraviolet/Ultraviolet";
import Light from "./Actuators/Light/Light";
import MagneticStirring from "./Actuators/MagneticStirring/MagneticStirring";
import Gas from "./Actuators/Gas/Gas";
import Temperature from "./Actuators/Temperature/Temperature";
import Ph from "./Actuators/Ph/Ph";
import Chart from "./Chart/Chart";
import FinishProcess from "./FinishProcess/FinishProcess";
import UpdateProcess from "./UpdateProcess/UpdateProcess";
import ManualData from "./ManualData/ManualData";

import {
  useGetUserRunningProcessesDataQuery,
  useSendActuatorDataMutation,
} from "../../../features/process/processApiSlice";

export default function Process({ bion }) {
  const [finishProcess, setFinishProcess] = useState(false);
  const [updateProcess, setUpdateProcess] = useState(false);
  const [manualData, setManualData] = useState(false);

  const { data: process, isLoading: getUserRunningProcessesDataReqIsLoading } =
    useGetUserRunningProcessesDataQuery(bion.bionId);

  const [sendActuatorDataReq] = useSendActuatorDataMutation();

  const sendActuatorDataHandler = ({ actuatorName, ...data }) => {
    sendActuatorDataReq({
      actuatorName: actuatorName,
      data: { ...data, processId: process.processId, bionId: bion.bionId },
    });
  };

  if (getUserRunningProcessesDataReqIsLoading) return null;

  return (
    <div className="p-6 bg-background text-foreground">
      <motion.div
        variants={{
          hidden: {},
          visible: { transition: { staggerChildren: 0.05 } },
        }}
        initial="hidden"
        animate="visible"
        className="grid gap-6 md:grid-cols-2 lg:grid-cols-3"
      >
        {/* Info */}
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          className="col-span-full md:col-span-1"
        >
          <Card className="h-full">
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                <span className="text-2xl font-bold">Process Details</span>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setUpdateProcess(true)}
                >
                  <Edit className="h-4 w-4" />
                </Button>
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div>
                <h2 className="text-xl font-semibold text-primary">
                  {process.processName}
                </h2>
                <p className="text-sm text-muted-foreground">
                  {process.description}
                </p>
              </div>
              <Separator />
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-sm font-medium">Bion</h3>
                  <p className="text-sm">{bion.bionName}</p>
                </div>
                <div>
                  <h3 className="text-sm font-medium">Type</h3>
                  <p className="text-sm">{process.processTypeDescription}</p>
                </div>
                <div>
                  <h3 className="text-sm font-medium">Start Date</h3>
                  <p className="text-sm">
                    {new Date(process.startDate).toLocaleString()}
                  </p>
                </div>
                {process?.endDate && (
                  <div>
                    <h3 className="text-sm font-medium">End Date</h3>
                    <p className="text-sm">
                      {new Date(process.endDate).toLocaleString()}
                    </p>
                  </div>
                )}
              </div>
              {process.isPolluted && (
                <div className="mt-4">
                  <Badge
                    variant="destructive"
                    className="flex items-center gap-2"
                  >
                    <AlertTriangle className="h-4 w-4" />
                    Contaminated
                  </Badge>
                </div>
              )}
              <div className="flex gap-4 mt-6">
                <Button onClick={() => setManualData(true)}>
                  Add manual data
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setFinishProcess(true)}
                >
                  Finish Process
                </Button>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        {/* Status Level */}
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          className="col-span-full md:col-span-1"
        >
          <Card className="h-full">
            <CardHeader>
              <CardTitle className="text-2xl font-bold">Sensors</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <CircularProgressBar
                        value={process.temperature}
                        colorRanges={[
                          { min: 0, max: 30, color: "hsl(var(--primary))" },
                          { min: 30, max: 60, color: "hsl(var(--warning))" },
                          {
                            min: 60,
                            max: 80,
                            color: "hsl(var(--destructive))",
                          },
                        ]}
                      >
                        C
                      </CircularProgressBar>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Temperature: {process.temperature}°C</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <CircularProgressBar
                        value={process.carbonDioxide}
                        unit="%"
                        colorRanges={[
                          { min: 0, max: 25, color: "hsl(var(--primary))" },
                          { min: 25, max: 50, color: "hsl(var(--success))" },
                          { min: 50, max: 75, color: "hsl(var(--warning))" },
                          {
                            min: 75,
                            max: 100,
                            color: "hsl(var(--destructive))",
                          },
                        ]}
                      >
                        CO<sub>2</sub>
                      </CircularProgressBar>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Carbon Dioxide: {process.carbonDioxide}%</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <CircularProgressBar
                        value={process.oxygen}
                        unit="%"
                        colorRanges={[
                          { min: 0, max: 25, color: "hsl(var(--primary))" },
                          { min: 25, max: 50, color: "hsl(var(--success))" },
                          { min: 50, max: 75, color: "hsl(var(--warning))" },
                          {
                            min: 75,
                            max: 100,
                            color: "hsl(var(--destructive))",
                          },
                        ]}
                      >
                        O<sub>2</sub>
                      </CircularProgressBar>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Oxygen: {process.oxygen}%</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <CircularProgressBar
                        value={process.volume}
                        unit="%"
                        colorRanges={[
                          { min: 0, max: 100, color: "hsl(var(--primary))" },
                        ]}
                      >
                        Volume
                      </CircularProgressBar>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Volume: {process.volume}%</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <CircularProgressBar
                        value={process.ethanol}
                        unit="%"
                        colorRanges={[
                          { min: 0, max: 25, color: "hsl(var(--primary))" },
                          { min: 25, max: 50, color: "hsl(var(--success))" },
                          { min: 50, max: 75, color: "hsl(var(--warning))" },
                          {
                            min: 75,
                            max: 100,
                            color: "hsl(var(--destructive))",
                          },
                        ]}
                      >
                        Ethanol
                      </CircularProgressBar>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Ethanol: {process.ethanol}%</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <CircularProgressBar
                        value={process.acidity}
                        colorRanges={[
                          { min: 0, max: 4, color: "hsl(var(--primary))" },
                          { min: 4, max: 7, color: "hsl(var(--success))" },
                          { min: 7, max: 14, color: "hsl(var(--warning))" },
                        ]}
                      >
                        pH
                      </CircularProgressBar>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>pH: {process.acidity}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        {/* Actuators */}
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          className="col-span-full"
        >
          <Card>
            <CardHeader>
              <CardTitle className="text-2xl font-bold">Actuators</CardTitle>
            </CardHeader>
            <CardContent>
              {!bion.isLidSet ? (
                <div className="bg-muted p-4 rounded-lg text-center">
                  <AlertTriangle className="h-6 w-6 text-warning mx-auto mb-2" />
                  <p className="text-sm font-medium">
                    Please put back the BION lid to be able to send any actuator
                    data
                  </p>
                </div>
              ) : (
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                  <Gas
                    process={process}
                    sendActuatorData={sendActuatorDataHandler}
                  />
                  <Vacuum
                    process={process}
                    sendActuatorData={sendActuatorDataHandler}
                  />
                  <Ultraviolet
                    process={process}
                    sendActuatorData={sendActuatorDataHandler}
                  />
                  <Light
                    process={process}
                    sendActuatorData={sendActuatorDataHandler}
                  />
                  <Ultrasound
                    process={process}
                    sendActuatorData={sendActuatorDataHandler}
                  />
                  <MagneticStirring
                    process={process}
                    sendActuatorData={sendActuatorDataHandler}
                  />
                  <Temperature
                    process={process}
                    sendActuatorData={sendActuatorDataHandler}
                  />
                  <Ph />
                </div>
              )}
            </CardContent>
          </Card>
        </motion.div>

        {/* Chart */}
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          className="col-span-full"
        >
          <Card>
            <CardHeader>
              <CardTitle className="text-2xl font-bold">
                Sensor Evolution Chart
              </CardTitle>
            </CardHeader>
            <CardContent className="h-[400px]">
              <Chart bion={bion} processId={process.processId} />
            </CardContent>
          </Card>
        </motion.div>
      </motion.div>

      <AnimatePresence>
        {finishProcess && (
          <FinishProcess
            process={process}
            bionId={bion.bionId}
            onClose={() => setFinishProcess(false)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {updateProcess && (
          <UpdateProcess
            process={process}
            bionId={bion.bionId}
            onClose={() => setUpdateProcess(false)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {manualData && (
          <ManualData onClose={() => setManualData(false)} process={process} />
        )}
      </AnimatePresence>
    </div>
  );
}
