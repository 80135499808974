import React from "react";
import { Outlet } from "react-router-dom";
import MainNavigation from "../../MainNavigation/MainNavigation";
import { ThemeProvider } from "../../ThemeProvider";

const AppWrapper = () => {
  return (
    <ThemeProvider>
      <div className="flex h-screen bg-background -ml-6">
        {/* Sidebar */}
        <MainNavigation />
        {/* Main content area */}
        <main className="flex-1 ml-20 sm:ml-22 overflow-auto">
          <Outlet />
        </main>
      </div>
    </ThemeProvider>
  );
};

export default AppWrapper;
