import { useLocation, useParams } from "react-router-dom";

import {
  useHandleNewUserRegistrationMutation,
  useGetSignedUpUserDataQuery,
} from "../../features/auth/authApiSlice";

import PasswordProtectionWrapper from "../../components/Wrappers/PasswordProtectionWrapper/PasswordProtectionWrapper";

import styles from "./RegistrationRequest.module.css";

const RegistrationRequest = () => {
  const { action } = useParams();

  const urlSearchParams = new URLSearchParams(useLocation().search);

  // Retrieve individual query parameters
  const clientId = urlSearchParams.get("client_id");
  const confirmationToken = urlSearchParams.get("confirmation_token");
  const bionId = urlSearchParams.get("destination");

  const { data: newUser } = useGetSignedUpUserDataQuery(confirmationToken);

  const [
    handleNewUserRegistrationReq,
    { isLoading: handleNewUserRegistrationReqIsLoading },
  ] = useHandleNewUserRegistrationMutation();

  const passwordSubmitHandler = (password) => {
    handleNewUserRegistrationReq({
      action,
      data: {
        clientId: clientId,
        confirmationToken: confirmationToken,
        destination: bionId,
        adminUserPassword: password,
      },
    });
  };

  return (
    <PasswordProtectionWrapper onSubmit={passwordSubmitHandler}>
      {(showModal) => (
        <div className={styles["page"]}>
          <header>
            <h1 className={styles["title"]}>
              Access Request {action.toUpperCase()}
            </h1>
            <p className={styles["subtitle"]}>
              Welcome to the Access Request Approval page, where administrators
              hold the key to granting access to the Bion bioreactor. Here, you
              can review and decide on new user registration requests. Your
              decision shapes the community accessing the bioreactor, ensuring a
              secure and purposeful environment. Let's proceed with managing
              access requests and shaping the future of your Bion community.
            </p>
          </header>

          <h2>New user's request data:</h2>
          <p>Username: {newUser?.userName}</p>
          <p>Email: {newUser?.email}</p>
          <p>Alias: {newUser?.alias}</p>
          <button onClick={showModal}>
            {handleNewUserRegistrationReqIsLoading
              ? "Loading"
              : action.toUpperCase()}
          </button>
        </div>
      )}
    </PasswordProtectionWrapper>
  );
};

export default RegistrationRequest;
