import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Select from "react-select";

import { IoIosArrowDown as ExpandIcon } from "react-icons/io";
import { BsHourglassSplit as InProgressIcon } from "react-icons/bs";
import { FaCheckCircle as CompletedIcon } from "react-icons/fa";

import BatchImg from "../../../../../../../assets/images/PlannerImages/white/BatchMode.png";
import FedBatchImg from "../../../../../../../assets/images/PlannerImages/white/FedBatchMode.png";
import ContinuousImg from "../../../../../../../assets/images/PlannerImages/white/ContinuousMode.png";

import classNames from "classnames/bind";
import styles from "./Media.module.css";
import commonStyles from "../CommonStyles.module.css";
import { useState, useEffect } from "react";
import usePromptEngine from "../../../../../../../hooks/use-prompt_engine";
import LinePlans from "./LinePlan/LinePlans";

const cx = classNames.bind({ ...styles, ...commonStyles });

const MEDIA_MODES = [
  {
    title: "Batch",
    subtitle: "All ingredients added at start; no further additions",
    img: BatchImg,
  },
  {
    title: "Fed-Batch",
    subtitle: "Substrates added intermittently for controlled growth",
    img: FedBatchImg,
  },
  {
    title: "Continuous",
    subtitle: "Continuous flow of substrates and culture for steady operation",
    img: ContinuousImg,
  },
];

const Media = ({ name, options, prompts, plannerData, setPlannerData }) => {
  const promptOneData = plannerData?.[name].MEDIA_LINES;
  const [selectedMode, setSelectedMode] = useState("Batch");
  const {
    // dataItems: promptOneData,
    renderPrompt: renderPromptOne,
    checkAllItemsForCompletion: checkPromptOneForCompletion,
  } = usePromptEngine(
    prompts.MEDIA_LINES.basePrompt,
    options,
    promptOneData,
    setPlannerData,
    "MEDIA_LINES"
  );

  const [lines, setLines] = useState([{}]);

  const promptLine1Data = plannerData?.[name].MEDIA_LINE1;
  // console.log(prompts.MEDIA_LINE1.basePrompt);
  const {
    renderPrompt: renderPromptLine1,
    checkAllItemsForCompletion: checkAllPromptLine1ForCompletion,
    checkItemForCompletion: checkPromptLine1ForCompletion,
    checkItemForCreation: checkPromptLine1ForCreation,
    checkItemForStart: checkPromptLine1ForStart,
    addDataItem: addPromptLine1,
  } = usePromptEngine(
    prompts.MEDIA_LINE1.basePrompt,
    options,
    promptLine1Data,
    setPlannerData,
    "MEDIA_LINE1",
    "MEDIA_LINE_CTRL"
  );

  const promptInject1Data = plannerData?.[name].MEDIA_INJECT;
  const {
    renderPrompt: renderPromptInject1,
    checkAllItemsForCompletion: checkAllInjectionPlansForCompletion,
    checkItemForCompletion: checkInjectionPlanForCompletion,
    checkItemForStart: checkInjectionPlanForStart,
    checkItemForCreation: checkInjectionPlanForCreation,
    addDataItem: addInjectionPlan,
  } = usePromptEngine(
    prompts.MEDIA_INJECT.basePrompt,
    options,
    promptInject1Data,
    setPlannerData,
    "MEDIA_INJECT"
  );

  const changeModeHandler = (mode) => {
    setSelectedMode(mode);
  };

  return (
    <div>
      <div className={cx("mode-btns__container")}>
        {MEDIA_MODES.map((mode) => (
          <button
            key={mode.title}
            className={cx({ selected: mode.title === selectedMode })}
            onClick={changeModeHandler.bind(null, mode.title)}
          >
            <img src={mode.img} className={cx("mode-img")} />
            <span>{mode.title}</span>
            <span>{mode.subtitle}</span>
          </button>
        ))}
      </div>

      {/* Injections Management */}
      {selectedMode && (
        <Accordion
          className={cx("accordion", "accordion--dark")}
          disableGutters
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={<ExpandIcon className={cx("expand-icon")} />}
            className={cx("accordionSummary")}
          >
            <div className={cx("accordionSummary__container")}>
              {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

              <div className={cx("info__container")}>
                <div>
                  <h3>Injections Management</h3>
                </div>
                <p>Schedule and control bioprocess injections</p>
              </div>

              <div className={cx("status__container")}>
                <p>Not started</p>
                <p>Fill the details</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={cx("accordionDetails")}>
            {promptInject1Data.map((plan, index) => (
              <Accordion
                key={index}
                //   expanded={isExpanded}
                className={cx("accordion", "accordion--light")}
                disableGutters
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={<ExpandIcon className={cx("expand-icon")} />}
                  className={cx("accordionSummary")}
                >
                  <div className={cx("accordionSummary__container")}>
                    {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                    <div className={cx("info__container")}>
                      <div className={cx("info__container-row")}>
                        <h3>Injection Planning {index + 1}</h3>
                        {checkInjectionPlanForCompletion(index) ? (
                          <p className={cx("status-indicator", "completed")}>
                            <CompletedIcon /> Completed
                          </p>
                        ) : checkInjectionPlanForStart(index) ? (
                          <p className={cx("status-indicator", "in-progress")}>
                            <InProgressIcon /> In Progress
                          </p>
                        ) : null}
                      </div>
                      <p>Fine-Tuning Your Bioprocess with Precision</p>
                    </div>

                    {checkInjectionPlanForCompletion(index) ? (
                      <div className={cx("status__container")}>
                        <p>All set !</p>
                        <p>Modify if required</p>
                      </div>
                    ) : checkInjectionPlanForStart(index) ? (
                      <div className={cx("status__container")}>
                        <p>In Progress</p>
                        <p>Continue filling details</p>
                      </div>
                    ) : (
                      <div className={cx("status__container")}>
                        <p>Not started</p>
                        <p>Fill the details</p>
                      </div>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails className={cx("accordionDetails")}>
                  <div>
                    <div className={cx("prompt")}>
                      {renderPromptInject1(index)}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
            {checkAllInjectionPlansForCompletion() &&
              checkInjectionPlanForCreation(
                promptInject1Data.length - 1,
                promptInject1Data
              ) && (
                <Accordion
                  className={cx(
                    "accordion",
                    "accordion--light",
                    "accordion--add"
                  )}
                  disableGutters
                  onClick={addInjectionPlan}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    // expandIcon={<ExpandIcon className={cx("expand-icon")} />}
                    className={cx("accordionSummary")}
                  >
                    <div className={cx("accordionSummary__container")}>
                      {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                      <div className={cx("info__container")}>
                        <div>
                          <h3>Create New Injection Planning</h3>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                </Accordion>
              )}
          </AccordionDetails>
        </Accordion>
      )}

      {/* Lines Management */}
      {selectedMode === "Fed-Batch" && (
        <Accordion
          className={cx("accordion", "accordion--dark")}
          disableGutters
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={<ExpandIcon className={cx("expand-icon")} />}
            className={cx("accordionSummary")}
          >
            <div className={cx("accordionSummary__container")}>
              {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

              <div className={cx("info__container")}>
                <div>
                  <h3>Lines Management</h3>
                </div>
                <p>Manage connections to the BION</p>
              </div>

              <div className={cx("status__container")}>
                <p>Not started</p>
                <p>Fill the details</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={cx("accordionDetails")}>
            <Accordion
              className={cx("accordion", "accordion--light")}
              disableGutters
              style={{ marginBottom: "45px" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandIcon className={cx("expand-icon")} />}
                className={cx("accordionSummary")}
              >
                <div className={cx("accordionSummary__container")}>
                  {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                  <div className={cx("info__container")}>
                    <div>
                      <h3>Bioprocess Volume Specification</h3>
                    </div>
                    <p>Define the Total Production Volume</p>
                  </div>

                  <div className={cx("status__container")}>
                    <p>Not started</p>
                    <p>Fill the details</p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={cx("accordionDetails")}>
                <div className={cx("prompt")}>{renderPromptOne(0)}</div>
              </AccordionDetails>
            </Accordion>

            {/*  */}
            <Accordion
              //   expanded={isExpanded}
              className={cx("accordion", "accordion--light")}
              disableGutters
              style={{ marginBottom: "45px" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandIcon className={cx("expand-icon")} />}
                className={cx("accordionSummary")}
              >
                <div className={cx("accordionSummary__container")}>
                  {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                  <div className={cx("info__container")}>
                    <div className={cx("info__container-row")}>
                      <h3>Lines Correlations</h3>
                    </div>
                    <p>Define Group Assosiations and Parameters</p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={cx("accordionDetails")}>
                {promptLine1Data &&
                  promptLine1Data.map((_, index) => (
                    <Accordion
                      key={index}
                      //   expanded={isExpanded}
                      className={cx("accordion", "accordion--dark")}
                      disableGutters
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={
                          <ExpandIcon className={cx("expand-icon")} />
                        }
                        className={cx("accordionSummary")}
                      >
                        <div className={cx("accordionSummary__container")}>
                          <div className={cx("info__container")}>
                            <div className={cx("info__container-row")}>
                              <h3>Line {index + 1} Correlation</h3>
                              {checkPromptLine1ForCompletion(index) ? (
                                <p
                                  className={cx(
                                    "status-indicator",
                                    "completed"
                                  )}
                                >
                                  <CompletedIcon /> Completed
                                </p>
                              ) : checkPromptLine1ForStart(index) ? (
                                <p
                                  className={cx(
                                    "status-indicator",
                                    "in-progress"
                                  )}
                                >
                                  <InProgressIcon /> In Progress
                                </p>
                              ) : null}
                            </div>
                            <p>Define Group Assosiations and Parameters</p>
                          </div>

                          {checkPromptLine1ForCompletion(index) ? (
                            <div className={cx("status__container")}>
                              <p>All set !</p>
                              <p>Modify if required</p>
                            </div>
                          ) : checkPromptLine1ForStart(index) ? (
                            <div className={cx("status__container")}>
                              <p>In Progress</p>
                              <p>Continue filling details</p>
                            </div>
                          ) : (
                            <div className={cx("status__container")}>
                              <p>Not started</p>
                              <p>Fill the details</p>
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={cx("accordionDetails")}>
                        <div>
                          <div className={cx("prompt")}>
                            {renderPromptLine1(index)}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                {checkAllPromptLine1ForCompletion() &&
                  checkPromptLine1ForCreation(
                    promptLine1Data.length - 1,
                    promptLine1Data
                  ) && (
                    <Accordion
                      className={cx(
                        "accordion",
                        "accordion--dark",
                        "accordion--add"
                      )}
                      disableGutters
                      onClick={addPromptLine1}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        // expandIcon={<ExpandIcon className={cx("expand-icon")} />}
                        className={cx("accordionSummary")}
                      >
                        <div className={cx("accordionSummary__container")}>
                          {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                          <div className={cx("info__container")}>
                            <div>
                              <h3>Add New Line</h3>
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                    </Accordion>
                  )}
              </AccordionDetails>
            </Accordion>

            <Accordion
              //   expanded={isExpanded}
              className={cx("accordion", "accordion--light")}
              disableGutters
              // style={{ marginBottom: "45px" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandIcon className={cx("expand-icon")} />}
                className={cx("accordionSummary")}
              >
                <div className={cx("accordionSummary__container")}>
                  {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                  <div className={cx("info__container")}>
                    <div className={cx("info__container-row")}>
                      <h3>Lines Plans</h3>
                    </div>
                    <p>Outline and Manage Plans for the Lines</p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={cx("accordionDetails")}>
                {promptLine1Data &&
                  promptLine1Data.map((_, index) => (
                    <Accordion
                      key={index}
                      //   expanded={isExpanded}
                      className={cx("accordion", "accordion--dark")}
                      disableGutters
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={
                          <ExpandIcon className={cx("expand-icon")} />
                        }
                        className={cx("accordionSummary")}
                      >
                        <div className={cx("accordionSummary__container")}>
                          <div className={cx("info__container")}>
                            <div className={cx("info__container-row")}>
                              <h3>Line {index + 1} Plans</h3>
                            </div>
                            <p>Define Group Assosiations and Parameters</p>
                          </div>

                          {checkPromptLine1ForCompletion(index) ? (
                            <div className={cx("status__container")}>
                              <p>All set !</p>
                              <p>Modify if required</p>
                            </div>
                          ) : checkPromptLine1ForStart(index) ? (
                            <div className={cx("status__container")}>
                              <p>In Progress</p>
                              <p>Continue filling details</p>
                            </div>
                          ) : (
                            <div className={cx("status__container")}>
                              <p>Not started</p>
                              <p>Fill the details</p>
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={cx("accordionDetails")}>
                        <LinePlans
                          lineIdx={index}
                          prompt={prompts.MEDIA_LINE_CTRL.basePrompt}
                          options={options}
                          plannerData={plannerData}
                          setPlannerData={setPlannerData}
                          name={name}
                        />
                        {/* <div>
                          <div className={cx("prompt")}>
                            {renderPromptLine1(index)}
                          </div>
                        </div> */}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default Media;
