import { useEffect, useState } from "react";
import { motion, animate } from "framer-motion";
import InputSlider from "react-input-slider";
import ActuatorButton from "../../ActuatorButton/ActuatorButton";
import GasImg from "../../../../../assets/images/Actuators/gas.png";
import styles from "./Gas.module.css";
import ModalWrapper from "../../../../Wrappers/ModalWrapper/ModalWrapper";

// Constants
const GASSER_VALUE_MIN = 0;
const GASSER_VALUE_MAX = 100;
const GASES = [
  { label: "Oxygen", mode: "OXYGEN" },
  { label: "Nitrogen", mode: "NITROGEN" },
];

const GasModal = ({ process, sendActuatorData, onClose }) => {
  // State variables
  const [gasserValue, setGasserValue] = useState(0);
  const [gasserMode, setGasserMode] = useState(process.gasserMode);

  // Animation related state variables
  const [modalAnimationComplete, setModalAnimationComplete] = useState(false);
  const [sliderAnimationComplete, setSliderAnimationComplete] = useState(false);

  const sendActuatorDataHandler = () => {
    sendActuatorData({
      actuatorName: "gas",
      gasserMode: gasserValue === 0 ? "OFF" : gasserMode,
      gasserValue: gasserValue,
    });
    onClose();
  };

  const modalAnimationCompleteHandler = () => {
    setModalAnimationComplete(true);
  };

  // Determine if the expandable area should be shown
  const showExpandableArea =
    gasserMode && gasserMode !== "OFF" && modalAnimationComplete;

  // Determine if the data is valid for submission
  const validForSubmit =
    (gasserValue !== process.gasserValue ||
      gasserMode !== process.gasserMode) &&
    sliderAnimationComplete;

  // Use useEffect for animations when the expandable area is shown
  useEffect(() => {
    if (showExpandableArea) {
      animate(0, process.gasserValue ?? 0, {
        onUpdate: (latestValue) => setGasserValue(Math.trunc(latestValue)),
        onComplete: () => setSliderAnimationComplete(true),
        duration: 0.6,
        ease: "easeIn",
      });
    }
  }, [showExpandableArea, process.gasserValue]);

  return (
    <ModalWrapper
      onClose={onClose}
      onAnimationComplete={modalAnimationCompleteHandler}
    >
      <div className={styles["gas-actuator__modal"]}>
        {/* Header */}
        <header>
          <h4>Gas Actuator Configuration</h4>
          <div className={styles["actuator-decoration"]}>
            <img src={GasImg} alt="Gas" />
          </div>
        </header>

        {/* Main Modal Content */}
        <div>
          <p className={styles["info-text-1"]}>
            Configure the gas settings for your bioreactor.
          </p>
          <ul className={styles["options"]}>
            {GASES.map((gas) => (
              <li key={gas.mode}>
                <button
                  className={gas.mode === gasserMode ? styles["selected"] : ""}
                  onClick={setGasserMode.bind(null, gas.mode)}
                >
                  {gas.label}
                </button>
              </li>
            ))}
          </ul>

          {/* Expandable area (2nd step)  */}
          {showExpandableArea && (
            <motion.div
              initial={{ opacity: 0, height: 0, y: -25 }}
              animate={{ opacity: 1, height: "auto", y: 0 }}
              transition={{ opacity: { delay: 0.2 }, y: { delay: 0.2 } }}
              className={styles["expandable-area"]}
            >
              <p className={styles["info-text-2"]}>
                Use the slider below to set the {gasserMode} percentage.
              </p>

              <div className={styles["input-slider__container"]}>
                {/* <img src={lightBrightImg} alt="" /> */}
                <span>{gasserValue}%</span>
                <InputSlider
                  xmin={GASSER_VALUE_MIN}
                  xmax={GASSER_VALUE_MAX}
                  x={gasserValue}
                  onChange={({ x }) => setGasserValue(x)}
                  styles={{
                    track: {
                      backgroundColor: "#151414",
                      width: "100%",
                      height: "36px",
                      border: "1px solid #656565",
                      cursor: "pointer",
                      overflow: "hidden",
                    },
                    active: {
                      backgroundColor: "rgba(91, 91, 91, 0.20)",
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                    },
                    thumb: {
                      width: "1px",
                      height: "34px",
                      backgroundColor: "#656565",
                    },
                  }}
                />
              </div>
              <p className={styles["info-text-3"]}>
                Click the "SET" button to save your gas configuration.
              </p>

              <button
                className={`${styles["submit-btn"]} ${
                  validForSubmit ? styles["active"] : ""
                }`}
                disabled={!validForSubmit}
                onClick={sendActuatorDataHandler}
              >
                SET
              </button>
            </motion.div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

const Gas = ({ process, sendActuatorData }) => {
  const gasIsEnabled = process.gasserMode && process.gasserMode !== "OFF";

  return (
    <ActuatorButton
      enabled={gasIsEnabled}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Gas</h5> */}
            <div className={styles["row"]}>
              <img src={GasImg} alt="Gas" />{" "}
              <div>
                <p className={styles["label__info"]}>
                  Mode:{" "}
                  <span>
                    {process?.gasserMode ? process.gasserMode : "N/S"}
                  </span>
                </p>
                <p className={styles["label__info"]}>
                  Value:{" "}
                  <span>
                    {process?.gasserValue ? process.gasserValue + "%" : "N/S"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      }}
      showChildrenOnSingleClick
    >
      <GasModal process={process} sendActuatorData={sendActuatorData} />
    </ActuatorButton>
  );
};

export default Gas;
