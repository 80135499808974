import useTimer from "../../../hooks/use-timer";
import { useState, useEffect } from "react";

import styles from "./Timer.module.css";

const Timer2 = ({ shutDownDate, freeze }) => {
  const [freezeTime, setFreezeTime] = useState(null);
  const timeLeftBeforeDisconnecting = Math.floor(
    (new Date(shutDownDate) - new Date()) / 1000
  );

  const { secondsLeft } = useTimer(timeLeftBeforeDisconnecting, {
    startAutomatically: true,
    direction: "desc",
  });

  useEffect(() => {
    if (!freeze) {
      setFreezeTime(null);
    }

    if (!freezeTime) {
      setFreezeTime(secondsLeft);
    }
  }, [freeze, secondsLeft]);

  // Function to convert seconds into "mm:ss" format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div
      className={styles["circular-progress"]}
      style={{
        background: `conic-gradient(#A6CEE3 ${
          (secondsLeft / 1800) * 100
        }%, #262626 0deg)`,
      }}
    >
      <p className={styles["text-time"]}>
        {formatTime(freeze ? freezeTime : secondsLeft)}
      </p>
    </div>
  );
};

export default Timer2;
