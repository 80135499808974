import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentToken,
  setCredentials,
} from "../../../features/auth/authSlice";
import {
  useGetChatByIdQuery,
  useSendMessageMutation,
  useCreateChatMutation,
  useGetChatsQuery,
  useDeleteChatMutation,
} from "../../../features/ai/aiApiSlice";
import { useGetUserDataQuery } from "../../../features/user/userApiSlice";
import { getLocalStorageItems } from "../../../utils/localStorageUtils";
import { Button } from "../../UI/Shadcn/Button";
import { Input } from "../../UI/Shadcn/Input";
import { Slider } from "../../UI/Shadcn/Slider";
import { Switch } from "../../UI/Shadcn/Switch";
import { ScrollArea } from "../../UI/Shadcn/ScrollArea";
import { Avatar } from "../../UI/Shadcn/Avatar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../UI/Shadcn/Popover";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../UI/Shadcn/DropdownMenu";
import {
  Settings,
  PlusCircle,
  MoreHorizontal,
  Trash,
  Send,
} from "lucide-react";

const ChatContainer = () => {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [chatIsPrinting, setChatIsPrinting] = useState(false);
  const [message, setMessage] = useState("");
  const [config, setConfig] = useState({
    temperature: 0.5,
    withPublicData: true,
  });
  const sessionToken = useSelector(selectCurrentToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector((state) => state.auth.user.userName);
  const inputRef = useRef(null);
  const endOfMessagesRef = useRef(null);

  const { data: userData } = useGetUserDataQuery();
  const {
    data: chatData,
    isLoading: chatLoading,
    error: chatError,
  } = useGetChatByIdQuery(chatId, { skip: !chatId });
  const { data: chats = [], isLoading: chatsLoading } = useGetChatsQuery();
  const [createChat] = useCreateChatMutation();
  const [sendMessage] = useSendMessageMutation();
  const [deleteChat] = useDeleteChatMutation();

  useEffect(() => {
    if (chatData) {
      setMessages(chatData?.userAiMessages || []);
    }
  }, [chatData]);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleConfigChange = (key, value) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [key]: value,
    }));
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    let incChatId = chatId || getChatIdFromUrl();

    if (!incChatId) {
      try {
        const newChat = await createChat().unwrap();
        incChatId = newChat.id;
        window.history.pushState(null, "", `/cultzyme-ai/${incChatId}`);
        setMessages([]);
      } catch (err) {
        console.error("Error creating new chat:", err);
        return;
      }
    }

    setChatIsPrinting(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { messageId: prevMessages.length, content: message, senderType: "USER" },
    ]);
    setMessage("");

    const assistantMessageId = new Date().getTime();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/assistants`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${sessionToken}`,
            accept: "text/event-stream",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: message,
            chatId: incChatId,
            temperature: config.temperature,
            withPublicData: config.withPublicData,
          }),
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          const refreshToken = getLocalStorageItems("refreshToken");
          const refreshResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/auth/refreshtoken`,
            {
              method: "POST",
              headers: { refreshtoken: refreshToken },
              body: JSON.stringify({ userName: userName }),
            }
          );

          if (refreshResponse.ok) {
            const refreshData = await refreshResponse.json();
            if (refreshData?.accessToken) {
              dispatch(setCredentials(refreshData));
              return handleSendMessage();
            }
          } else {
            dispatch(logOut());
            throw new Error("Token refresh failed");
          }
        }
        throw new Error("Failed to send message");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let completeMessage = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunkText = decoder.decode(value, { stream: true });
        const processedText = chunkText
          .split("\n")
          .filter((line) => line.startsWith("data:"))
          .map((line) => line.replace(/^data:/, ""))
          .join("");

        completeMessage += processedText;

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.messageId === assistantMessageId
              ? { ...msg, content: completeMessage }
              : msg
          )
        );
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          messageId: new Date().getTime(),
          content: "An error occurred while processing your message.",
          senderType: "AI",
          isError: true,
        },
      ]);
    } finally {
      setChatIsPrinting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleCreateChat = async () => {
    try {
      const newChat = await createChat().unwrap();
      navigate(`/cultzyme-ai/${newChat.id}`);
    } catch (err) {
      console.error("Error creating new chat:", err);
    }
  };

  const handleDeleteChat = async (chatIdToDelete) => {
    try {
      await deleteChat(chatIdToDelete).unwrap();
      if (chatId === chatIdToDelete) navigate("/cultzyme-ai");
    } catch (err) {
      console.error("Failed to delete chat", err);
    }
  };

  const getChatIdFromUrl = () => {
    const pathSegments = window.location.pathname.split("/");
    if (pathSegments.length > 2 && pathSegments[1] === "cultzyme-ai") {
      return pathSegments[2] || null;
    }
    return null;
  };

  if (chatLoading || chatsLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-background text-foreground">
        Loading...
      </div>
    );
  }

  if (chatError) {
    return (
      <div className="flex items-center justify-center h-screen bg-background text-foreground">
        Error loading chat
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-background text-foreground">
      {/* Chat History Sidebar */}
      <div className="w-64 bg-card border-r border-border p-4 overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold">Chats</h2>
          <Button
            onClick={handleCreateChat}
            size="sm"
            className="bg-primary text-primary-foreground"
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            New Chat
          </Button>
        </div>
        <ScrollArea className="h-[calc(100vh-80px)]">
          {chats.map((chat) => (
            <div
              key={chat.chatId}
              className="flex items-center justify-between py-2"
            >
              <Button
                variant="ghost"
                className="text-sm text-left truncate w-full"
                onClick={() => navigate(`/cultzyme-ai/${chat.chatId}`)}
              >
                {chat.preview || "New Chat"}
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm">
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => handleDeleteChat(chat.chatId)}
                  >
                    <Trash className="mr-2 h-4 w-4" />
                    <span>Delete</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ))}
        </ScrollArea>
      </div>

      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col">
        {/* Message List */}
        <ScrollArea className="flex-1 p-4">
          {messages.map((message, index) => (
            <div key={index} className="flex items-start space-x-4 mb-4">
              <Avatar className="w-10 h-10 bg-secondary text-secondary-foreground">
                {message.senderType === "USER" ? (
                  <span className="text-xl font-semibold">
                    {userData?.alias?.[0].toUpperCase() || "U"}
                  </span>
                ) : (
                  <span className="text-xl font-semibold">AI</span>
                )}
              </Avatar>
              <div className="flex-1 space-y-2">
                <p className="font-semibold">
                  {message.senderType === "USER" ? "You" : "Cultzyme AI"}
                </p>
                <div className="bg-accent text-accent-foreground p-3 rounded-lg">
                  {message.content}
                </div>
              </div>
            </div>
          ))}
          <div ref={endOfMessagesRef} />
        </ScrollArea>

        {/* Input Box */}
        <div className="p-4 border-t border-border">
          <div className="flex items-center space-x-2">
            <Input
              ref={inputRef}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your message here..."
              className="flex-grow"
            />
            <Button
              onClick={handleSendMessage}
              disabled={chatIsPrinting}
              className="bg-primary text-primary-foreground"
            >
              <Send className="h-4 w-4 mr-2" />
              Send
            </Button>
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" size="icon">
                  <Settings className="h-4 w-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <div className="space-y-4">
                  <h4 className="font-medium leading-none">Configuration</h4>
                  <div className="space-y-2">
                    <label className="text-sm">
                      Temperature: {config.temperature.toFixed(2)}
                    </label>
                    <Slider
                      min={0}
                      max={1}
                      step={0.01}
                      value={[config.temperature]}
                      onValueChange={([value]) =>
                        handleConfigChange("temperature", value)
                      }
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="public-data"
                      checked={config.withPublicData}
                      onCheckedChange={(checked) =>
                        handleConfigChange("withPublicData", checked)
                      }
                    />
                    <label htmlFor="public-data">Use Public Data</label>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
