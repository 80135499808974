import { useRef, useState } from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

import styles from "./PasswordProtectionWrapper.module.css";

const PasswordModal = ({ onClose, onSubmit }) => {
  const passwordRef = useRef();

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles["modal-container"]}>
        <label htmlFor="password">Password:</label>
        <input
          id="password"
          name="password"
          type="password"
          ref={passwordRef}
        />
        <button
          onClick={() => {
            onSubmit(passwordRef.current.value);
            onClose();
          }}
        >
          Submit
        </button>
      </div>
    </ModalWrapper>
  );
};

const PasswordProtectionWrapper = ({ children, onSubmit }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      {children(openModal)}
      {showModal && <PasswordModal onClose={closeModal} onSubmit={onSubmit} />}
    </div>
  );
};

export default PasswordProtectionWrapper;
