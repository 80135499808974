"use client";

import React, { useState, useCallback, useEffect } from "react";
import {
  FileIcon,
  UploadIcon,
  XIcon,
  Loader2,
  DownloadIcon,
  PlusIcon,
  LockIcon,
  InfoIcon,
} from "lucide-react";
import { Button } from "../../../components/UI/Shadcn/Button";
import { ScrollArea } from "../../../components/UI/Shadcn/ScrollArea";
import { Alert, AlertDescription } from "../../../components/UI/Shadcn/Alert";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/UI/Shadcn/Tooltip";
import { toast } from "../../../hooks/use-toast";

const MAX_TOTAL_SIZE = 512 * 1024 * 1024; // 512MB in bytes
const MAX_PDF_COUNT = 5;

export default function PDFDropzone({
  trainingDoeId,
  initialFiles = [],
  onFilesChange,
  canUploadPDFs,
  accessToken,
  isViewOnly,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadAreaVisible, setIsUploadAreaVisible] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [downloadError, setDownloadError] = useState(null);

  useEffect(() => {
    fetchUploadedFiles();
  }, []);

  useEffect(() => {
    setUploadedFiles(initialFiles);
  }, [initialFiles]);

  const fetchUploadedFiles = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/${trainingDoeId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch training DOE details: ${response.statusText}`
        );
      }

      const data = await response.json();
      setUploadedFiles(data.pdfs || []);
      onFilesChange(data.pdfs || []);
    } catch (error) {
      console.error("Error fetching uploaded PDFs:", error);
      toast({
        title: "Error",
        description: `Failed to load uploaded PDFs: ${error.message}`,
        variant: "destructive",
      });
    }
  };

  const uploadFiles = async () => {
    if (!canUploadPDFs) {
      toast({
        title: "Error",
        description: "PDF uploads are not allowed at this stage.",
        variant: "destructive",
      });
      return;
    }

    if (uploadedFiles.length + selectedFiles.length > MAX_PDF_COUNT) {
      toast({
        title: "Error",
        description: `You can upload a maximum of ${MAX_PDF_COUNT} PDFs in total.`,
        variant: "destructive",
      });
      return;
    }

    const totalSize = [...uploadedFiles, ...selectedFiles].reduce(
      (sum, file) => sum + (file.size || 0),
      0
    );
    if (totalSize > MAX_TOTAL_SIZE) {
      toast({
        title: "Error",
        description: `Total file size exceeds the limit of 512MB.`,
        variant: "destructive",
      });
      return;
    }

    setIsUploading(true);
    setUploadError(null);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("pdfs", file);
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/pdfs?trainingDoeId=${trainingDoeId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.statusText}`);
      }

      const result = await response.json();
      await fetchUploadedFiles();
      setSelectedFiles([]);
      toast({
        title: "Success",
        description: `${result.length} PDF(s) uploaded successfully.`,
      });
    } catch (error) {
      setUploadError(`Upload failed: ${error.message}`);
      toast({
        title: "Error",
        description: `Upload failed: ${error.message}`,
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const downloadPDF = async (pdfId, fileName) => {
    setDownloadError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/pdfs/download/${trainingDoeId}/${pdfId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (!response.ok) {
        throw new Error(`Download failed: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName || "download.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setDownloadError(`Failed to download ${fileName}: ${error.message}`);
      toast({
        title: "Error",
        description: `Failed to download ${fileName}: ${error.message}`,
        variant: "destructive",
      });
    }
  };

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(false);
      if (!canUploadPDFs || uploadedFiles.length >= MAX_PDF_COUNT) return;
      const droppedFiles = Array.from(e.dataTransfer.files);
      const pdfFiles = droppedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      setSelectedFiles((prevFiles) => {
        const newFiles = [...prevFiles, ...pdfFiles];
        const availableSlots = MAX_PDF_COUNT - uploadedFiles.length;
        if (newFiles.length > availableSlots) {
          toast({
            title: "Warning",
            description: `Only the first ${availableSlots} PDFs will be selected for upload.`,
            variant: "warning",
          });
          return newFiles.slice(0, availableSlots);
        }
        return newFiles;
      });
    },
    [canUploadPDFs, uploadedFiles.length]
  );

  const removeSelectedFile = useCallback((fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  }, []);

  const handleFileInput = useCallback(
    (e) => {
      if (
        e.target.files &&
        canUploadPDFs &&
        uploadedFiles.length < MAX_PDF_COUNT
      ) {
        const newFiles = Array.from(e.target.files);
        setSelectedFiles((prevFiles) => {
          const combinedFiles = [...prevFiles, ...newFiles];
          const availableSlots = MAX_PDF_COUNT - uploadedFiles.length;
          if (combinedFiles.length > availableSlots) {
            toast({
              title: "Warning",
              description: `Only the first ${availableSlots} PDFs will be selected for upload.`,
              variant: "warning",
            });
            return combinedFiles.slice(0, availableSlots);
          }
          return combinedFiles;
        });
      }
    },
    [canUploadPDFs, uploadedFiles.length]
  );

  const truncateFileName = (fileName, maxLength = 45) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split(".").pop();
    const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
    const truncatedName = nameWithoutExtension.slice(
      0,
      maxLength - 3 - extension.length
    );
    return `${truncatedName}...${extension}`;
  };

  const remainingPDFs = MAX_PDF_COUNT - uploadedFiles.length;

  return (
    <div className="w-full">
      {!isViewOnly && (
        <>
          {!isUploadAreaVisible && canUploadPDFs && remainingPDFs > 0 && (
            <Button
              onClick={() => setIsUploadAreaVisible(true)}
              className="mb-4 bg-primary text-background w-full"
            >
              <PlusIcon className="mr-2 h-4 w-4" /> Upload PDFs ({remainingPDFs}{" "}
              remaining)
            </Button>
          )}

          {isUploadAreaVisible && canUploadPDFs && remainingPDFs > 0 && (
            <>
              <div
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                className={`border-2 border-dashed rounded-lg p-4 text-center transition-colors ${
                  isDragging ? "border-blue-500 bg-card" : "border-primary"
                }`}
              >
                <UploadIcon className="mx-auto h-12  w-12 text-primary" />
                <p className="mt-2 text-sm text-gray-600">
                  Drag and drop PDF files here, or click to select files
                </p>
                <p className="mt-1 text-xs text-gray-500">
                  {remainingPDFs} PDF{remainingPDFs !== 1 ? "s" : ""} remaining,{" "}
                  {MAX_TOTAL_SIZE / (1024 * 1024)}MB total size limit
                </p>
                <input
                  type="file"
                  multiple
                  accept=".pdf"
                  className="hidden"
                  onChange={handleFileInput}
                  id="file-upload"
                  disabled={remainingPDFs === 0}
                />
                <Button
                  variant="outline"
                  className="mt-2"
                  onClick={() =>
                    document.getElementById("file-upload")?.click()
                  }
                  disabled={remainingPDFs === 0}
                >
                  Select Files
                </Button>
              </div>

              <Alert variant="info" className="mt-4">
                <InfoIcon className="h-4 w-4 mr-2" />
                <AlertDescription>
                  You can upload a maximum of {MAX_PDF_COUNT} PDFs in total,
                  with a total size limit of {MAX_TOTAL_SIZE / (1024 * 1024)}MB.
                </AlertDescription>
              </Alert>

              {uploadError && (
                <Alert variant="destructive" className="mt-4">
                  <AlertDescription>{uploadError}</AlertDescription>
                </Alert>
              )}

              {downloadError && (
                <Alert variant="destructive" className="mt-4">
                  <AlertDescription>{downloadError}</AlertDescription>
                </Alert>
              )}

              {selectedFiles.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-2">Selected PDFs:</h3>
                  <ScrollArea className="h-[200px] w-full border rounded">
                    <ul className="space-y-2 p-4">
                      {selectedFiles.map((file, index) => (
                        <li
                          key={`selected-${file.name}-${index}`}
                          className="flex items-center justify-between bg-card p-2 rounded"
                        >
                          <div className="flex items-center flex-grow mr-2">
                            <FileIcon className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <span className="text-sm truncate">
                                    {truncateFileName(file.name)}
                                  </span>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <p>{file.name}</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => removeSelectedFile(file)}
                            className="text-red-500 hover:text-red-700 flex-shrink-0"
                          >
                            <XIcon className="h-5 w-5" />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>
                  <Button
                    onClick={uploadFiles}
                    className="mt-4 bg-primary text-primary-foreground hover:bg-primary/90"
                    disabled={isUploading}
                  >
                    {isUploading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Uploading...
                      </>
                    ) : (
                      "Upload PDFs"
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}

      {uploadedFiles.length > 0 ? (
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">
            Uploaded PDFs ({uploadedFiles.length}/{MAX_PDF_COUNT}):
          </h3>
          <ScrollArea className="h-[200px] w-full border rounded">
            <ul className="space-y-2 p-4">
              {uploadedFiles.map((file) => (
                <li
                  key={file.pdfId}
                  className="flex items-center justify-between bg-card p-2 rounded"
                >
                  <div className="flex items-center flex-grow mr-2">
                    <FileIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <span className="text-sm truncate text-foreground">
                            {truncateFileName(file.fileName || "Unnamed PDF")}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{file.fileName || "Unnamed PDF"}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => downloadPDF(file.pdfId, file.fileName)}
                          className="text-background/90 hover:text-background bg-primary flex-shrink-0"
                        >
                          <DownloadIcon className="h-5 w-5" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Download PDF</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </li>
              ))}
            </ul>
          </ScrollArea>
        </div>
      ) : (
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            No PDFs uploaded for this training.
          </p>
        </div>
      )}

      {!canUploadPDFs && !isViewOnly && (
        <Alert className="mt-4">
          <LockIcon className="h-4 w-4 mr-2" />
          <AlertDescription>
            PDF uploads are not allowed at this stage of the training.
          </AlertDescription>
        </Alert>
      )}

      {uploadedFiles.length >= MAX_PDF_COUNT && !isViewOnly && (
        <Alert className="mt-4">
          <InfoIcon className="h-4 w-4 mr-2" />
          <AlertDescription>
            You have reached the maximum number of PDFs ({MAX_PDF_COUNT}) for
            this training.
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
}
