import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { debounce } from "lodash";
import { ChevronDown, Check } from "lucide-react";

const MoleculeCombobox = ({
  value,
  onChange,
  accessToken,
  selectedMoleculeType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [moleculeTypes, setMoleculeTypes] = useState([]);
  const comboboxRef = useRef(null);
  const inputRef = useRef(null);

  const fetchMolecules = useCallback(
    async (type, term) => {
      if (!accessToken || !type || !term) {
        console.warn("Missing accessToken, type, or search term");
        setOptions([]);
        return;
      }

      setIsSearching(true);
      setError(null);

      try {
        const selectedType = moleculeTypes.find((mt) => mt.value === type);
        if (!selectedType) {
          throw new Error("Invalid molecule type");
        }

        const endpoint = `${
          process.env.REACT_APP_BACKEND_URL
        }/cultzyme-api/v1/administrations/molecules/${selectedType.value.toLowerCase()}/${term}`;

        const response = await fetch(endpoint, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        if (!response.ok) {
          throw new Error(`API Error: ${response.status}`);
        }

        const data = await response.json();
        const fetchedOptions = Array.isArray(data) ? data : [];
        setOptions(fetchedOptions);

        if (fetchedOptions.length === 0) {
          console.warn("No options returned from the API");
        }
      } catch (err) {
        console.error("Error fetching molecules:", err);
        setError(err.message || "Failed to fetch molecules.");
        setOptions([]);
      } finally {
        setIsSearching(false);
      }
    },
    [accessToken, moleculeTypes]
  );

  const fetchMoleculeTypes = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/administrations/molecule-types`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch molecule types");
      }
      const data = await response.json();
      setMoleculeTypes(data);
    } catch (error) {
      console.error("Error fetching molecule types:", error);
      setError("Failed to fetch molecule types. Please try again.");
    }
  }, [accessToken]);

  useEffect(() => {
    fetchMoleculeTypes();
  }, [fetchMoleculeTypes]);

  const debouncedFetchMolecules = useMemo(
    () => debounce(fetchMolecules, 300),
    [fetchMolecules]
  );

  useEffect(() => {
    if (selectedMoleculeType && inputValue) {
      debouncedFetchMolecules(selectedMoleculeType, inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, selectedMoleculeType, debouncedFetchMolecules]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsOpen(true);
  };

  const handleComboboxToggle = () => {
    if (selectedMoleculeType) {
      setIsOpen(!isOpen);
      if (!isOpen) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
  };

  const getDescriptionForValue = (value) => {
    const moleculeType = moleculeTypes.find((mt) => mt.value === value);
    return moleculeType ? moleculeType.description : value;
  };

  return (
    <div className="relative w-full max-w-xs" ref={comboboxRef}>
      <button
        onClick={handleComboboxToggle}
        className={`flex items-center justify-between w-full px-4 py-2 text-left bg-background border border-input rounded-md shadow-sm hover:bg-accent hover:text-accent-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:border-input transition-colors duration-200 ${
          !selectedMoleculeType && "opacity-50 cursor-not-allowed"
        }`}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        disabled={!selectedMoleculeType}
      >
        <span className="block truncate text-foreground">
          {value
            ? getDescriptionForValue(value)
            : getDescriptionForValue(selectedMoleculeType) || "Molecule"}
        </span>
        <ChevronDown className="w-4 h-4 text-muted-foreground" />
      </button>

      {isOpen && selectedMoleculeType && (
        <div className="absolute z-10 w-full mt-1 bg-popover rounded-md shadow-md border border-border transition-all duration-200 ease-in-out opacity-100 scale-100 origin-top">
          <div className="p-2">
            <input
              ref={inputRef}
              type="text"
              className="w-full px-3 py-2 text-sm bg-muted text-muted-foreground border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-ring focus:border-input"
              placeholder="Search options..."
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <ul className="max-h-60 overflow-auto py-1 text-base" role="listbox">
            {isSearching ? (
              <li className="relative px-3 py-2 text-muted-foreground cursor-default select-none">
                Searching...
              </li>
            ) : error ? (
              <li className="relative px-3 py-2 text-destructive cursor-default select-none">
                {error}
              </li>
            ) : options.length === 0 ? (
              <li className="relative px-3 py-2 text-muted-foreground cursor-default select-none">
                No results found
              </li>
            ) : (
              options.map((option) => (
                <li
                  key={option}
                  className={`relative px-3 py-2 cursor-pointer select-none transition-colors duration-200
                    ${
                      value === option
                        ? "bg-primary text-primary-foreground"
                        : "text-popover-foreground hover:bg-accent hover:text-accent-foreground"
                    }`}
                  role="option"
                  aria-selected={value === option}
                  onClick={() => {
                    onChange(option);
                    setIsOpen(false);
                    setInputValue("");
                  }}
                >
                  <span className="block truncate">{option}</span>
                  {value === option && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-primary-foreground">
                      <Check className="w-5 h-5" />
                    </span>
                  )}
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MoleculeCombobox;
