import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-gray-800">
      <h1 className="text-3xl font-bold mb-6 text-black">
        Cultzyme Data Privacy and Policy
      </h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-black">
          Privacy Policy
        </h2>
        <p className="mb-4">
          <strong>RESPONSIBLE:</strong> CULTZYME, S.L.
        </p>
        <h3 className="text-xl font-semibold mb-2 text-black">
          Who is responsible for the processing of your data?
        </h3>
        <p className="mb-4">
          The owner of the web APP: www.cultzyme-APP.com (hereinafter, the APP)
          and responsible for its processing is the company CULTZYME, S.L.,
          domiciled in San Sebastián, Paseo de Miramón, 170, 3rd floor, 20014,
          with tax identification number B10691566 and registered in the
          Companies Register of Guipúzcoa, Volume 1820, page 43, sheet number
          SS-17285.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg">
          <strong>Contact details:</strong> Email: support@cultzyme.com / Tel:
          +(34) 943.062.035
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-black">
          Categories of Data, Purposes of Processing and Storage Periods
        </h2>
        <h3 className="text-xl font-semibold mb-2 text-black">
          What category of data do we process?
        </h3>
        <p className="mb-4">
          Cultzyme may collect the following personal information from the user,
          including but not limited to.
        </p>
        <p className="mb-4">
          When registering users provide personal information including e-mail
          address and telephone number.
        </p>
        <h3 className="text-xl font-semibold mb-2 text-black">
          For what purpose and for how long will we process your personal data?
        </h3>
        <p className="mb-4">
          The purposes of the processing of users' data may consist of:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>
            <strong>The management of user registration in the APP.</strong> To
            use the BION together with the software service for the management,
            control, and data analysis of the bioprocesses carried out, the User
            must first register in the APP. During registration, the User will
            be required to provide identification data, including their e-mail
            and phone number. These essential details are necessary for Cultzyme
            to process the User's request for the service. Additionally, please
            note that the User's email will be used to send personalized emails,
            ensuring a more tailored and enhanced user experience.
          </li>
          <li>
            <strong>Preservation of information.</strong> Cultzyme will process
            and store the personal data provided by the user when registering in
            the APP for as long as the user does not request the deletion of
            his/her registration data, and is a customer/user of Cultzyme, as
            well as during the period of limitation of legal obligations, which
            may arise from possible liabilities linked to the processing of
            customer/user data.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-black">
          Legitimisation of the Processing
        </h2>
        <h3 className="text-xl font-semibold mb-2 text-black">
          What is the legitimacy for the processing of your data?
        </h3>
        <p className="mb-4">
          The legal basis for processing the personal data of customers/users of
          the APP lies in the consent of the interested party, in the legitimate
          interest of Cultzyme and in the commercial relationship that binds it
          to CULTZYME.
        </p>
        <p className="mb-4">
          Users always voluntarily access the services offered in the APP, and
          provide their data to Cultzyme in the same way, when registering in
          the APP. If they do not provide the minimum data requested when
          registering in the APP, they will not be able to access the contents
          of the APP or access the services requested.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-black">Rights</h2>
        <h3 className="text-xl font-semibold mb-2 text-black">
          What are your rights?
        </h3>
        <p className="mb-4">
          Pursuant to the applicable Data Protection legislation in force, users
          may exercise the rights listed below, in accordance with the procedure
          detailed below:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>
            <strong>Right to revoke consent:</strong> if applicable, Users may
            revoke their consent to the collection and processing of their
            personal data at any time, by means of the procedure established for
            this purpose, without this affecting the lawfulness of the
            processing prior to its withdrawal.
          </li>
          <li>
            <strong>Right of Access:</strong> users may request information
            about the personal data they have provided to Cultzyme.
          </li>
          <li>
            <strong>Right of rectification or deletion:</strong> users may
            request the correction or deletion of the personal data they have
            provided.
          </li>
          <li>
            <strong>Portability:</strong> if applicable, users may request the
            portability of the personal data they have provided.
          </li>
          <li>
            <strong>Limitation of processing:</strong> if applicable, users may
            request the limitation of the processing of their data.
          </li>
          <li>
            <strong>Opposition:</strong> if applicable, users may oppose the
            processing of their data.
          </li>
        </ul>
        <h3 className="text-xl font-semibold mb-2 text-black">
          Procedure for exercising rights:
        </h3>
        <p className="mb-4">
          Users, holders of personal data, shall exercise the aforementioned
          rights:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>
            Send a letter to the following e-mail address:
            dataprotection@cultzyme.com
          </li>
          <li>
            This letter must:
            <ul className="list-disc pl-6 mt-2">
              <li>Include the reference "Data Protection, APP usage".</li>
              <li>Indicate the purpose of your request.</li>
            </ul>
          </li>
        </ul>
        <p className="mb-4">
          In accordance with the applicable regulations in force (EU Regulation
          2016/679 and Organic Law 3/2018, of 5 December, on the Protection of
          Personal Data and guarantee of digital rights), we will reply to you
          within the legal deadlines established by the aforementioned
          Regulations.
        </p>
        <p className="mb-4">
          In case you feel that your rights concerning the protection of your
          personal data are not satisfied, you can file a complaint with the
          competent Data Protection Agency via its website www.agpd.es.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-black">
          Privacy Policy Update
        </h2>
        <h3 className="text-xl font-semibold mb-2 text-black">
          Can the privacy policy be changed?
        </h3>
        <p className="mb-4">
          Cultzyme may at any time and without prior notice modify this Privacy
          Policy to adapt them to new legislation or by strategic corporate
          decisions. Likewise, the user is recommended to access them each time
          he/she intends to use the content or services offered through the APP.
        </p>
      </section>
    </div>
  );
}
