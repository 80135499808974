import React, { useState, cloneElement } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../UI/Shadcn/Accordion";
import { MdAutoAwesome as ControlsIcon } from "react-icons/md";
import { ChevronLeft } from "lucide-react";
import MenuImg from "../../../../../assets/images/PlannerImages/white/menu.png";

const ControlButton = ({ control, isSelected, onClick }) => (
  <button
    className={`flex items-center -ml-[26px] mb-6 transition-all duration-300 ${
      isSelected
        ? "text-primary scale-110"
        : "text-muted-foreground hover:text-foreground hover:scale-105"
    }`}
    onClick={onClick}
  >
    <span
      className={`flex items-center justify-center w-[55px] h-[55px] rounded-full shadow-md transition-all duration-300 ${
        isSelected
          ? "bg-primary text-primary-foreground"
          : "bg-secondary text-secondary-foreground hover:bg-primary/20"
      }`}
    >
      {control.imgDark ? (
        <>
          <img
            src={control.img}
            alt={control.name}
            className="h-3/5 dark:hidden filter brightness-0 contrast-100"
          />
          <img
            src={control.imgDark}
            alt={control.name}
            className="h-3/5 hidden dark:block filter-none"
          />
        </>
      ) : (
        <img
          src={control.img}
          alt={control.name}
          className="h-3/5 filter brightness-0 contrast-100 dark:filter-none"
        />
      )}
    </span>
  </button>
);

const ControlCard = ({ control, onClick }) => (
  <button
    onClick={onClick}
    className="min-h-[350px] bg-card text-card-foreground font-bold text-lg p-6 rounded-lg relative overflow-hidden group transition-all duration-300 hover:shadow-xl border border-border hover:border-primary/50 hover:scale-105"
  >
    <div className="absolute inset-0 bg-gradient-to-br from-primary/10 via-primary/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
    <div className="relative z-20 flex flex-col items-center justify-center h-full">
      <div className="mb-6 p-6 bg-background rounded-full shadow-inner group-hover:shadow-md transition-all duration-300">
        {control.imgDark ? (
          <>
            <img
              src={control.img}
              alt={control.name}
              className="w-24 h-24 object-contain dark:hidden filter brightness-0 contrast-100"
            />
            <img
              src={control.imgDark}
              alt={control.name}
              className="w-24 h-24 object-contain hidden dark:block filter-none"
            />
          </>
        ) : (
          <img
            src={control.img}
            alt={control.name}
            className="w-24 h-24 object-contain filter brightness-0 contrast-100 dark:filter-none"
          />
        )}
      </div>
      <div className="mt-4 text-foreground group-hover:text-primary transition-colors duration-300">
        {control.name}
      </div>
    </div>
  </button>
);

const ControlPlanning = ({
  isExpanded,
  plannerData,
  setPlannerData,
  controls,
}) => {
  const [controlSelected, setControlSelected] = useState(null);

  const selectControlHandler = (controlName) => {
    setControlSelected(controlName);
  };

  const getComponentProps = () => {
    const compData = controls.find((part) => part.name === controlSelected);
    return {
      name: compData.name,
      options: compData.fetchedPlaceholders,
      prompts: compData.fetchedPrompts,
      plannerData,
      setPlannerData,
    };
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue={isExpanded ? "item-1" : undefined}
      className="border-2 rounded-lg border-primary bg-background text-foreground shadow-lg mt-4 overflow-hidden"
    >
      <AccordionItem value="item-1" className="border-none">
        <AccordionTrigger className="hover:no-underline p-6 group transition-all duration-300 hover:bg-primary/5">
          <div className="flex items-center w-full">
            <div className="bg-primary/10 p-3 rounded-full flex-shrink-0 mr-6 group-hover:bg-primary/20 transition-colors duration-300">
              <ControlsIcon className="text-4xl text-primary" />
            </div>
            <div className="flex-grow flex flex-col items-start justify-start">
              <h3 className="text-xl font-semibold group-hover:text-primary transition-colors duration-300">
                Controls Planning
              </h3>
              <p className="text-muted-foreground mt-1 group-hover:text-foreground transition-colors duration-300">
                Chart precise controls for optimal bioprocess conditions
              </p>
            </div>
            <div className="ml-auto text-right mr-4 flex-shrink-0">
              <p className="text-lg font-bold group-hover:text-primary transition-colors duration-300">
                Not started
              </p>
              <p className="text-muted-foreground mt-1 group-hover:text-foreground transition-colors duration-300">
                Fill the details
              </p>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-6 py-4 bg-background min-h-[60vh]">
          {controlSelected ? (
            <div className="flex gap-8">
              <div className="flex flex-col items-start border-l-2 border-dashed border-muted-foreground py-4">
                <ControlButton
                  control={{ img: MenuImg, name: "Menu" }}
                  onClick={() => selectControlHandler(null)}
                />
                {controls.map((control) => (
                  <ControlButton
                    key={control.name}
                    control={control}
                    isSelected={control.name === controlSelected}
                    onClick={() => selectControlHandler(control.name)}
                  />
                ))}
              </div>
              <div className="flex-grow">
                <div className="mb-6">
                  <button
                    onClick={() => selectControlHandler(null)}
                    className="flex items-center text-muted-foreground hover:text-primary transition-colors duration-300 group"
                  >
                    <ChevronLeft className="mr-2 group-hover:transform group-hover:-translate-x-1 transition-transform duration-300" />
                    Back to Controls
                  </button>
                </div>
                {controlSelected &&
                  cloneElement(
                    controls.find((control) => control.name === controlSelected)
                      .component,
                    getComponentProps()
                  )}
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
              {controls.map((control) => (
                <ControlCard
                  key={control.name}
                  control={control}
                  onClick={() => setControlSelected(control.name)}
                />
              ))}
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ControlPlanning;
