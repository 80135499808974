/**
 * Updates items in the localStorage based on key-value pairs.
 * @param {Array<Array<string>>} itemsToUpdate - An array of key-value pairs to update in localStorage.
 */
export const updateLocalStorageItems = (...itemsToUpdate) => {
  for (const item of itemsToUpdate) {
    const [key, value] = item;
    localStorage.setItem(key, value);
  }
};

/**
 * Retrieves items from the localStorage based on provided keys.
 * @param {Array<string>} keysToRetrieve - An array of keys to retrieve from localStorage.
 * @returns {Object|string} - An object containing retrieved values (for multiple keys) or a single retrieved value.
 */
export const getLocalStorageItems = (...keysToRetrieve) => {
  if (keysToRetrieve.length === 1) {
    return localStorage.getItem(keysToRetrieve[0]);
  }

  const retrievedItems = {};
  for (const key of keysToRetrieve) {
    retrievedItems[key] = localStorage.getItem(key);
  }
  return retrievedItems;
};

/**
 * Removes items from the localStorage based on provided keys.
 * @param {Array<string>} keysToRemove - An array of keys to remove from localStorage.
 */
export const removeLocalStorageItems = (...keysToRemove) => {
  for (const key of keysToRemove) {
    localStorage.removeItem(key);
  }
};

const localStorageUtils = {
  updateLocalStorageItems,
  getLocalStorageItems,
  removeLocalStorageItems,
};

export default localStorageUtils;
