import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { IoIosArrowDown as ExpandIcon } from "react-icons/io";

import usePromptEngine from "../../../../../../../../hooks/use-prompt_engine";

import classNames from "classnames/bind";

import commonStyles from "../../CommonStyles.module.css";

const cx = classNames.bind(commonStyles);

const LinePlans = ({
  lineIdx,
  prompt,
  options,
  plannerData,
  setPlannerData,
  name,
}) => {
  const plans = plannerData?.[name].MEDIA_LINE_CTRL[lineIdx];
  // console.log(plans);
  const {
    renderPrompt,
    checkAllItemsForCompletion: checkAllPlansForCompletion,
    checkItemForCreation: checkPlanForCreation,
    addDataItem: addPlan,
  } = usePromptEngine(
    prompt,
    options,
    plans,
    setPlannerData,
    "MEDIA_LINE_CTRL",
    null,
    lineIdx
  );

  return (
    <div>
      {plans?.length > 0 &&
        plans.map((plan, index) => (
          <Accordion
            key={index}
            //   expanded={isExpanded}
            className={cx("accordion", "accordion--light")}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ExpandIcon className={cx("expand-icon")} />}
              className={cx("accordionSummary")}
            >
              <div className={cx("accordionSummary__container")}>
                {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                <div className={cx("info__container")}>
                  <div>
                    <h3>
                      Line {lineIdx + 1} Plan {index + 1}
                    </h3>
                  </div>
                  <p>Fine-Tuning Your Bioprocess with Precision</p>
                </div>

                <div className={cx("status__container")}>
                  <p>Not started</p>
                  <p>Fill the details</p>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className={cx("accordionDetails")}>
              <div>
                <div className={cx("prompt")}>{renderPrompt(index)}</div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      {checkAllPlansForCompletion() &&
        checkPlanForCreation(plans.length - 1, plans) && (
          <Accordion
            className={cx("accordion", "accordion--light", "accordion--add")}
            disableGutters
            onClick={addPlan}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              className={cx("accordionSummary")}
            >
              <div className={cx("accordionSummary__container")}>
                {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                <div className={cx("info__container")}>
                  <div>
                    <h3>Line {lineIdx + 1} New Planning</h3>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              className={cx("accordionDetails")}
            ></AccordionDetails>
          </Accordion>
        )}
    </div>
  );
};

export default LinePlans;
