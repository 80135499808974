import React, { useState, useRef } from "react";
import { Button } from "../UI/Shadcn/Button";
import { Input } from "../UI/Shadcn/Input";
import { Slider } from "../UI/Shadcn/Slider";
import { Switch } from "../UI/Shadcn/Switch";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../UI/Shadcn/Dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../UI/Shadcn/Tooltip";
import { Label } from "../UI/Shadcn/Label";
import { Separator } from "../UI/Shadcn/Separator";
import { Loader2, Send, Settings } from "lucide-react";

export default function ChatbotInput({
  sendMessage,
  isLoading,
  temperature,
  setTemperature,
  usePublicData,
  setUsePublicData,
  dynamicSuggestions,
  isSuggestionsLoading,
}) {
  const [inputMessage, setInputMessage] = useState("");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const inputRef = useRef(null);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      sendMessage(inputMessage);
      setInputMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="border-t border-border bg-background p-4">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-wrap gap-2 w-full min-h-[40px]">
          {isLoading || isSuggestionsLoading ? (
            <div className="flex items-center text-muted-foreground">
              <Loader2 className="h-4 w-4 animate-spin mr-2" />
              <span>Loading suggestions...</span>
            </div>
          ) : dynamicSuggestions && dynamicSuggestions.length > 0 ? (
            dynamicSuggestions.map((reply, index) => (
              <Button
                key={index}
                onClick={() => setInputMessage(reply)}
                variant="outline"
                size="sm"
                className="bg-secondary text-secondary-foreground hover:bg-secondary/90"
              >
                {reply}
              </Button>
            ))
          ) : (
            <span className="text-muted-foreground">
              No suggestions available
            </span>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <div className="relative flex-grow">
            <Input
              ref={inputRef}
              type="text"
              placeholder="Type your message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              className="pr-20 focus:ring-2 focus:ring-ring focus:border-input rounded-full py-6 bg-background text-foreground placeholder-muted-foreground"
              disabled={isLoading}
            />
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Dialog
                      open={isSettingsOpen}
                      onOpenChange={setIsSettingsOpen}
                    >
                      <DialogTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          className="rounded-full bg-background text-muted-foreground hover:text-foreground hover:bg-accent"
                        >
                          <Settings className="h-4 w-4" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="bg-background text-foreground">
                        <DialogHeader>
                          <DialogTitle>Chat Settings</DialogTitle>
                        </DialogHeader>
                        <div className="py-4">
                          <div className="mb-4">
                            <Label className="text-sm font-medium mb-1 block text-foreground">
                              Temperature: {temperature.toFixed(2)}
                            </Label>
                            <Slider
                              value={[temperature]}
                              onValueChange={(value) =>
                                setTemperature(value[0])
                              }
                              max={1}
                              step={0.01}
                              className="mt-2"
                            />
                          </div>
                          <Separator className="my-4 bg-border" />
                          <div className="flex items-center space-x-2">
                            <Switch
                              checked={usePublicData}
                              onCheckedChange={setUsePublicData}
                              id="public-data"
                            />
                            <Label
                              htmlFor="public-data"
                              className="text-sm font-medium text-foreground"
                            >
                              Use Public Data
                            </Label>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </TooltipTrigger>
                  <TooltipContent className="bg-popover text-popover-foreground">
                    <p>Chat Settings</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <Button
                onClick={handleSendMessage}
                disabled={isLoading || !inputMessage.trim()}
                size="icon"
                className="bg-primary text-primary-foreground hover:bg-primary/90 rounded-full"
              >
                {isLoading ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <Send className="h-4 w-4" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
