"use client";

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/UI/Shadcn/Card";
import { Loader2, AlertCircle, Download } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../components/UI/Shadcn/Alert";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/UI/Shadcn/Tabs";
import { Badge } from "../../components/UI/Shadcn/Badge";
import { ScrollArea } from "../../components/UI/Shadcn/ScrollArea";
import { Button } from "../../components/UI/Shadcn/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../components/UI/Shadcn/Dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/UI/Shadcn/Tooltip";
import PDFDropzone from "./TrainingComponents/PDFDropzone";
import ExperimentalDesigns from "./TrainingComponents/ExperimentalDesigns";
import Optimizations from "./TrainingComponents/Optimizations";
import ImpactFactorsManager from "./TrainingComponents/ImpactFactorsManager";
import { useToast } from "../../hooks/use-toast";
import {
  useGetTrainingQuery,
  useStartDesignMutation,
  useGenerateImpactFactorsMutation,
  useCreateExperimentalDesignsMutation,
  useGenerateExperimentalDesignsMutation,
} from "../../app/api/aiApiSlice";
import CreateChatButton from "./TrainingComponents/CreateChatButton";

const stateConfig = {
  SEARCH: {
    canGenerateImpactFactors: true,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
    canUploadPDFs: true,
  },
  DESIGN: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: true,
    canViewOptimizations: false,
    canUploadPDFs: false,
  },
  GENERATE: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
    canUploadPDFs: false,
  },
  null: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: true,
    canViewOptimizations: true,
    isViewOnly: true,
    canUploadPDFs: false,
  },
  FAILED: {
    canGenerateImpactFactors: false,
    canViewExperimentalDesigns: false,
    canViewOptimizations: false,
    canUploadPDFs: false,
  },
};

export default function TrainingView() {
  const { trainingDoeId } = useParams();
  const [pdfs, setPdfs] = useState([]);
  const [impactFactors, setImpactFactors] = useState(null);
  const [isImpactFactorsModalOpen, setIsImpactFactorsModalOpen] =
    useState(false);
  const [isNoPDFModalOpen, setIsNoPDFModalOpen] = useState(false);
  const [isGeneratingModalOpen, setIsGeneratingModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("training");
  const navigate = useNavigate();

  const accessToken = useSelector(selectCurrentToken);
  const { toast } = useToast();

  const {
    data: training,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetTrainingQuery(trainingDoeId);
  const [startDesign, { isLoading: isStartingDesign }] =
    useStartDesignMutation();
  const [generateImpactFactors, { isLoading: isGeneratingImpactFactors }] =
    useGenerateImpactFactorsMutation();
  const [
    createExperimentalDesigns,
    { isLoading: isCreatingExperimentalDesigns },
  ] = useCreateExperimentalDesignsMutation();
  const [
    generateExperimentalDesigns,
    { isLoading: isGeneratingExperimentalDesigns },
  ] = useGenerateExperimentalDesignsMutation();

  useEffect(() => {
    if (training) {
      setPdfs(
        training.pdfs.map(
          (pdf) =>
            new File([], pdf.fileName, {
              type: "application/pdf",
              lastModified: new Date(pdf.createdDate).getTime(),
            })
        )
      );
      const currentStateConfig =
        stateConfig[training.state] || stateConfig.SEARCH;
      if (currentStateConfig.canViewExperimentalDesigns) {
        setActiveTab("experimental");
      } else {
        setActiveTab("training");
      }
      setIsGeneratingModalOpen(
        training.state === "GENERATE" || training.state === "FAILED"
      );
    }
  }, [training]);

  const handlePDFsChange = (newPDFs) => {
    setPdfs(newPDFs);
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleGenerateImpactFactors = useCallback(async () => {
    try {
      const result = await generateImpactFactors({ trainingDoeId }).unwrap();
      setImpactFactors(result);
      setIsImpactFactorsModalOpen(true);
      refetch();
      toast({
        title: "Success",
        description: "Impact factors generated successfully.",
      });
    } catch (err) {
      console.error("Error generating impact factors:", err);
      toast({
        title: "Error",
        description: `Failed to generate impact factors: ${err.message}`,
        variant: "destructive",
      });
    }
  }, [generateImpactFactors, trainingDoeId, refetch, toast]);

  const handleStartDesign = async (experimentalDesignId) => {
    try {
      await startDesign({
        trainingDoeId,
        experimentalDesignId,
        measureFrequency: 1,
      }).unwrap();
      refetch();
    } catch (err) {
      toast({
        title: "Error",
        description: `Failed to start design: ${err.message}`,
        variant: "destructive",
      });
    }
  };

  const handleCreateExperimentalDesigns = async (selectedFactors) => {
    try {
      await createExperimentalDesigns({
        trainingDoeId,
        impactFactorsIntroduction: impactFactors.impactFactorsIntroduction,
        impactFactors: selectedFactors,
      }).unwrap();
      refetch();
      setIsImpactFactorsModalOpen(false);
      toast({
        title: "Success",
        description:
          "We are generating your experimental designs. You will be notified by email once they are ready.",
      });
    } catch (err) {
      console.error("Error creating experimental designs:", err);
      toast({
        title: "Error",
        description: `Failed to create experimental designs: ${err.message}`,
        variant: "destructive",
      });
    }
  };

  const handleGenerateExperimentalDesigns = async () => {
    try {
      await generateExperimentalDesigns({
        trainingDoeId,
      }).unwrap();
      refetch();
      setIsGeneratingModalOpen(true);
      toast({
        title: "Success",
        description:
          "Experimental designs generation started. You will be notified when it's complete.",
      });
    } catch (err) {
      console.error("Error generating experimental designs:", err);
      toast({
        title: "Error",
        description: `Failed to generate experimental designs: ${err.message}`,
        variant: "destructive",
      });
      // Set the modal to open even if there's an error
      setIsGeneratingModalOpen(true);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
      </div>
    );
  }

  if (isError || !training) {
    navigate("/trainings/not-found");
    return null;
  }

  if (!training) {
    return <div>No training data available.</div>;
  }

  const currentStateConfig = stateConfig[training.state] || stateConfig.SEARCH;
  const showOptimizationsTab = training.finished;

  const canUploadPDFs =
    currentStateConfig.canUploadPDFs &&
    (!training.experimentalDesigns ||
      training.experimentalDesigns.length === 0);

  return (
    <div className="container mx-auto p-4" style={{ isolation: "isolate" }}>
      <h1 className="text-2xl font-bold mb-4">{training.trainingDoeName}</h1>
      {currentStateConfig.isViewOnly && (
        <Alert className="mb-4 bg-primary text-background">
          <AlertCircle className="h-4 w-4 text-white" />
          <AlertTitle>View-Only Mode</AlertTitle>
          <AlertDescription>
            This training is in view-only mode. You can download elements but
            cannot make changes.
          </AlertDescription>
        </Alert>
      )}
      <Card className="relative">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Training Details</CardTitle>
          <CreateChatButton training={training} accessToken={accessToken}>
            Ask Me Anything
          </CreateChatButton>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p>
                <strong>Start Date:</strong>{" "}
                {new Date(training.startDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Finish Date:</strong>{" "}
                {training.finishDate
                  ? new Date(training.finishDate).toLocaleDateString()
                  : "Not finished"}
              </p>
              <p>
                <strong>Process Type:</strong> {training.processTypeDescription}
              </p>
              <p>
                <strong>Aim Type:</strong> {training.aimTypeDescription}
              </p>
              <p>
                <strong>GMO Activated:</strong>{" "}
                {training.gmoActivated ? "Yes" : "No"}
              </p>
              <p>
                <strong>GMO Purpose:</strong> {training.gmoPurposeDescription}
              </p>
              <div className="mt-4 text-left">
                <Badge
                  className="bg-primary text-background"
                  variant={training.finished ? "default" : "secondary"}
                >
                  {training.finished ? "Finished" : "In Progress"}
                </Badge>
              </div>
            </div>
            <div>
              <p>
                <strong>Microorganism:</strong>{" "}
                {training.microOrganismDescription}
              </p>
              <p>
                <strong>Bioprocess Target:</strong>{" "}
                {training.bioProcessTargetTypeDescription}
              </p>
              <p>
                <strong>Molecule Type:</strong>{" "}
                {training.moleculeTypeDescription}
              </p>
              <p>
                <strong>Molecule:</strong> {training.moleculeDescription}
              </p>
              <p>
                <strong>Production Volume:</strong> {training.productionVolume}{" "}
                L
              </p>
              <p>
                <strong>Bioreactor Model:</strong>{" "}
                {training.bioreactorModelDescription}
              </p>
              <p>
                <strong>Process Mode:</strong> {training.processModeDescription}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="mt-4">
        <TabsList className="bg-muted">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <TabsTrigger
                  value="experimental"
                  disabled={!currentStateConfig.canViewExperimentalDesigns}
                  className={`${
                    activeTab === "experimental"
                      ? "bg-background text-foreground"
                      : "text-muted-foreground"
                  } data-[state=active]:bg-background data-[state=active]:text-foreground`}
                >
                  Experimental Designs
                </TabsTrigger>
              </TooltipTrigger>
              {!currentStateConfig.canViewExperimentalDesigns && (
                <TooltipContent>
                  {training.state === "SEARCH"
                    ? "This tab is disabled because no impact factors have been created yet."
                    : training.state === "GENERATE"
                    ? "Experimental designs are being generated."
                    : "This tab is not available in the current state."}
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
          {showOptimizationsTab && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <TabsTrigger
                    value="optimizations"
                    className={`${
                      activeTab === "optimizations"
                        ? "bg-background text-foreground"
                        : "text-muted-foreground"
                    } data-[state=active]:bg-background data-[state=active]:text-foreground`}
                  >
                    Optimizations
                  </TabsTrigger>
                </TooltipTrigger>
              </Tooltip>
            </TooltipProvider>
          )}
          <TabsTrigger
            value="training"
            className={`${
              activeTab === "training"
                ? "bg-background text-foreground"
                : "text-muted-foreground"
            } data-[state=active]:bg-background data-[state=active]:text-foreground`}
          >
            Training Data
          </TabsTrigger>
        </TabsList>
        <TabsContent value="experimental">
          <ExperimentalDesigns
            experimentalDesigns={training.experimentalDesigns}
            onStartDesign={handleStartDesign}
            isStartingDesign={isStartingDesign}
            trainingDoeId={trainingDoeId}
            accessToken={accessToken}
            isViewOnly={currentStateConfig.isViewOnly}
            onUpdate={refetch}
          />
        </TabsContent>
        {showOptimizationsTab && (
          <TabsContent value="optimizations">
            <Optimizations
              optimizations={training.optimizations}
              isViewOnly={currentStateConfig.isViewOnly}
              accessToken={accessToken}
              trainingDoeId={trainingDoeId}
            />
          </TabsContent>
        )}
        <TabsContent value="training">
          <div className="grid grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle>Papers</CardTitle>
              </CardHeader>
              <CardContent>
                <ScrollArea className="h-[400px]">
                  {training.papers.map((paper) => (
                    <div key={paper.paperId} className="mb-4">
                      <h3 className="font-semibold">{paper.title}</h3>
                      <p>
                        <strong>Score:</strong> {paper.score.toFixed(2)}
                      </p>
                      <p>
                        <strong>Private:</strong>{" "}
                        {paper.isPrivate ? "Yes" : "No"}
                      </p>
                      <p>
                        <strong>Searched:</strong>{" "}
                        {new Date(paper.searchedDate).toLocaleDateString()}
                      </p>
                      <a
                        href={paper.url}
                        className="text-primary hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Paper
                      </a>
                    </div>
                  ))}
                </ScrollArea>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>PDFs</CardTitle>
              </CardHeader>
              <CardContent>
                {currentStateConfig.isViewOnly ? (
                  <ScrollArea className="h-[400px]">
                    {pdfs.map((pdf, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center mb-2"
                      >
                        <span>{pdf.name}</span>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleDownload(pdf.url, pdf.name)}
                        >
                          <Download className="h-4 w-4 mr-2" />
                          Download
                        </Button>
                      </div>
                    ))}
                  </ScrollArea>
                ) : (
                  <PDFDropzone
                    trainingDoeId={trainingDoeId}
                    initialFiles={pdfs}
                    onFilesChange={handlePDFsChange}
                    canUploadPDFs={canUploadPDFs}
                    accessToken={accessToken}
                  />
                )}
              </CardContent>
            </Card>
          </div>
        </TabsContent>
      </Tabs>

      {currentStateConfig.canGenerateImpactFactors &&
        !currentStateConfig.isViewOnly && (
          <div className="mt-4">
            <Button
              onClick={() => {
                if (pdfs.length === 0) {
                  setIsNoPDFModalOpen(true);
                } else {
                  handleGenerateImpactFactors();
                }
              }}
              className="bg-primary text-primary-foreground hover:bg-primary/90"
              disabled={
                isGeneratingImpactFactors ||
                (training.experimentalDesigns &&
                  training.experimentalDesigns.length > 0)
              }
            >
              {isGeneratingImpactFactors ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Generating Impact Factors...
                </>
              ) : training.experimentalDesigns &&
                training.experimentalDesigns.length > 0 ? (
                "Impact Factors Already Generated"
              ) : (
                "Generate Impact Factors"
              )}
            </Button>
          </div>
        )}

      <Dialog
        open={isImpactFactorsModalOpen}
        onOpenChange={setIsImpactFactorsModalOpen}
      >
        <DialogContent className="sm:max-w-[425px] md:max-w-[700px]">
          <DialogHeader>
            <DialogTitle>Impact Factors</DialogTitle>
          </DialogHeader>
          {impactFactors && (
            <ImpactFactorsManager
              data={impactFactors}
              trainingDoeId={trainingDoeId}
              onExperimentalDesignsGenerated={handleCreateExperimentalDesigns}
              isCreatingExperimentalDesigns={isCreatingExperimentalDesigns}
              isViewOnly={currentStateConfig.isViewOnly}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={isNoPDFModalOpen} onOpenChange={setIsNoPDFModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>No PDFs Uploaded</DialogTitle>
            <DialogDescription>
              You haven't uploaded any PDFs yet. Do you want to continue
              generating impact factors without PDFs?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsNoPDFModalOpen(false);
                handleGenerateImpactFactors();
              }}
              disabled={isGeneratingImpactFactors}
            >
              {isGeneratingImpactFactors ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Generating...
                </>
              ) : (
                "Yes, Continue Without PDFs"
              )}
            </Button>
            <Button
              onClick={() => {
                setIsNoPDFModalOpen(false);
                document
                  .querySelector(".PDFDropzone")
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              No, Upload PDFs First
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {isGeneratingModalOpen && training.state !== "SEARCH" && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="absolute inset-0 bg-black/50 backdrop-blur-sm z-[40]"
            style={{ left: "56px" }}
          ></div>
          <div className="relative bg-card rounded-lg p-8 max-w-md w-full z-[70]">
            <h2 className="text-2xl font-bold mb-4">
              {training.state === "FAILED"
                ? "Experimental Designs Generation Failed"
                : "Generating Experimental Designs"}
            </h2>
            <div className="flex items-center space-x-4">
              {training.state === "FAILED" ? (
                <AlertCircle className="h-8 w-8 text-destructive" />
              ) : (
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
              )}
              <p className="text-foreground">
                {training.state === "FAILED"
                  ? "The generation of experimental designs has failed. You can retry the process."
                  : "We are generating your experimental designs. You will be notified by email once they are ready. Check also the spam/junk folder."}
              </p>
            </div>
            {training.state === "FAILED" && (
              <Button
                className="bg-primary text-primary-foreground hover:bg-primary/90 w-full mt-4"
                onClick={handleGenerateExperimentalDesigns}
                disabled={isGeneratingExperimentalDesigns}
              >
                {isGeneratingExperimentalDesigns ? "Retrying..." : "Retry"}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
