import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-hot-toast";
import { setCredentials, logOut } from "../../features/auth/authSlice";
import { getLocalStorageItems } from "../../utils/localStorageUtils";

// Define the baseQuery function with necessary headers and authentication logic
const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1`,
  prepareHeaders: (headers, { getState }) => {
    // If a refresh token is present in headers, no further modification is needed
    if (headers.has("refreshtoken")) return headers;

    // Get the accessToken from the state
    const accessToken = getState().auth.token;

    // If an access token is available, add it to the authorization header
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }

    // Return the updated headerss
    return headers;
  },
});

// Function to handle token refreshing and retries in case of a 401 error
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Make the initial API request
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.data?.message) toast.error(result.error.data.message);
  if (result?.data?.message) toast.success(result.data.message);

  // console.log("hiii", result);

  // If the API request returned a 401 error (unauthorized)
  if (result?.error?.status === 401) {
    // Get the refresh token from the localStorage
    const refreshToken = getLocalStorageItems("refreshToken");

    // Send a request to refresh the token
    const refreshResult = await baseQuery(
      {
        url: `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1`,
        method: "POST",
        headers: { refreshtoken: refreshToken },
        body: { userName: api.getState().auth.user?.userName },
      },
      api,
      extraOptions
    );

    // If the refresh request was successful and a new access token is obtained
    if (refreshResult?.data) {
      // Update the credentials in the auth state with the new access token
      api.dispatch(setCredentials(refreshResult.data));

      // Retry the initial API request with the new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // If the refresh request failed or didn't return a new token, log the user out
      api.dispatch(logOut());
    }
  }

  // Return the API response
  return result;
};

export const aiApiSlice = createApi({
  reducerPath: "apiThree",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Chats"],
  endpoints: (build) => ({}),
});
