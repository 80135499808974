import { aiApiSlice } from "../../app/api/aiApiSlice2";

export const documentApiSlice = aiApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getChats: build.query({
      query: () => "/chats",
    }),
    getChatById: build.query({
      query: (chatId) => `/chats/${chatId}`,
    }),
    createChat: build.mutation({
      query: () => ({
        url: "/chats",
        method: "POST",
      }),
    }),
    deleteChat: build.mutation({
      query: (chatId) => ({
        url: `/chats/${chatId}`,
        method: "DELETE",
      }),
    }),
    sendMessage: build.mutation({
      query: (message) => ({
        url: `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/assistants`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "text/event-stream",
        },
        body: JSON.stringify(message),
      }),
    }),
  }),
});

export const {
  useGetChatsQuery,
  useGetChatByIdQuery,
  useCreateChatMutation,
  useDeleteChatMutation,
  useSendMessageMutation,
} = documentApiSlice;
