import React, { useState, useEffect, useCallback } from "react";
import { Loader2, ChevronDown, AlertCircle } from "lucide-react";

export default function GmoPurposeInput({
  value,
  onChange,
  accessToken,
  selectedType,
}) {
  const [state, setState] = useState({
    options: [],
    isLoading: false,
    error: null,
    isOpen: false,
  });

  const fetchData = useCallback(
    async (url) => {
      if (!accessToken) {
        throw new Error("No access token available");
      }

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      return response.json();
    },
    [accessToken]
  );

  const fetchGmoTechniques = useCallback(
    async (type) => {
      if (!accessToken || !type) {
        setState((prev) => ({ ...prev, options: [] }));
        return;
      }

      setState((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        let endpoint;
        switch (type.toLowerCase()) {
          case "animal_cells":
            endpoint = `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/administrations/gmo-techniques/animal-cells`;
            break;
          case "yeasts":
            endpoint = `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/administrations/gmo-techniques/yeasts`;
            break;
          case "bacteria":
            endpoint = `${process.env.REACT_APP_BACKEND_URL}/cultzyme-api/v1/administrations/gmo-techniques/bacterias`;
            break;
          default:
            throw new Error(`Invalid GMO technique type: ${type}`);
        }

        const data = await fetchData(endpoint);
        setState((prev) => ({ ...prev, options: data, isLoading: false }));
      } catch (err) {
        setState((prev) => ({
          ...prev,
          error: `Failed to fetch GMO techniques: ${err.message}`,
          isLoading: false,
          options: [],
        }));
      }
    },
    [accessToken, fetchData]
  );

  useEffect(() => {
    if (selectedType) {
      fetchGmoTechniques(selectedType);
    } else {
      setState((prev) => ({ ...prev, options: [] }));
    }
  }, [selectedType, fetchGmoTechniques]);

  const handleSelectChange = useCallback(
    (newValue) => {
      onChange(newValue);
      setState((prev) => ({ ...prev, isOpen: false }));
    },
    [onChange]
  );

  const toggleDropdown = () => {
    setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const selectedOption = state.options.find((option) => option.value === value);

  return (
    <div className="relative w-[200px] max-w-[300px]">
      {state.error && (
        <div className="mb-2 p-4 bg-destructive/10 border border-destructive text-destructive rounded-md">
          <div className="flex items-center">
            <AlertCircle className="h-4 w-4 mr-2" />
            <h3 className="font-bold">Error</h3>
          </div>
          <p className="text-sm mt-1">{state.error}</p>
        </div>
      )}

      <div className="relative">
        <button
          type="button"
          onClick={toggleDropdown}
          disabled={state.isLoading || !selectedType}
          className="w-full h-8 px-2 py-1 text-sm inline-flex items-center justify-between bg-background border border-input rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-ring focus:border-input disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <span className="truncate text-foreground">
            {selectedOption
              ? selectedOption.description
              : selectedType
              ? state.isLoading
                ? "Loading techniques..."
                : "Select GMO technique"
              : "Select GMO type first"}
          </span>
          {state.isLoading ? (
            <Loader2
              className="h-4 w-4 animate-spin ml-2 text-muted-foreground"
              aria-hidden="true"
            />
          ) : (
            <ChevronDown
              className="h-4 w-4 text-muted-foreground"
              aria-hidden="true"
            />
          )}
        </button>

        {state.isOpen && (
          <ul className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-popover rounded-md shadow-lg max-h-60 ring-1 ring-border focus:outline-none sm:text-sm">
            {state.options.map((option) => (
              <li
                key={option.value}
                className="relative py-2 pl-3 text-popover-foreground cursor-pointer select-none pr-9 hover:bg-accent hover:text-accent-foreground"
                onClick={() => handleSelectChange(option.value)}
              >
                <span className="block truncate">{option.description}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
