import { useCallback, useEffect, useRef, useState } from "react";

const useGeolocationAPI = (config) => {
  const {
    positionOptions = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: Infinity,
    },
    watchPosition = false,
  } = config;

  // State to hold geolocation data
  const [coords, setCoords] = useState(undefined);
  const [timestamp, setTimestamp] = useState(undefined);
  const [positionError, setPositionError] = useState(undefined);

  // Reference to store the watch ID for clearing it later
  const watchId = useRef(0);

  // Callback to handle geolocation errors
  const handlePositionError = useCallback((error) => {
    setCoords(undefined);
    setPositionError(error);
  }, []);

  // Callback to handle geolocation success
  const handlePositionSuccess = useCallback((position) => {
    setCoords(position.coords);
    setTimestamp(position.timestamp);
    setPositionError(undefined);
  }, []);

  // Function to get geolocation data based on config
  const getPosition = useCallback(() => {
    const funcPosition = (
      watchPosition
        ? navigator.geolocation.watchPosition
        : navigator.geolocation.getCurrentPosition
    ).bind(navigator.geolocation);

    watchId.current = funcPosition(
      handlePositionSuccess,
      handlePositionError,
      positionOptions
    );
  }, [
    watchPosition,
    handlePositionSuccess,
    handlePositionError,
    // positionOptions,
  ]);

  // Effect to start watching for geolocation data on mount
  useEffect(() => {
    getPosition();

    // Cleanup function to clear the watch on unmount
    return () => {
      if (watchPosition && watchId.current) {
        navigator.geolocation.clearWatch(watchId.current);
      }
    };
  }, [getPosition, watchPosition]);

  // Function to stop the geolocation watch
  const stopWatchingGeolocation = useCallback(() => {
    if (watchId.current) {
      navigator.geolocation.clearWatch(watchId.current);
      watchId.current = 0;
    }
  }, []);

  return {
    coords,
    timestamp,
    isGeolocationAvailable: Boolean(navigator.geolocation),
    positionError,
    stopWatchingGeolocation,
  };
};

export default useGeolocationAPI;
