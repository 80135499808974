import { useState, useRef, cloneElement } from "react";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./ActuatorButton.module.css";

const ActuatorButton = ({
  threshold = 500,
  label,
  renderLabel,
  enabled,
  onClick,
  children,
  onLongPress,
  showChildrenOnSingleClick,
}) => {
  const [isLongPress, setIsLongPress] = useState(false);
  const timeoutRef = useRef();

  const mouseDownHandler = () => {
    timeoutRef.current = setTimeout(() => {
      children && setIsLongPress(true);
      onLongPress && onLongPress();
    }, threshold);
  };

  const mouseUpHandler = () => {
    clearTimeout(timeoutRef.current);
    !isLongPress && onClick && onClick();
    showChildrenOnSingleClick && setIsLongPress(true);
  };

  return (
    <>
      <motion.button
        variants={{
          bigger: { scale: 1.12 },
          smaller: {
            scale: 1,
            transition: { duration: 0.1 },
          },
        }}
        whileHover="bigger"
        whileTap="smaller"
        animate="smaller"
        transition={{ type: "spring", duration: 0.4, bounce: 0.5 }}
        className={`${styles["actuator-btn"]} ${
          enabled
            ? styles[
                children ? "multi-action-enabled" : "single-action-enabled"
              ]
            : ""
        }`}
        onMouseDown={mouseDownHandler}
        onMouseUp={mouseUpHandler}
      >
        {label && label}
        {renderLabel && renderLabel()}
      </motion.button>

      <AnimatePresence>
        {children &&
          isLongPress &&
          cloneElement(children, {
            ...children.props, // Preserve existing props,
            onClose: () => setIsLongPress(false), // Add the onClose prop
          })}
      </AnimatePresence>
    </>
  );
};

export default ActuatorButton;
