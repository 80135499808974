import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import { apiSlice } from "../app/api/apiSlice";
import { useToast } from "../hooks/use-toast";
import ChatbotSidebar from "./Chatbot/ChatbotSidebar";
import ChatbotBody from "./Chatbot/ChatbotBody";
import ChatbotInput from "./Chatbot/ChatbotInput";
import { marked } from "marked";

export default function ChatbotContainer() {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarLoading, setIsSidebarLoading] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [temperature, setTemperature] = useState(0.5);
  const [usePublicData, setUsePublicData] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [dynamicSuggestions, setDynamicSuggestions] = useState([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);

  const dispatch = useDispatch();
  const accessToken = useSelector(selectCurrentToken);
  const [tokenExisted, setTokenExisted] = useState(!!accessToken);
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (!accessToken && tokenExisted) {
      dispatch(apiSlice.util.resetApiState());
    }
    setTokenExisted(!!accessToken);
  }, [accessToken, dispatch, tokenExisted]);

  useEffect(() => {
    if (accessToken) {
      getChats();
    }
  }, [accessToken]);

  useEffect(() => {
    if (chatId && chats.length > 0) {
      const chat = chats.find((c) => c.chatId === chatId);
      if (chat) {
        selectChat(chat);
      }
    }
  }, [chatId, chats]);

  const getChats = useCallback(async () => {
    try {
      setIsSidebarLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch chats");
      }
      const data = await response.json();
      setChats(
        data.sort((a, b) => new Date(b.lastActivity) - new Date(a.lastActivity))
      );
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    } finally {
      setIsSidebarLoading(false);
    }
  }, [accessToken, toast]);

  const deleteChat = async (chatId, event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      setIsSidebarLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats/${chatId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete chat");
      }

      setChats((prevChats) =>
        prevChats.filter((chat) => chat.chatId !== chatId)
      );
      if (selectedChat?.chatId === chatId) {
        setSelectedChat(null);
        setMessages([]);
      }
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    } finally {
      setIsSidebarLoading(false);
    }
  };

  const selectChat = async (chat) => {
    setSelectedChat(chat);
    navigate(`/chatbot/${chat.chatId}`);
    if (chat.userAiMessages && chat.userAiMessages.length > 0) {
      setMessages(chat.userAiMessages);
    } else {
      try {
        setIsChatLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats/${chat.chatId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to load chat messages");
        }
        const data = await response.json();
        setMessages(data.userAiMessages || []);
      } catch (err) {
        toast({
          title: "Error",
          description: err.message,
          variant: "destructive",
        });
      } finally {
        setIsChatLoading(false);
      }
    }
  };

  const exportChat = () => {
    const chatData = JSON.stringify(messages, null, 2);
    const blob = new Blob([chatData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `chat_${selectedChat?.chatId}.json`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const sendMessage = async (inputMessage) => {
    if (!inputMessage.trim() || !selectedChat) return;

    try {
      setIsLoading(true);
      setIsTyping(true);
      setDynamicSuggestions([]);

      const userMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "USER",
        content: inputMessage,
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/assistants`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: inputMessage,
            chatId: selectedChat.chatId,
            temperature: temperature,
            withPublicData: usePublicData,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send message");
      }

      const reader = response.body.getReader();
      let aiResponse = "";
      const decoder = new TextDecoder();

      let tempAiMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "AI",
        content: "",
      };

      setMessages((prevMessages) => [...prevMessages, tempAiMessage]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");
        for (const line of lines) {
          if (line.startsWith("data:")) {
            aiResponse += line.slice(5);

            tempAiMessage.content = marked(aiResponse);

            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.messageId === tempAiMessage.messageId ? tempAiMessage : msg
              )
            );
          }
        }
      }

      tempAiMessage.content = marked(
        aiResponse || "Sorry, I was unable to generate a response."
      );
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.messageId === tempAiMessage.messageId ? tempAiMessage : msg
        )
      );

      // Aquí se asegura de no cambiar el preview por el mensaje del usuario
      const updatedChat = {
        ...selectedChat,
        preview: selectedChat.preview || "Chat sin título", // Usa un valor fijo o manten el valor original
        lastActivity: new Date().toISOString(),
      };

      setChats((prevChats) => {
        const filteredChats = prevChats.filter(
          (chat) => chat.chatId !== selectedChat.chatId
        );
        return [updatedChat, ...filteredChats];
      });
      setSelectedChat(updatedChat);

      // Generar sugerencias basadas en la respuesta de AI
      await generateSuggestions(aiResponse);
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
      const errorMessage = {
        messageId: Date.now().toString(),
        generatedTime: new Date().toISOString(),
        senderType: "SYSTEM",
        content: "Error sending the message. Please try again.",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const generateSuggestions = async (aiResponse) => {
    try {
      setIsSuggestionsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/assistants/suggestions`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: aiResponse,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate suggestions");
      }

      const data = await response.json();
      setDynamicSuggestions(data.suggestions);
    } catch (error) {
      console.error("Error generating suggestions:", error);
      setDynamicSuggestions([]);
    } finally {
      setIsSuggestionsLoading(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen bg-background text-foreground overflow-hidden">
      <ChatbotSidebar
        chats={chats}
        selectedChat={selectedChat}
        isLoading={isSidebarLoading}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        selectChat={selectChat}
        deleteChat={deleteChat}
        exportChat={exportChat}
      />
      <div className="flex-1 flex flex-col h-full overflow-hidden">
        <ChatbotBody
          selectedChat={selectedChat}
          messages={messages}
          isTyping={isTyping}
          isChatLoading={isChatLoading}
          toggleSidebar={toggleSidebar}
          accessToken={accessToken}
        />
        <ChatbotInput
          sendMessage={sendMessage}
          isLoading={isLoading}
          temperature={temperature}
          setTemperature={setTemperature}
          usePublicData={usePublicData}
          setUsePublicData={setUsePublicData}
          dynamicSuggestions={dynamicSuggestions}
          isSuggestionsLoading={isSuggestionsLoading}
        />
      </div>
    </div>
  );
}
