import { ClockLoader } from "react-spinners";
import { useEffect } from "react";

import styles from "./Step4.module.css";
import { useGetUserAccountApprovedQuery } from "../../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const Step4 = ({ message }) => {
  const { data } = useGetUserAccountApprovedQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if ("approved" in data && data.approved) {
      toast.success(data.message);
      navigate("/signin");
    } else if ("approved" in data && !data.approved) {
      toast.error(data.message);
    }
  }, [data, navigate]);

  return (
    <div className={styles["step"]}>
      <h3 className={styles["step-info"]}>{message}</h3>

      <div className={styles["loading-animation__container"]}>
        <ClockLoader color="#fcfcfc" size={100} speedMultiplier={0.75} />
      </div>
    </div>
  );
};

export default Step4;
