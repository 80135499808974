import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importamos el hook useNavigate

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../UI/Shadcn/AlertDialog";

import ChatContainer from "../ChatContainer/ChatContainer";
import { CircularProgress } from "@mui/material";

const AskQuestion = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      try {
        // Llamada al endpoint para verificar acceso
        const response = await fetch("/maximize-productions/check-doe-data");
        const data = await response.json();

        // Revisamos si tiene acceso o no
        setHasAccess(data === true);
      } catch (error) {
        console.log("Error al verificar el acceso:", error);
        setError("Failed to verify access.");
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, []);

  // Muestra un mensaje de carga mientras se realiza la verificación
  if (loading) {
    return <CircularProgress />;
  }

  // Si no tiene acceso, mostramos el AlertDialog de shadcn
  if (!hasAccess) {
    return (
      <AlertDialog defaultOpen>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              You don't have any DOE Flow yet, would you like to create one?
            </AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove your data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => navigate("/cultzyme-ai")}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => navigate("/cultzyme-ai/maximize")}
            >
              Create New Flow
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  // Si tiene acceso, mostramos el contenedor del chat
  return <ChatContainer />;
};

export default AskQuestion;
