import { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { IoIosArrowDown as ExpandIcon } from "react-icons/io";
import { BsHourglassSplit as InProgressIcon } from "react-icons/bs";
import { FaCheckCircle as CompletedIcon } from "react-icons/fa";

import classNames from "classnames/bind";
import styles from "./Ultrasound.module.css";
import commonStyles from "../CommonStyles.module.css";
import usePromptEngine from "../../../../../../../hooks/use-prompt_engine";

const cx = classNames.bind({ ...styles, ...commonStyles });
const Ultrasound = ({
  name,
  options,
  prompts,
  plannerData,
  setPlannerData,
}) => {
  const plans = plannerData?.[name];
  const {
    // dataItems: plans,
    renderPrompt,
    checkAllItemsForCompletion: checkAllPlansForCompletion,
    checkItemForCompletion: checkPlanForCompletion,
    checkItemForCreation: checkPlanForCreation,
    checkItemForStart: checkPlanForStart,
    addDataItem: addPlan,
  } = usePromptEngine(prompts.basePrompt, options, plans, setPlannerData, name);

  return (
    <div>
      {plans &&
        plans.map((plan, index) => (
          <Accordion
            key={index}
            //   expanded={isExpanded}
            className={cx("accordion", "accordion--dark")}
            disableGutters
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ExpandIcon className={cx("expand-icon")} />}
              className={cx("accordionSummary")}
            >
              <div className={cx("accordionSummary__container")}>
                {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                <div className={cx("info__container")}>
                  <div className={cx("info__container-row")}>
                    <h3>Ultrasound Planning {index + 1}</h3>
                    {checkPlanForCompletion(index) ? (
                      <p className={cx("status-indicator", "completed")}>
                        <CompletedIcon /> Completed
                      </p>
                    ) : checkPlanForStart(index) ? (
                      <p className={cx("status-indicator", "in-progress")}>
                        <InProgressIcon /> In Progress
                      </p>
                    ) : null}
                  </div>
                  <p>Fine-Tuning Your Bioprocess with Precision</p>
                </div>

                {checkPlanForCompletion(index) ? (
                  <div className={cx("status__container")}>
                    <p>All set !</p>
                    <p>Modify if required</p>
                  </div>
                ) : checkPlanForStart(index) ? (
                  <div className={cx("status__container")}>
                    <p>In Progress</p>
                    <p>Continue filling details</p>
                  </div>
                ) : (
                  <div className={cx("status__container")}>
                    <p>Not started</p>
                    <p>Fill the details</p>
                  </div>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className={cx("accordionDetails")}>
              <div>
                <div className={cx("prompt")}>{renderPrompt(index)}</div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      {checkAllPlansForCompletion() &&
        checkPlanForCreation(plans.length - 1, plans) && (
          <Accordion
            className={cx("accordion", "accordion--dark", "accordion--add")}
            disableGutters
            onClick={addPlan}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              // expandIcon={<ExpandIcon className={cx("expand-icon")} />}
              className={cx("accordionSummary")}
            >
              <div className={cx("accordionSummary__container")}>
                {/* <ControlsIcon style={{ color: "", fontSize: "50px" }} /> */}

                <div className={cx("info__container")}>
                  <div>
                    <h3>Create New Ultrasound Planning</h3>
                  </div>
                </div>
              </div>
            </AccordionSummary>
          </Accordion>
        )}
    </div>
  );
};

export default Ultrasound;
