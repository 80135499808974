import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken } from "./features/auth/authSlice";
import { apiSlice } from "./app/api/apiSlice";

import RequireAuth from "./features/auth/RequireAuth";
import AskQuestion from "./components/AI/AskQuestion/AskQuestion";
import MaximizeProduction from "./components/AI/MaximizeProduction/MaximizeProduction";

import AppWrapper from "./components/Wrappers/AppWrapper/AppWrapper";
import BionsManagementAppWrapper from "./components/Wrappers/BionsManagementAppWrapper/BionsManagementAppWrapper";
import Login from "./pages/Login/Login";
import Bions from "./pages/BionsManagementApp/Bions/Bions";
import Bion from "./pages/BionsManagementApp/Bion/Bion";
import Signup from "./pages/Signup/Signup";
import Files from "./pages/BionsManagementApp/Processes/Files";
import Settings from "./pages/Settings/Settings";
import RegistrationRequest from "./pages/RegistrationRequest/RegistrationRequest";
import Process from "./pages/Process/Process";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import Home from "./pages/Home/Home";
import InSilico from "./pages/AI/InSilico";

import ChatbotContainer from "./components/ChatbotContainer";

import Trainings from "./pages/Trainings/Trainings";
import NewFiles from "./pages/Files/NewFiles";
import TrainingView from "./pages/Trainings/TrainingView";
import TrainingNotFound from "./components/training-not-found";

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const [tokenExisted, setTokenExisted] = useState(!!token);

  useEffect(() => {
    if (!token && tokenExisted) {
      dispatch(apiSlice.util.resetApiState());
    }
    setTokenExisted(!!token);
  }, [token, dispatch, tokenExisted]);

  return (
    <Routes>
      <Route path="/signin" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route element={<RequireAuth />}>
        <Route element={<AppWrapper />}>
          <Route path="/home" element={<Home />} />
          <Route path="/bions" element={<Bions />} />
          <Route path="/bions/:bionId" element={<Bion />} />
          <Route path="/files" element={<Files />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/processes/:processId" element={<Process />} />
          <Route
            path="auth/accounts/:action"
            element={<RegistrationRequest />}
          />

          <Route
            path="/bions-management-app"
            element={<BionsManagementAppWrapper />}
          >
            <Route index element={<Navigate to="bions" replace />} />
            <Route path="bions" element={<Bions />} />
            <Route path="bions/:bionId" element={<Bion />} />
          </Route>

          <Route path="/cultzyme-ai/ask" element={<AskQuestion />} />
          <Route
            path="/cultzyme-ai/maximize"
            element={<MaximizeProduction />}
          />

          {/* RUTAS PARA PRUEBAS MARTIN */}

          <Route path="/chatbot" element={<ChatbotContainer />} />
          <Route path="/chatbot/:chatId" element={<ChatbotContainer />} />
          <Route path="/insilico" element={<InSilico />} />

          <Route path="/trainings" element={<Trainings />} />
          <Route path="/trainings/:trainingDoeId" element={<TrainingView />} />
          <Route path="/trainings/not-found" element={<TrainingNotFound />} />
          <Route path="/new-files" element={<NewFiles />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default App;
