"use client";

import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/UI/Shadcn/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/UI/Shadcn/Table";
import { Button } from "../../../../components/UI/Shadcn/Button";
import { Loader2, X, FileText, FileSpreadsheet } from "lucide-react";
import { useToast } from "../../../../hooks/use-toast";
import { ScrollArea } from "../../../../components/UI/Shadcn/ScrollArea";

const Csv = ({
  trainingDoeId,
  experimentalDesignId,
  accessToken,
  onFinish,
  isViewOnly,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [excelFileName, setExcelFileName] = useState("");
  const { toast } = useToast();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isViewOnly) {
      fetchExperimentalDesignDetails();
    }
  }, [isViewOnly, trainingDoeId, experimentalDesignId, accessToken]);

  const fetchExperimentalDesignDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/${trainingDoeId}/${experimentalDesignId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      if (data.excelFileName) {
        setExcelFileName(data.excelFileName);
        setFiles([{ name: data.excelFileName, type: "text/csv" }]);
      }
    } catch (error) {
      console.error("Failed to fetch experimental design details:", error);
      toast({
        title: "Error",
        description:
          "Failed to fetch experimental design details. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const droppedFiles = Array.from(e.dataTransfer.files).filter(
      (file) => file.type === "text/csv" || file.type === "application/pdf"
    );
    handleFiles(droppedFiles);
  }, []);

  const handleFileInput = useCallback((e) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files).filter(
        (file) => file.type === "text/csv" || file.type === "application/pdf"
      );
      handleFiles(selectedFiles);
    }
  }, []);

  const handleFiles = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    newFiles.forEach((file) => {
      if (file.type === "text/csv") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const text = event.target.result;
          const rows = text.split("\n").map((row) => row.split(","));
          setCsvData((prevData) => [
            ...prevData,
            { fileName: file.name, data: rows },
          ]);
        };
        reader.readAsText(file);
      }
    });
  };

  const removeFile = useCallback((index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setCsvData((prevData) => prevData.filter((_, i) => i !== index));
  }, []);

  const handleFinish = useCallback(async () => {
    if (files.length === 0) {
      toast({
        title: "Error",
        description: "Please upload at least one file before finishing.",
        variant: "destructive",
      });
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    files.forEach((file) => formData.append("file", file));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/finish?trainingDoeId=${trainingDoeId}&experimentalDesignId=${experimentalDesignId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      toast({
        title: "Success",
        description: "Experimental design finished successfully.",
      });

      onFinish();
    } catch (error) {
      console.error("Error finishing experimental design:", error);
      let errorMessage =
        "Failed to finish experimental design. Please try again.";
      if (error instanceof TypeError) {
        errorMessage =
          "No response received from server. Please check your network connection.";
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
    }
  }, [
    files,
    trainingDoeId,
    experimentalDesignId,
    accessToken,
    onFinish,
    toast,
  ]);

  const handleSelectFiles = () => {
    fileInputRef.current.click();
  };

  if (isViewOnly) {
    return (
      <Card className="w-full max-w-3xl mx-auto">
        <CardHeader>
          <CardTitle>Uploaded Files</CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex items-center justify-center py-6">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Loading...
            </div>
          ) : files.length > 0 ? (
            <ul className="space-y-2">
              {files.map((file, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between bg-secondary p-2 rounded"
                >
                  <span className="flex items-center">
                    {file.type === "text/csv" ? (
                      <FileSpreadsheet className="mr-2 h-4 w-4" />
                    ) : (
                      <FileText className="mr-2 h-4 w-4" />
                    )}
                    {file.name}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No files have been uploaded for this experimental design.</p>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle>File Upload</CardTitle>
      </CardHeader>
      <CardContent>
        <div
          className={`border-2 border-dashed rounded-lg p-8 text-center mb-4 ${
            dragActive ? "border-primary bg-primary/10" : "border-gray-300"
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <p>Drag and drop CSV or PDF files here or click to select</p>
          <input
            type="file"
            accept=".csv,.pdf"
            onChange={handleFileInput}
            className="hidden"
            multiple
            id="file-input"
            ref={fileInputRef}
          />
          <Button
            variant="outline"
            type="button"
            onClick={handleSelectFiles}
            className="mt-2"
          >
            Select Files
          </Button>
        </div>

        {files.length > 0 && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Uploaded Files:</h3>
            <ul className="space-y-2">
              {files.map((file, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between bg-secondary p-2 rounded"
                >
                  <span className="flex items-center">
                    {file.type === "text/csv" ? (
                      <FileSpreadsheet className="mr-2 h-4 w-4" />
                    ) : (
                      <FileText className="mr-2 h-4 w-4" />
                    )}
                    {file.name}
                  </span>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => removeFile(index)}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {csvData.length > 0 && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">CSV Data Preview:</h3>
            <ScrollArea className="h-[300px]">
              {csvData.map((csv, index) => (
                <div key={index} className="mb-4">
                  <h4 className="font-medium">{csv.fileName}</h4>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        {csv.data[0].map((header, headerIndex) => (
                          <TableHead key={headerIndex}>{header}</TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {csv.data.slice(1, 6).map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {csv.data.length > 6 && (
                    <p className="text-sm text-muted-foreground mt-2">
                      Showing first 5 rows of {csv.data.length - 1} total rows.
                    </p>
                  )}
                </div>
              ))}
            </ScrollArea>
          </div>
        )}

        <Button
          className="mt-4 w-full bg-primary hover:bg-primary/90 text-primary-foreground"
          onClick={handleFinish}
          disabled={isUploading || files.length === 0}
        >
          {isUploading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Finishing...
            </>
          ) : (
            "Finish Experimental Design"
          )}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Csv;
