import { useEffect, useState } from "react";
import { AnimatePresence, animate, motion } from "framer-motion";
import InputSlider from "react-input-slider";
import ModalWrapper from "../../../../Wrappers/ModalWrapper/ModalWrapper";
import ActuatorButton from "../../ActuatorButton/ActuatorButton";
import LightImg from "../../../../../assets/images/Actuators/light.png";
import lightBrightImg from "../../../../../assets/images/light-bright.svg";
import Switch from "react-switch";
import styles from "./Light.module.css";

const LIGHT_BRIGHT_MIN = 0;
const LIGHT_BRIGHT_MAX = 100;

const LIGHT_OPTIONS = [
  { label: "Standard", mode: "STANDARD" },
  { label: "Custom", mode: "CUSSTOM" },
];

const LIGHTS = [
  { name: "BLUE", label: "(440nm)", color: "#0000ff" },
  { name: "RED", label: "(680nm)", color: "#f00" },
  { name: "YELLOW", label: "(590nm)", color: "#ff0" },
  { name: "PURPLE", label: "(400nm)", color: "#4b0082" },
  { name: "GREEN", label: "(520nm)", color: "#0f0" },
];

const LightModal = ({ process, sendActuatorData, onClose }) => {
  // State variables
  const [lightMode, setLightMode] = useState(process.lightMode);
  const [lightBright, setLightBright] = useState(process.lightBright ?? 0);

  const [lightState, setLightState] = useState(
    process.lightMode && process.lightMode !== "OFF"
  );

  // Animation related state variables
  const [modalAnimationComplete, setModalAnimationComplete] = useState(false);
  const [sliderAnimationComplete, setSliderAnimationComplete] = useState(false);

  const sendActuatorDataHandler = () => {
    sendActuatorData({
      actuatorName: "light",
      lightMode: lightState ? lightMode : "OFF",
      lightBright: lightState ? lightBright : 0,
    });

    onClose();
  };

  const modeSelectionHandler = (mode) => {
    if (mode === "STANDARD") {
      setLightMode("STANDARD");
      setLightBright(100);
    } else {
      setLightMode("CUSTOM");
    }
  };

  const modalAnimationCompleteHandler = () => {
    setModalAnimationComplete(true);
  };

  // Determine if the expandable area should be shown

  const showFirstExpandableArea =
    lightMode &&
    lightMode !== "OFF" &&
    lightMode !== "STANDARD" &&
    modalAnimationComplete;

  const showExpandableArea =
    showFirstExpandableArea &&
    lightMode &&
    lightMode !== "OFF" &&
    lightMode !== "CUSTOM" &&
    modalAnimationComplete;
  // Determine if the data is valid for submission
  // const validForSubmit =
  //   (lightBright !== process.lightBright || lightMode !== process.lightMode) &&
  //   sliderAnimationComplete;
  // console.log(showFirstExpandableArea);

  const validForSubmit = true;

  // Use useEffect for animations when the expandable area is shown
  useEffect(() => {
    if (showExpandableArea) {
      animate(0, process.lightBright ?? 0, {
        onUpdate: (latestValue) => setLightBright(Math.trunc(latestValue)),
        onComplete: () => setSliderAnimationComplete(true),
        duration: 0.6,
        ease: "easeIn",
      });
    }
  }, [showExpandableArea, process.lightBright]);

  return (
    <ModalWrapper
      onClose={onClose}
      onAnimationComplete={modalAnimationCompleteHandler}
    >
      <div className={styles["light-actuator__modal"]}>
        {/* Header */}
        <header>
          <h4>Light Actuator Configuration</h4>
          <div className={styles["actuator-decoration"]}>
            <img src={LightImg} alt="Light" />
          </div>
        </header>

        {/* Main Modal Content */}
        <div>
          <p className={styles["info-text-1"]}>
            Adjust the lighting settings for your bioreactor.
          </p>

          <div className={styles["switch__container"]}>
            <Switch
              checked={lightState}
              onChange={(checked) => {
                setLightState(checked);
              }}
            />
            <p>Toggle the switch to turn the Light Actuator</p>
          </div>
          <AnimatePresence>
            {lightState && (
              <motion.div
                initial={{ opacity: 0, height: 0, y: -25 }}
                animate={{ opacity: 1, height: "auto", y: 0 }}
                exit={{
                  opacity: 0,
                  height: 0,
                  y: -25,
                  transition: { height: { delay: 0.2 } },
                }}
                transition={{ opacity: { delay: 0.2 }, y: { delay: 0.2 } }}
              >
                <ul className={styles["options_first"]}>
                  {LIGHT_OPTIONS.map((option) => (
                    <li key={option.label}>
                      <button
                        className={
                          option.mode === lightMode ? styles["selected"] : ""
                        }
                        onClick={modeSelectionHandler.bind(null, option.mode)}
                      >
                        {option.label}
                      </button>
                    </li>
                  ))}
                </ul>
                <AnimatePresence>
                  {showFirstExpandableArea && (
                    <motion.div
                      initial={{ opacity: 0, height: 0, y: -25 }}
                      animate={{ opacity: 1, height: "auto", y: 0 }}
                      exit={{
                        opacity: 0,
                        height: 0,
                        y: -25,
                        transition: { height: { delay: 0.2 } },
                      }}
                      transition={{
                        opacity: { delay: 0.2 },
                        y: { delay: 0.2 },
                      }}
                    >
                      <ul className={styles["options"]}>
                        {LIGHTS.map((light) => (
                          <li key={light.name}>
                            <button
                              className={`${
                                lightMode === light.name
                                  ? styles["selected"]
                                  : ""
                              }`}
                              onClick={() => setLightMode(light.name)}
                            >
                              <span style={{ background: light.color }}></span>
                              <span>
                                {light.name} <br />
                                {light.label}
                              </span>
                            </button>
                          </li>
                        ))}
                      </ul>

                      <div className={styles["expandable-area"]}>
                        <p className={styles["info-text-2"]}>
                          Use the slider to set the brightness of the selected
                          light color.
                        </p>

                        <div className={styles["input-slider__container"]}>
                          {/* <img src={lightBrightImg} alt="" /> */}
                          <span>{lightBright}lm</span>
                          <InputSlider
                            xmin={LIGHT_BRIGHT_MIN}
                            xmax={LIGHT_BRIGHT_MAX}
                            x={lightBright}
                            onChange={({ x }) => setLightBright(x)}
                            styles={{
                              track: {
                                backgroundColor: "#151414",
                                width: "100%",
                                height: "36px",
                                border: "1px solid #656565",
                                cursor: "pointer",
                                overflow: "hidden",
                              },
                              active: {
                                backgroundColor: "rgba(91, 91, 91, 0.20)",
                                borderTopRightRadius: "0",
                                borderBottomRightRadius: "0",
                              },
                              thumb: {
                                width: "1px",
                                height: "34px",
                                backgroundColor: "#656565",
                              },
                            }}
                          />
                        </div>
                        <p className={styles["info-text-3"]}>
                          Click the "SET" button to save your light
                          configuration.
                        </p>
                      </div>

                      {/* Expandable area(2nd step) */}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>

          {/* <AnimatePresence>
            {showFirstExpandableArea && (
              
            )}
          </AnimatePresence> */}

          <button
            className={`${styles["submit-btn"]} ${
              validForSubmit ? styles["active"] : ""
            }`}
            disabled={!validForSubmit}
            onClick={sendActuatorDataHandler}
          >
            SET
          </button>
        </div>

        {/* <div className={styles["input-slider__container"]}>
          <img src={lightBrightImg} alt="" />
          <span>{lightBright}</span>
          <InputSlider
            xmin={LIGHT_BRIGHT_MIN}
            xmax={LIGHT_BRIGHT_MAX}
            x={lightBright}
            onChange={({ x }) => setLightBright(x)}
            styles={{
              track: {
                backgroundColor: "#151414",
                width: "100%",
                height: "36px",
                border: "1px solid #656565",
                cursor: "pointer",
                overflow: "hidden",
              },
              active: {
                backgroundColor: "rgba(91, 91, 91, 0.20)",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
              },
              thumb: {
                width: "1px",
                height: "34px",
                backgroundColor: "#656565",
              },
            }}
          />
        </div> */}

        {/* <button onClick={sendActuatorDataHandler}>SET</button> */}
      </div>
    </ModalWrapper>
  );
};

const Light = ({ process, sendActuatorData }) => {
  const lightActuatorIsEnabled =
    process.lightMode && process.lightMode !== "OFF";

  return (
    <ActuatorButton
      enabled={lightActuatorIsEnabled}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Light</h5> */}
            <div className={styles["row"]}>
              <img src={LightImg} alt="Light" />
              <div>
                <p className={styles["label__info"]}>
                  Mode:{" "}
                  <span>{process?.lightMode ? process.lightMode : "N/S"}</span>
                </p>
                <p className={styles["label__info"]}>
                  Value:{" "}
                  <span>
                    {process?.lightBright ? process.lightBright + "lm" : "N/S"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      }}
      showChildrenOnSingleClick
    >
      <LightModal process={process} sendActuatorData={sendActuatorData} />
    </ActuatorButton>
  );
};

export default Light;
