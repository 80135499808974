import ActuatorButton from "../../ActuatorButton/ActuatorButton";

import UltrasoundImg from "../../../../../assets/images/Actuators/ultrasound.png";

import styles from "./Ultrasound.module.css";

const Ultrasound = ({ process, sendActuatorData }) => {
  return (
    <ActuatorButton
      enabled={process.ultraSoundState}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Ultrasound</h5> */}
            <img src={UltrasoundImg} alt="Ultrasound" />
          </div>
        );
      }}
      onClick={sendActuatorData.bind(null, {
        actuatorName: "ultra-sound",
        ultraSoundState: !process.ultraSoundState,
      })}
    />
  );
};

export default Ultrasound;
