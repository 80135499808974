"use client";

import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/UI/Shadcn/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/UI/Shadcn/Table";
import { Button } from "../../../components/UI/Shadcn/Button";
import { Loader2, Download, AlertCircle, Eye } from "lucide-react";
import { useToast } from "../../../hooks/use-toast";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/UI/Shadcn/Alert";
import { ScrollArea } from "../../../components/UI/Shadcn/ScrollArea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../../../components/UI/Shadcn/Dialog";

export default function Optimizations({
  trainingDoeId,
  isViewOnly,
  accessToken,
}) {
  const [optimizations, setOptimizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [previewContent, setPreviewContent] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    fetchOptimizations();
  }, [trainingDoeId, accessToken]);

  const fetchOptimizations = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/optimizations/${trainingDoeId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setOptimizations(data);
    } catch (err) {
      console.error("Failed to fetch optimizations:", err);
      setError("Failed to load optimizations. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async (optimizationId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/optimizations/download-pdf/${trainingDoeId}/${optimizationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `optimization_${optimizationId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      toast({
        title: "Success",
        description: "PDF downloaded successfully.",
      });
    } catch (err) {
      console.error("Failed to download optimization PDF:", err);
      toast({
        title: "Error",
        description: "Failed to download optimization PDF. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handlePreview = (content) => {
    setPreviewContent(content);
    setIsPreviewOpen(true);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Optimizations</CardTitle>
      </CardHeader>
      <CardContent>
        {optimizations.length > 0 ? (
          <ScrollArea className="h-[400px]">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Created Date</TableHead>
                  <TableHead>Content</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {optimizations.map((optimization) => (
                  <TableRow key={optimization.optimizationId}>
                    <TableCell>
                      {new Date(optimization.createdDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="max-w-md">
                      <div className="truncate">{optimization.content}</div>
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handlePreview(optimization.content)}
                        className="mr-2"
                      >
                        <Eye className="h-4 w-4 mr-2" />
                        Preview
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() =>
                          handleDownload(optimization.optimizationId)
                        }
                      >
                        <Download className="h-4 w-4 mr-2" />
                        Download PDF
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        ) : (
          <p className="text-center text-muted-foreground">
            No optimizations available.
          </p>
        )}
      </CardContent>

      <Dialog open={isPreviewOpen} onOpenChange={setIsPreviewOpen}>
        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Optimization Preview</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <pre className="whitespace-pre-wrap font-sans text-sm">
              {previewContent}
            </pre>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
