import { apiSlice } from "../../app/api/apiSlice";

const transformPlaceholders = (placeholders) => {
  const transformArray = (arr) => {
    return arr.map((item) => ({
      valueBackend: item.value,
      value: item.description,
      label: item.description,
    }));
  };

  const transformedPlaceholders = {};
  for (const key in placeholders) {
    if (Array.isArray(placeholders[key])) {
      transformedPlaceholders[key] = transformArray(placeholders[key]);
    }
  }
  return transformedPlaceholders;
};

export const administrationApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPlannerBasePrompt: build.query({
      query: ({ promptType }) => `/administrations/${promptType}/base-prompt`,
    }),

    getPlannerBasePromptOptions: build.query({
      query: () => "/administrations/bioprocess-definitions/placeholders",
      transformResponse: (response) => transformPlaceholders(response),
    }),

    getPlannerPrompts: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const results = await Promise.all(
            _arg.map(async (control) => {
              const fetchPromptNames = async (names) => {
                const responses = await Promise.all(
                  names.map(async (name) => {
                    const response = await fetchWithBQ({
                      url: `administrations/${name}/base-prompt`,
                    });
                    if (response.error) throw response.error;
                    return { [name]: response.data };
                  })
                );
                return names.length > 1
                  ? Object.assign({}, ...responses)
                  : responses[0][names[0]];
              };

              const fetchPlaceholders = async (placeholders) => {
                const responses = await Promise.all(
                  placeholders.map(async (placeholder) => {
                    const response = await fetchWithBQ({
                      url: `administrations/${placeholder}/placeholders`,
                    });
                    if (response.error) throw response.error;
                    return {
                      [placeholder]: transformPlaceholders(response.data),
                    };
                  })
                );
                return placeholders.length > 1
                  ? Object.assign({}, ...responses)
                  : responses[0][placeholders[0]];
              };

              const [promptNames, placeholders] = await Promise.all([
                fetchPromptNames(control.reqPromptNames),
                fetchPlaceholders(control.reqPlaceholdersName),
              ]);

              return {
                ...control,
                fetchedPrompts: promptNames,
                fetchedPlaceholders: placeholders,
              };
            })
          );

          return { data: results };
        } catch (error) {
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
    }),

    getPromptPlaceholders: build.query({
      query: ({ urlPath }) => `/administrations/${urlPath}/placeholders`,
    }),
  }),
});

export const {
  useGetPlannerBasePromptOptionsQuery,
  useGetPlannerBasePromptQuery,
  useGetPlannerPromptsQuery,
  useGetPromptPlaceholdersQuery,
} = administrationApiSlice;
