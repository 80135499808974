"use client";

import * as React from "react";
import { useEffect } from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

import { cn } from "../../../lib/utils";

const ScrollArea = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    // useEffect para eliminar display: table dinámicamente si aparece
    useEffect(() => {
      const viewport = document.querySelector(
        "[data-radix-scroll-area-viewport]"
      );

      // Si encuentra el viewport, busca el div problemático y cambia su display
      if (viewport) {
        const problematicDiv = viewport.querySelector(
          "div[style*='display: table']"
        );
        if (problematicDiv) {
          problematicDiv.style.display = "block"; // Cambia display: table por block
        }
      }
    }, []); // Se ejecuta una vez al montar el componente

    return (
      <ScrollAreaPrimitive.Root
        ref={ref}
        className={cn("relative overflow-hidden", className)}
        {...props}
      >
        <ScrollAreaPrimitive.Viewport
          className="h-full w-full rounded-[inherit]"
          style={{ display: "block !important" }} // Forzamos display: block
        >
          {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
      </ScrollAreaPrimitive.Root>
    );
  }
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef(
  ({ className, orientation = "vertical", ...props }, ref) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        "flex touch-none select-none transition-colors",
        orientation === "vertical" &&
          "h-full w-2.5 border-l border-l-transparent p-[1px]",
        orientation === "horizontal" &&
          "h-2.5 flex-col border-t border-t-transparent p-[1px]",
        className
      )}
      {...props}
    >
      <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-border" />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  )
);
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
