import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  ArrowLeft,
  User,
  Mail,
  Lock,
  Phone,
  MapPin,
  EyeOff,
  Eye,
  Search,
} from "lucide-react";

import {
  useSignupUserMutation,
  useSignupUserWithoutBionMutation,
  useConfirmUserAccountMutation,
  useGetBionNameQuery,
} from "../../features/auth/authApiSlice";

import { Button } from "../../components/UI/Shadcn/Button";
import { Input } from "../../components/UI/Shadcn/Input";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/UI/Shadcn/Card";
import { Checkbox } from "../../components/UI/Shadcn/Checkbox";
import { Label } from "../../components/UI/Shadcn/Label";
import { Progress } from "../../components/UI/Shadcn/Progress";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/UI/Shadcn/Dialog";

import Step2 from "../../components/Signup/Step2/Step2";
import Step3 from "../../components/Signup/Step3/Step3";
import Step4 from "../../components/Signup/Step4/Step4";

import logoImg from "../../assets/images/logo.png";
import countryCodes from "../../assets/data/countryCodes.json";

const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    userName: "",
    alias: "",
    countryCode: "",
    phone: "",
    email: "",
    password: "",
    bionName: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [apiError, setApiError] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [searchCountry, setSearchCountry] = useState("");
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const bionId = new URLSearchParams(location.search).get("destination");

  // Usamos el hook para obtener el nombre del BION
  const { data: bionData } = useGetBionNameQuery(bionId);

  useEffect(() => {
    if (bionData?.bionName) {
      setFormData((prev) => ({ ...prev, bionName: bionData.bionName }));
    }
  }, [bionData]);

  const [
    signupUserReq,
    {
      data: newUser,
      isLoading: signupUserReqIsLoading,
      isSuccess: signupUserReqIsSuccess,
    },
  ] = useSignupUserMutation();
  const [
    signupUserWithoutBionReq,
    {
      data: newUserWithoutBion,
      isLoading: signupUserWithoutBionIsLoading,
      isSuccess: signupUserWithoutBionIsSuccess,
    },
  ] = useSignupUserWithoutBionMutation();
  const [
    confirmUserAccountReq,
    {
      isLoading: confirmUserAccountReqIsLoading,
      isSuccess: confirmUserAccountReqIsSuccess,
    },
  ] = useConfirmUserAccountMutation();

  const validateForm = () => {
    const newErrors = {};
    if (!formData.userName.trim()) newErrors.userName = "Username is required";
    if (!formData.alias.trim()) newErrors.alias = "Alias is required";
    if (!formData.countryCode)
      newErrors.countryCode = "Country code is required";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    else if (!/^\d+$/.test(formData.phone))
      newErrors.phone = "Phone number must contain only digits";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (formData.password.length < 8)
      newErrors.password = "Password must be at least 8 characters long";
    if (!agreeToTerms)
      newErrors.terms = "You must agree to the terms and conditions";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouched(
      Object.keys(formData).reduce((acc, key) => ({ ...acc, [key]: true }), {})
    );
    setTouched((prev) => ({ ...prev, terms: true }));
    if (validateForm()) {
      try {
        setApiError(null);
        if (bionId) {
          await signupUserReq({
            destination: bionId,
            ...formData,
            phoneNumber: formData.countryCode + formData.phone,
          }).unwrap();
        } else {
          await signupUserWithoutBionReq({
            ...formData,
            phoneNumber: formData.countryCode + formData.phone,
          }).unwrap();
        }
      } catch (error) {
        console.error("Error during signup:", error);
        if (error.data && error.data.message) {
          setApiError(error.data.message);
        } else {
          setApiError("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  const step2SubmitHandler = useCallback(
    (step2Data) => {
      signupUserReq({
        destination: bionId,
        ...formData,
        phoneNumber: formData.countryCode + formData.phone,
        latitude: step2Data.latitude,
        longitude: step2Data.longitude,
      });
    },
    [signupUserReq, formData, bionId]
  );

  const step3SubmitHandler = useCallback(
    (verificationCode) => {
      confirmUserAccountReq({
        confirmationCode: verificationCode,
        userName: newUser?.userName || newUserWithoutBion?.userName,
        destination: bionId,
      });
    },
    [
      confirmUserAccountReq,
      newUser?.userName,
      newUserWithoutBion?.userName,
      bionId,
    ]
  );

  useEffect(() => {
    if (signupUserReqIsSuccess) {
      setCurrentStep(newUser?.isAdmin ? 3 : 4);
    }

    if (signupUserWithoutBionIsSuccess) {
      setCurrentStep(3);
    }
  }, [signupUserReqIsSuccess, signupUserWithoutBionIsSuccess, newUser]);

  useEffect(() => {
    if (confirmUserAccountReqIsSuccess) {
      navigate("/login", { replace: true });
    }
  }, [confirmUserAccountReqIsSuccess, navigate]);

  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [formData, agreeToTerms]);

  const filteredCountries = countryCodes.filter(
    (country) =>
      country.name.toLowerCase().includes(searchCountry.toLowerCase()) ||
      country.dial_code.includes(searchCountry)
  );

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <form onSubmit={handleSubmit} className="space-y-4">
            {apiError && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{apiError}</span>
              </div>
            )}
            {bionId && (
              <div className="space-y-2">
                <Label htmlFor="bionName">BION</Label>
                <Input
                  id="bionName"
                  name="bionName"
                  placeholder="BION Name"
                  value={formData.bionName}
                  onChange={handleInputChange}
                  className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                  icon={
                    <MapPin className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  }
                />
              </div>
            )}
            <div className="space-y-2">
              <Label htmlFor="userName">Username</Label>
              <Input
                id="userName"
                name="userName"
                placeholder="Your Username"
                value={formData.userName}
                onChange={handleInputChange}
                className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                icon={
                  <User className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                }
              />
              {touched.userName && errors.userName && (
                <p className="text-red-500 text-sm">{errors.userName}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="alias">Alias</Label>
              <Input
                id="alias"
                name="alias"
                placeholder="Nickname (visible to others)"
                value={formData.alias}
                onChange={handleInputChange}
                className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                icon={
                  <User className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                }
              />
              {touched.alias && errors.alias && (
                <p className="text-red-500 text-sm">{errors.alias}</p>
              )}
            </div>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
              <div className="w-full sm:w-1/3">
                <Label htmlFor="countryCode">Country code</Label>
                <Dialog
                  open={isCountryModalOpen}
                  onOpenChange={setIsCountryModalOpen}
                >
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      className="w-full justify-start text-left font-normal"
                    >
                      <span>{formData.countryCode || "Select"}</span>
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>Select Country Code</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="relative">
                        <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transform text-gray-500" />
                        <Input
                          placeholder="Search countries..."
                          value={searchCountry}
                          onChange={(e) => setSearchCountry(e.target.value)}
                          className="pl-8"
                        />
                      </div>
                      <div className="max-h-[300px] overflow-y-auto">
                        {filteredCountries.map((country) => (
                          <button
                            key={country.code}
                            className="flex w-full items-center space-x-2 rounded-md p-2 hover:bg-gray-100"
                            onClick={() => {
                              setFormData((prev) => ({
                                ...prev,
                                countryCode: country.dial_code,
                              }));
                              setTouched((prev) => ({
                                ...prev,
                                countryCode: true,
                              }));
                              setIsCountryModalOpen(false);
                            }}
                          >
                            <img
                              src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                              alt={`${country.name} flag`}
                              className="h-4 w-6 object-cover"
                            />
                            <span>{country.name}</span>
                            <span className="ml-auto">{country.dial_code}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                {touched.countryCode && errors.countryCode && (
                  <p className="text-red-500 text-sm">{errors.countryCode}</p>
                )}
              </div>
              <div className="w-full sm:w-2/3">
                <Label htmlFor="phone">Phone number</Label>
                <Input
                  id="phone"
                  name="phone"
                  placeholder="Phone number"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                  icon={
                    <Phone className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  }
                />
                {touched.phone && errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                icon={
                  <Mail className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                }
              />
              {touched.email && errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <div className="relative">
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="pl-10 pr-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                  icon={
                    <Lock className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  }
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  {showPassword ? (
                    <EyeOff className="h-4 w-4" />
                  ) : (
                    <Eye className="h-4 w-4" />
                  )}
                </button>
              </div>
              {touched.password && errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="terms"
                checked={agreeToTerms}
                onCheckedChange={(checked) => setAgreeToTerms(checked)}
              />
              <label
                htmlFor="terms"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I agree to the{" "}
                <Link
                  to="/privacy-policy"
                  className="text-blue-600 hover:underline"
                >
                  terms and conditions
                </Link>
              </label>
            </div>
            {touched.terms && errors.terms && (
              <p className="text-red-500 text-sm">{errors.terms}</p>
            )}
            <Button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white transition duration-200 ease-in-out"
              disabled={
                signupUserReqIsLoading || signupUserWithoutBionIsLoading
              }
            >
              {signupUserReqIsLoading || signupUserWithoutBionIsLoading
                ? "Creating Account..."
                : "Create Account"}
            </Button>
          </form>
        );
      case 2:
        return <Step2 bionId={bionId} onSubmit={step2SubmitHandler} />;
      case 3:
        return (
          <Step3
            userName={newUser?.userName || newUserWithoutBion?.userName}
            message={newUser?.message || ""}
            onSubmit={step3SubmitHandler}
          />
        );
      case 4:
        return <Step4 message={newUser?.message} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-background">
      <div className="w-full md:w-1/2 relative overflow-hidden bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 md:min-h-screen h-64">
        <div className="absolute inset-0 flex items-center justify-center">
          <img className="w-48" src={logoImg} alt="Cultzyme" />
        </div>
      </div>
      <div className="w-full md:w-1/2 flex items-center justify-center p-4 sm:p-8">
        <Card className="w-full max-w-md shadow-lg bg-card">
          <CardHeader>
            <div className="flex items-center mb-4">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => navigate(-1)}
                className="hover:bg-gray-100 transition duration-200 ease-in-out"
              >
                <ArrowLeft className="h-4 w-4" />
              </Button>
              <CardTitle className="text-2xl font-bold ml-2">Sign up</CardTitle>
            </div>
            <p className="text-muted-foreground">
              {currentStep === 1 && "Let's get started"}
              {currentStep === 2 && "Confirm Your Location"}
              {currentStep === 3 && "Verify Your Account"}
              {currentStep === 4 && "Account Approval"}
            </p>
          </CardHeader>
          <CardContent>{renderStep()}</CardContent>
          <CardFooter className="flex flex-col items-center space-y-4">
            <Progress value={(currentStep / 4) * 100} className="w-full" />
            {currentStep === 1 && (
              <p className="text-sm text-muted-foreground">
                Already have an account?{" "}
                <Link to="/login" className="text-blue-600 hover:underline">
                  Log in
                </Link>
              </p>
            )}
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default Signup;
