import React, { useState, useEffect } from "react";
import { Button } from "./../UI/Shadcn/Button";
import { ScrollArea } from "./../UI/Shadcn/ScrollArea";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./../UI/Shadcn/Dialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./../UI/Shadcn/Card";
import { Info, Loader2 } from "lucide-react";
import { Badge } from "./../UI/Shadcn/Badge";

const translations = {
  viewContext: "View Chatbot Context",
  close: "Close",
  chatbotContext: "AI Context",
  personalizedInfo: "Personalized information for this chat",
  projectDetails: "Project Details",
  specificParameters: "Specific parameters for this chat",
  loading: "Loading context...",
  error: "Error loading context",
};

const formatLabel = (key) => {
  return key
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default function ChatContext({ accessToken, chatId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [chatData, setChatData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen && chatId) {
      fetchChatContext();
    }
  }, [isOpen, chatId]);

  const fetchChatContext = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_API_URL}/cultzyme-ai-api/v1/chats/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch chat context");
      }
      const data = await response.json();
      setChatData(data.userAiChatParameterDescDtoDescriptions);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center p-4">
          <Loader2 className="h-6 w-6 animate-spin mr-2" />
          <p>{translations.loading}</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-red-500 p-4">
          <p>
            {translations.error}: {error}
          </p>
        </div>
      );
    }

    if (!chatData) {
      return null;
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold">
            {translations.projectDetails}
          </CardTitle>
          <CardDescription>{translations.specificParameters}</CardDescription>
        </CardHeader>
        <CardContent>
          <dl className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
            {Object.entries(chatData).map(([key, value]) => (
              <div key={key} className="space-y-1">
                <dt className="font-medium text-gray-500">
                  {formatLabel(key.replace("Description", ""))}:
                </dt>
                <dd className="text-gray-900">
                  {typeof value === "boolean" ? (
                    <Badge variant="secondary">{value ? "Yes" : "No"}</Badge>
                  ) : typeof value === "number" ? (
                    <Badge variant="secondary">{value}</Badge>
                  ) : (
                    <Badge variant="secondary">{value}</Badge>
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </CardContent>
      </Card>
    );
  };

  if (!chatId) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="text-primary">
          <Info className="h-4 w-4 mr-2" aria-hidden="true" />
          {translations.viewContext}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px] max-h-[80vh] overflow-hidden">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            {translations.chatbotContext}
          </DialogTitle>
          <DialogDescription className="text-base">
            {translations.personalizedInfo}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="mt-6 flex-grow">{renderContent()}</ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
