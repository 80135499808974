import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowLeft, Mail, Lock, KeyRound } from "lucide-react";

import {
  useLazyForgetPasswordQuery,
  useConfirmPasswordRetrievalMutation,
} from "../../features/auth/authApiSlice";
import ForgetPasswordSchema from "../../validations/forgetPassword";
import useTimer from "../../hooks/use-timer";

import { Button } from "../../components/UI/Shadcn/Button";
import { Input } from "../../components/UI/Shadcn/Input";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/UI/Shadcn/Card";

import logoImg from "../../assets/images/logo.png";

const ForgetPassword = () => {
  const [step, setStep] = useState(1);
  const [userName, setUsername] = useState("");
  const navigate = useNavigate();
  const { secondsLeft, restartTimer } = useTimer(60, {
    startAutomatically: true,
    direction: "desc",
  });

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1, isValid: isValid1 },
    reset,
  } = useForm({
    resolver: yupResolver(ForgetPasswordSchema.ForgetPasswordSchema1),
  });

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    formState: { errors: errors2, isValid: isValid2 },
  } = useForm({
    resolver: yupResolver(ForgetPasswordSchema.ForgetPasswordSchema2),
  });

  const [forgetPasswordReq, { isLoading: forgetPasswordReqIsLoading }] =
    useLazyForgetPasswordQuery();
  const [
    confirmPasswordRetrievalReq,
    { isLoading: confirmPasswordRetrievalReqIsLoading },
  ] = useConfirmPasswordRetrievalMutation();

  const forgetPasswordSubmitHandler = async (data) => {
    try {
      const responseData = await forgetPasswordReq(
        data.usernameOrEmail
      ).unwrap();
      if (responseData.status >= 400 && responseData.status <= 599) return;
      setUsername(data.usernameOrEmail);
      setStep(2);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const confirmPasswordRetrievalHandler = async (data) => {
    try {
      await confirmPasswordRetrievalReq({ ...data, usernameOrEmail: userName });
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const resendBtnClickHandler = () => {
    forgetPasswordReq(userName);
    restartTimer();
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-background">
      <div className="w-full md:w-1/2 relative overflow-hidden bg-gradient-to-r from-blue-900 via-blue-700 to-blue-500 md:min-h-screen h-64">
        <div className="absolute inset-0 flex items-center justify-center">
          <img className="w-48" src={logoImg} alt="Cultzyme" />
        </div>
      </div>
      <div className="w-full md:w-1/2 flex items-center justify-center p-4 sm:p-8">
        <Card className="w-full max-w-md shadow-lg bg-card">
          <CardHeader>
            <div className="flex items-center mb-4">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => navigate(-1)}
                className="hover:bg-gray-100 transition duration-200 ease-in-out"
              >
                <ArrowLeft className="h-4 w-4" />
              </Button>
              <CardTitle className="text-2xl font-bold ml-2">
                Password Recovery
              </CardTitle>
            </div>
            <p className="text-muted-foreground">
              {step === 1
                ? "Enter the email address associated with your account to reset your password."
                : "Enter the verification code sent to your email and your new password."}
            </p>
          </CardHeader>
          <CardContent>
            {step === 1 ? (
              <form
                id="forget-form-1"
                onSubmit={handleSubmit1(forgetPasswordSubmitHandler)}
              >
                <div className="space-y-4">
                  <div>
                    <Input
                      id="usernameOrEmail"
                      placeholder="Email or Username"
                      {...register1("usernameOrEmail")}
                      error={errors1.usernameOrEmail?.message}
                      className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                      icon={
                        <Mail className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                      }
                    />
                    {errors1.usernameOrEmail && (
                      <p className="text-sm text-destructive mt-1">
                        {errors1.usernameOrEmail.message}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            ) : (
              <form
                id="forget-form-2"
                onSubmit={handleSubmit2(confirmPasswordRetrievalHandler)}
              >
                <div className="space-y-4">
                  <div>
                    <Input
                      id="confirmationCode"
                      placeholder="Verification Code"
                      {...register2("confirmationCode")}
                      error={errors2.confirmationCode?.message}
                      className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                      icon={
                        <KeyRound className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                      }
                    />
                    {errors2.confirmationCode && (
                      <p className="text-sm text-destructive mt-1">
                        {errors2.confirmationCode.message}
                      </p>
                    )}
                  </div>
                  <div>
                    <Input
                      id="newPassword"
                      type="password"
                      placeholder="New Password"
                      {...register2("newPassword")}
                      error={errors2.newPassword?.message}
                      className="pl-10 transition duration-200 ease-in-out focus:ring-2 focus:ring-blue-500"
                      icon={
                        <Lock className="h-4 w-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                      }
                    />
                    {errors2.newPassword && (
                      <p className="text-sm text-destructive mt-1">
                        {errors2.newPassword.message}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            )}
          </CardContent>
          <CardFooter className="flex flex-col items-stretch gap-4">
            {step === 1 ? (
              <Button
                form="forget-form-1"
                type="submit"
                disabled={!isValid1 || forgetPasswordReqIsLoading}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white transition duration-200 ease-in-out"
              >
                {forgetPasswordReqIsLoading ? "Loading..." : "Reset Password"}
              </Button>
            ) : (
              <>
                <Button
                  form="forget-form-2"
                  type="submit"
                  disabled={!isValid2 || confirmPasswordRetrievalReqIsLoading}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white transition duration-200 ease-in-out"
                >
                  {confirmPasswordRetrievalReqIsLoading
                    ? "Loading..."
                    : "Reset Password"}
                </Button>
                <Button
                  variant="outline"
                  onClick={resendBtnClickHandler}
                  disabled={secondsLeft > 0}
                  className="w-full"
                >
                  {secondsLeft > 0
                    ? `Resend in ${secondsLeft} seconds`
                    : "Resend code"}
                </Button>
              </>
            )}
            <div className="text-sm text-center">
              Remember your password?{" "}
              <Link to="/login" className="text-blue-600 hover:underline">
                Log in
              </Link>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default ForgetPassword;
