import { useState, useEffect } from "react";
import useDebounce from "../../hooks/use-debounce";

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  const debouncedValue = useDebounce(value, debounce);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  const inputChangeHandler = (event) => {
    setValue(event.target.value);
  };

  return <input {...props} value={value} onChange={inputChangeHandler} />;
};

export default DebouncedInput;
