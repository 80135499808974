import { useState, useRef, useEffect, useCallback } from "react";

const useTimer = (
  seconds,
  { startAutomatically = false, direction = "asc" } = {}
) => {
  const initialValue = direction === "asc" ? 0 : seconds;

  // State to hold the current value of the timer
  const [secondsLeft, setSeconds] = useState(initialValue);

  // Ref to store the timer ID returned by setInterval
  const timerId = useRef();

  // Function to start the timer
  const startTimer = useCallback(() => {
    timerId.current = setInterval(() => {
      // Update the timer value based on the direction (ascending or descending)
      setSeconds((prevValue) =>
        direction === "asc" ? prevValue + 1 : prevValue - 1
      );
    }, 1000);
  }, [direction]);

  // Function to stop the timer
  const stopTimer = useCallback(() => {
    clearInterval(timerId.current);
    timerId.current = 0;
  }, []);

  // Function to restart the timer
  const restartTimer = useCallback(() => {
    setSeconds(initialValue);
    startTimer();
  }, [initialValue, startTimer]);

  // Effect to start or stop the timer based on the 'startAutomatically' prop
  useEffect(() => {
    if (startAutomatically) {
      startTimer();
    }

    // Clean up the timer when the component unmounts or when 'startAutomatically' changes
    return () => {
      stopTimer();
    };
  }, [startAutomatically, stopTimer, startTimer]);

  // Effect to check if the timer has reached the specified value and stop it
  useEffect(() => {
    if (
      (direction === "asc" && secondsLeft >= seconds) ||
      (direction === "desc" && secondsLeft <= 0)
    ) {
      stopTimer();
    }
  }, [secondsLeft, seconds, direction, stopTimer]);

  // Listen for changes in the 'initialSeconds' prop and reset the timer
  useEffect(() => {
    setSeconds(initialValue);
  }, [initialValue]);

  return { secondsLeft, startTimer, stopTimer, restartTimer };
};

export default useTimer;
