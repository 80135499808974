import React, { useEffect, useState } from "react";
import { Button } from "../../../components/UI/Shadcn/Button";
import { Skeleton } from "../../../components/UI/Shadcn/Skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/UI/Shadcn/Table";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/UI/Shadcn/Card";
import {
  AlertCircle,
  Eye,
  FileText,
  Play,
  Download,
  Loader2,
  Lock,
} from "lucide-react";
import { ScrollArea } from "../../../components/UI/Shadcn/ScrollArea";
import { Badge } from "../../../components/UI/Shadcn/Badge";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/UI/Shadcn/Dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/UI/Shadcn/Tabs";
import Measurements from "./ExpDesComponents/Measurements";
import Csv from "./ExpDesComponents/Csv";
import { useToast } from "../../../hooks/use-toast";

const ExperimentalDesigns = ({
  experimentalDesigns,
  trainingDoeId,
  accessToken,
  isViewOnly,
  onUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [designDetails, setDesignDetails] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [startingDesign, setStartingDesign] = useState(null);
  const [designs, setDesigns] = useState(experimentalDesigns);
  const { toast } = useToast();

  useEffect(() => {
    setDesigns(experimentalDesigns);
  }, [experimentalDesigns]);

  const handleGetDesignDetails = async (experimentalDesignId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/${trainingDoeId}/${experimentalDesignId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setDesignDetails(data);
      setSelectedDesign(data);
    } catch (err) {
      console.error(`Failed to fetch design details: ${err.message}`);
      setError(err.message);
    }
  };

  const handleAddMeasurement = async (newMeasurement) => {
    if (isViewOnly || selectedDesign.finished) {
      toast({
        title: "Error",
        description:
          "Cannot add measurements in view-only mode or to a finished design.",
        variant: "destructive",
      });
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/measurements`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            trainingDoeId,
            experimentalDesignId: selectedDesign.experimentalDesignId,
            measures: [newMeasurement],
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      await handleGetDesignDetails(selectedDesign.experimentalDesignId);
      onUpdate();
    } catch (err) {
      console.error(`Failed to add measurement: ${err.message}`);
      setError(err.message);
    }
  };

  const handleDownloadPDF = async (experimentalDesignId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/download-pdf/${trainingDoeId}/${experimentalDesignId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Response Headers:", response.headers);

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const contentDisposition = response.headers.get("Content-Disposition");
      let fileName = `experimental_design_${experimentalDesignId}.pdf`;

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(`Failed to download PDF: ${err.message}`);
      setError(err.message);
    }
  };

  const handleStartDesign = async (experimentalDesignId) => {
    if (isViewOnly) {
      toast({
        title: "Error",
        description: "Cannot start design in view-only mode.",
        variant: "destructive",
      });
      return;
    }

    setStartingDesign(experimentalDesignId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AI_DATA_MANAGEMENT_API_URL}/cultzyme-ai-data-management-api/v1/trainings/does/experimental-designs/start`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            trainingDoeId,
            experimentalDesignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      const updatedDesigns = designs.map((design) =>
        design.experimentalDesignId === data.experimentalDesignId
          ? { ...design, ...data }
          : design
      );
      setDesigns(updatedDesigns);
      onUpdate();
    } catch (err) {
      console.error(`Failed to start design: ${err.message}`);
      setError(err.message);
    } finally {
      setStartingDesign(null);
    }
  };

  const handleFinishDesign = async () => {
    if (isViewOnly) {
      toast({
        title: "Error",
        description: "Cannot finish design in view-only mode.",
        variant: "destructive",
      });
      return;
    }
    await onUpdate();
  };

  const getExperimentTitle = (content) => {
    if (!content) return "Untitled Experiment";
    const lines = content.split("\n");
    for (let line of lines) {
      if (line.startsWith("Experimental Design:")) {
        return line.replace("Experimental Design:", "").trim();
      }
    }
    return lines[0].substring(0, 30) + "...";
  };

  const getStatusBadge = (design) => {
    if (design.finished) {
      return <Badge variant="success">Completed</Badge>;
    } else if (design.startDate) {
      return <Badge variant="warning">In Progress</Badge>;
    } else {
      return <Badge variant="secondary">Not Started</Badge>;
    }
  };

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Experimental Designs</CardTitle>
          <CardDescription>Loading experimental designs...</CardDescription>
        </CardHeader>
        <CardContent>
          <Skeleton className="w-full h-[20px] m-4" />
          <Skeleton className="w-full h-[20px] m-4" />
          <Skeleton className="w-full h-[20px] m-4" />
          <Skeleton className="w-full h-[20px] m-4" />
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Experimental Designs</CardTitle>
          <CardDescription>An error occurred</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex items-center space-x-2 text-red-500">
            <AlertCircle size={20} />
            <p>{error}</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Experimental Designs</CardTitle>
        <CardDescription>
          View and manage your experimental designs
          {isViewOnly && (
            <span className="ml-2 text-primary">
              <Lock className="inline-block h-4 w-4 mr-1 text-primary" />
              View Only Mode
            </span>
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px]">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Experiment</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>Finish Date</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>File</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {designs.map((design) => (
                <TableRow key={design.experimentalDesignId}>
                  <TableCell className="font-medium">
                    {getExperimentTitle(design.content)}
                  </TableCell>
                  <TableCell>
                    {design.startDate
                      ? new Date(design.startDate).toLocaleDateString()
                      : "Not started"}
                  </TableCell>
                  <TableCell>
                    {design.finishDate
                      ? new Date(design.finishDate).toLocaleDateString()
                      : "Not finished"}
                  </TableCell>
                  <TableCell>{getStatusBadge(design)}</TableCell>
                  <TableCell>
                    {design.excelFileName ? (
                      <div className="flex items-center space-x-2">
                        <FileText size={16} />
                        <span>{design.excelFileName}</span>
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      {!design.startDate && !design.finished && !isViewOnly && (
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() =>
                            handleStartDesign(design.experimentalDesignId)
                          }
                          disabled={
                            startingDesign === design.experimentalDesignId
                          }
                          className="bg-primary hover:bg-primary/80 text-background"
                        >
                          {startingDesign === design.experimentalDesignId ? (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          ) : (
                            <Play className="mr-2 h-4 w-4" />
                          )}
                          {startingDesign === design.experimentalDesignId
                            ? "Starting..."
                            : "Start"}
                        </Button>
                      )}
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              handleGetDesignDetails(
                                design.experimentalDesignId
                              )
                            }
                          >
                            <Eye className="h-4 w-4" />
                          </Button>
                        </DialogTrigger>
                        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
                          <DialogHeader>
                            <DialogTitle>
                              {getExperimentTitle(design.content)}
                            </DialogTitle>
                            <DialogDescription>
                              View details and measurements for this experiment
                              {(design.finished || isViewOnly) && (
                                <span className="ml-2 text-yellow-500">
                                  <Lock className="inline-block h-4 w-4 mr-1" />
                                  View Only
                                </span>
                              )}
                            </DialogDescription>
                          </DialogHeader>
                          {selectedDesign && (
                            <Tabs defaultValue="description" className="w-full">
                              <TabsList>
                                <TabsTrigger value="description">
                                  Description
                                </TabsTrigger>
                                <TabsTrigger value="measurements">
                                  Measurements
                                </TabsTrigger>
                                {(design.finished || design.startDate) && (
                                  <TabsTrigger value="csv">
                                    {design.finished ? "CSV's" : "Finish"}
                                  </TabsTrigger>
                                )}
                              </TabsList>
                              <TabsContent value="description">
                                <ScrollArea className="h-[400px] w-full rounded-md border p-4">
                                  <pre className="whitespace-pre-wrap">
                                    {selectedDesign.content}
                                  </pre>
                                </ScrollArea>
                              </TabsContent>
                              <TabsContent value="measurements">
                                <Measurements
                                  measurements={selectedDesign.measurements}
                                  accessToken={accessToken}
                                  trainingDoeId={trainingDoeId}
                                  experimentalDesignId={
                                    selectedDesign.experimentalDesignId
                                  }
                                  isViewOnly={design.finished || isViewOnly}
                                  onAddMeasurement={handleAddMeasurement}
                                />
                              </TabsContent>
                              {(design.finished || design.startDate) && (
                                <TabsContent value="csv">
                                  <Csv
                                    trainingDoeId={trainingDoeId}
                                    experimentalDesignId={
                                      selectedDesign.experimentalDesignId
                                    }
                                    accessToken={accessToken}
                                    isViewOnly={design.finished || isViewOnly}
                                    onFinish={handleFinishDesign}
                                  />
                                </TabsContent>
                              )}
                            </Tabs>
                          )}
                        </DialogContent>
                      </Dialog>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() =>
                          handleDownloadPDF(design.experimentalDesignId)
                        }
                      >
                        <Download className="h-4  w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default ExperimentalDesigns;
