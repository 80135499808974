import * as yup from "yup";

const ForgetPasswordSchema1 = yup.object({
  usernameOrEmail: yup.string().required("Username is a required field"),
});

const ForgetPasswordSchema2 = yup.object({
  newPassword: yup.string().required("Password is a required field"),
  confirmationCode: yup.string(),
});

const ForgetPasswordSchema = { ForgetPasswordSchema1, ForgetPasswordSchema2 };

export default ForgetPasswordSchema;
