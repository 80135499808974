import { useRef } from "react";
import { motion } from "framer-motion";

import ModalWrapper from "../../../Wrappers/ModalWrapper/ModalWrapper";

import styles from "./UpdateProcess.module.css";

import { useModifyProcessDataMutation } from "../../../../features/process/processApiSlice";

const UpdateProcess = ({ bionId, process, onClose }) => {
  const inputRef = useRef();
  const descriptionRef = useRef();

  const [modifyProcessDataReq] = useModifyProcessDataMutation();

  const saveDataHandler = async () => {
    await modifyProcessDataReq({
      processId: process.processId,
      processName: inputRef.current.value,
      description: descriptionRef.current.value,
      bionId: bionId,
    });
    onClose();
  };

  return (
    <ModalWrapper onClose={onClose}>
      <div className={styles["finish-process__modal"]}>
        <h4>Update Process</h4>

        <div style={{ marginBottom: "12px" }}>
          <label htmlFor="processName">Process Name:</label>
          <input
            ref={inputRef}
            name="processName"
            id="processName"
            type="text"
            defaultValue={process.processName}
          />
        </div>

        <div>
          <label htmlFor="processDescription">Description</label>
          <textarea
            ref={descriptionRef}
            name="processDescription"
            id="processDescription"
            defaultValue={process.description}
          ></textarea>
        </div>

        <div className={styles["action-btns"]}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            onClick={onClose}
          >
            Cancel
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 1 }}
            onClick={saveDataHandler}
          >
            Save
          </motion.button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UpdateProcess;
