import { useState, useEffect } from "react";
import Switch from "react-switch";
import InputSlider from "react-input-slider";
import { AnimatePresence, motion, animate } from "framer-motion";

import ActuatorButton from "../../ActuatorButton/ActuatorButton";

import TemperatureImg from "../../../../../assets/images/Actuators/temperature.png";

import styles from "./Temperature.module.css";
import ModalWrapper from "../../../../Wrappers/ModalWrapper/ModalWrapper";

const TEMPERATURE_MIN = 0;
const TEMPERATURE_MAX = 80;

const TemperatureModal = ({ process, sendActuatorData, onClose }) => {
  const [temperature, setTemperature] = useState(process.temperatureValue ?? 0);
  const [temperatureState, setTemperatureState] = useState(
    !!process.temperatureState
  );

  // Animation related state variables
  const [modalAnimationComplete, setModalAnimationComplete] = useState(false);
  const [sliderAnimationComplete, setSliderAnimationComplete] = useState(false);

  const sendActuatorDataHandler = () => {
    sendActuatorData({
      actuatorName: "temperature",
      temperatureValue: temperature,
      temperatureState: temperatureState,
    });
    onClose();
  };

  const modalAnimationCompleteHandler = () => {
    setModalAnimationComplete(true);
  };

  // Determine if the expandable area should be shown
  const showExpandableArea = temperatureState && modalAnimationComplete;

  // Determine if the data is valid for submission
  const validForSubmit =
    (temperatureState !== process.temperatureState ||
      temperature !== process.temperatureValue) &&
    sliderAnimationComplete;

  // Use useEffect for animations when the expandable area is shown
  useEffect(() => {
    if (showExpandableArea) {
      animate(0, process.temperatureValue ?? 0, {
        onUpdate: (latestValue) => setTemperature(Math.trunc(latestValue)),
        onComplete: () => setSliderAnimationComplete(true),
        duration: 0.6,
        ease: "easeIn",
      });
    }
  }, [showExpandableArea, process.gasserValue]);

  const temperatureStateChangeHandler = (checked) => {
    setTemperatureState(checked);
  };

  return (
    <ModalWrapper
      onClose={onClose}
      onAnimationComplete={modalAnimationCompleteHandler}
    >
      <div className={styles["temperature-actuator__modal"]}>
        <header>
          <h4>Temperature Actuator Configuration</h4>
          <div className={styles["actuator-decoration"]}>
            <img src={TemperatureImg} alt="Temperature" />
          </div>
        </header>

        {/* Main Modal Content */}
        <div>
          <p className={styles["info-text-1"]}>
            Configure the temperature settings for your bioreactor.
          </p>

          <div className={styles["switch__container"]}>
            <Switch
              checked={temperatureState}
              onChange={temperatureStateChangeHandler}
            />
            <p>Toggle the switch to turn the Temperature Actuator</p>
          </div>

          <AnimatePresence>
            {showExpandableArea && (
              <motion.div
                initial={{ opacity: 0, height: 0, y: -25 }}
                animate={{ opacity: 1, height: "auto", y: 0 }}
                exit={{
                  opacity: 0,
                  height: 0,
                  y: -25,
                  transition: { height: { delay: 0.2 } },
                }}
                transition={{ opacity: { delay: 0.2 }, y: { delay: 0.2 } }}
                className={styles["expandable-area"]}
              >
                <p className={styles["info-text-2"]}>
                  Use the slider to set the desired temperature in degrees
                  Celsius.
                </p>

                <div className={styles["input-slider__container"]}>
                  <span>
                    {temperature}
                    <sup>o</sup>C
                  </span>
                  <InputSlider
                    xmin={TEMPERATURE_MIN}
                    xmax={TEMPERATURE_MAX}
                    x={temperature}
                    onChange={({ x }) => setTemperature(x)}
                    styles={{
                      track: {
                        backgroundColor: "#151414",
                        width: "100%",
                        height: "36px",
                        border: "1px solid #656565",
                        cursor: "pointer",
                        overflow: "hidden",
                      },
                      active: {
                        backgroundColor: "rgba(91, 91, 91, 0.20)",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                      },
                      thumb: {
                        width: "1px",
                        height: "34px",
                        backgroundColor: "#656565",
                      },
                    }}
                  />
                </div>

                <p className={styles["info-text-3"]}>
                  Click the "Set" button to save your stirring configuration.
                </p>
              </motion.div>
            )}
          </AnimatePresence>
          <button
            className={`${styles["submit-btn"]} ${
              validForSubmit ? styles["active"] : ""
            }`}
            disabled={!validForSubmit}
            onClick={sendActuatorDataHandler}
          >
            SET
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

const Temperature = ({ process, sendActuatorData }) => {
  const temperatureIsEnabled = !!process.temperatureState;
  return (
    <ActuatorButton
      enabled={temperatureIsEnabled}
      renderLabel={() => {
        return (
          <div className={styles["label"]}>
            {/* <h5 className={styles["title"]}>Temperature</h5> */}
            <div className={styles["row"]}>
              <img src={TemperatureImg} alt="Temperature" />
              <div>
                <p className={styles["label__info"]}>
                  Value:{" "}
                  <span>
                    {process?.temperatureState ? (
                      <p>
                        {process.temperatureValue}
                        <sup>o</sup>C
                      </p>
                    ) : (
                      "N/S"
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      }}
      showChildrenOnSingleClick
    >
      <TemperatureModal process={process} sendActuatorData={sendActuatorData} />
    </ActuatorButton>
  );
};

export default Temperature;
