import { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { useGetReadyBionsQuery } from "../../../features/bion/bionApiSlice";
import LoadingText from "../../../components/UI/LoadingText/LoadingText";

import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";

import { useUpdateCalibrationMutation } from "../../../features/bion/bionApiSlice";

import { motion } from "framer-motion";

import {
  useGetFinishedUserProcessesQuery,
  useDeleteUserProcessesMutation,
  useStartProcessReplicationMutation,
} from "../../../features/process/processApiSlice";

import { BsCalendar2DateFill as DateIcon } from "react-icons/bs";
import { GiChemicalDrop as ProcessIcon } from "react-icons/gi";
import { IoPricetags as TypeIcon } from "react-icons/io5";
import { FaClone as ReplicationIcon } from "react-icons/fa";
import { SlEnergy as ActionsIcon } from "react-icons/sl";
import { GiDuration as DurationIcon } from "react-icons/gi";
import { RiDeleteBin6Fill as DeleteIcon } from "react-icons/ri";
import { TbViewfinder as ViewIcon } from "react-icons/tb";
import { MdOutlineStairs as ScaleIcon } from "react-icons/md";
import { FaShareAlt as ShareIcon } from "react-icons/fa";

import DebouncedInput from "../../../components/General/DebouncedInput";
import CalibrateModal from "../../../components/Bion/CalibrateModal/CalibrateModal";

import styles from "./Files.module.css";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ModalWrapper from "../../../components/Wrappers/ModalWrapper/ModalWrapper";

const columnHelper = createColumnHelper();

function millisecondsToDurationString(milliseconds) {
  // Ensure the input is a positive number
  milliseconds = Math.abs(milliseconds);

  // Define time constants
  const oneSecond = 1000;
  const oneMinute = 60 * oneSecond;
  const oneHour = 60 * oneMinute;
  const oneDay = 24 * oneHour;
  const oneWeek = 7 * oneDay;

  // Calculate weeks, days, hours, minutes, and seconds
  const weeks = Math.floor(milliseconds / oneWeek);
  milliseconds -= weeks * oneWeek;
  const days = Math.floor(milliseconds / oneDay);
  milliseconds -= days * oneDay;
  const hours = Math.floor(milliseconds / oneHour);
  milliseconds -= hours * oneHour;
  const minutes = Math.floor(milliseconds / oneMinute);
  milliseconds -= minutes * oneMinute;
  const seconds = Math.floor(milliseconds / oneSecond);

  // Build a compact format string
  let result = [];
  if (weeks > 0) result.push(weeks + "W");
  if (days > 0) result.push(days + "D");
  if (hours > 0) result.push(hours + "H");
  if (minutes > 0) result.push(minutes + "M");
  if (seconds > 0) result.push(seconds + "S");

  return result.join(" ");
}

const Files = () => {
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [calibrationIsOpen, setCalibrationIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [contaminationModalIsOpen, setContaminationModalIsOpen] = useState({
    open: false,
    processId: null,
  });

  const location = useLocation();
  const bionId = new URLSearchParams(location.search).get("bionId");

  const selectBionById = useMemo(() => {
    const emptyObject = {};

    return createSelector(
      (result) => result.data,
      (bions, bionId) => bionId,
      (bions, bionId) =>
        bions?.find((bion) => bion.bionId === bionId) ?? emptyObject
    );
  }, []);

  const { bion } = useGetReadyBionsQuery(undefined, {
    selectFromResult: (result) => ({
      // ...result,
      bion: selectBionById(result, bionId),
    }),
  });

  const [updateCalibrationReq] = useUpdateCalibrationMutation();
  const [
    startProcessReplicationReq,
    { isLoading: startProcessReplicationReqIsLoading },
  ] = useStartProcessReplicationMutation();
  const [deleteUserProcessesReq] = useDeleteUserProcessesMutation();

  const navigate = useNavigate();

  const [selectedProcessId, setSelectedProcessId] = useState();
  const [selectedProcessIds, setSelectedProcessIds] = useState([]);

  const { data: finishedProcesses } = useGetFinishedUserProcessesQuery();

  // console.log(finishedProcesses);

  const columns = [
    columnHelper.display({
      id: "processId",
      header: (props) => {
        return (
          <div>
            <input
              type="checkbox"
              checked={selectedProcessIds.length === finishedProcesses.length}
              onChange={() => {
                if (finishedProcesses.length === selectedProcessIds.length) {
                  setSelectedProcessIds([]);
                } else {
                  const allIds = finishedProcesses.map(
                    (process) => process.processId
                  );
                  setSelectedProcessIds(allIds);
                }
              }}
            />
          </div>
        );
      },
      cell: (props) => {
        return (
          <div>
            <input
              type="checkbox"
              checked={selectedProcessIds.includes(
                props.row.original.processId
              )}
              onChange={() => {
                if (selectedProcessIds.includes(props.row.original.processId)) {
                  setSelectedProcessIds(
                    selectedProcessIds.filter(
                      (processId) => processId !== props.row.original.processId
                    )
                  );
                } else {
                  setSelectedProcessIds((prevValue) => [
                    ...prevValue,
                    props.row.original.processId,
                  ]);
                }
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "processName",
      header: (
        <p className={styles["row"]}>
          <ProcessIcon />
          Name
        </p>
      ),
      cell: (props) => {
        const { index } = props.row;
        const { processName } = props.row.original;
        return finishedProcesses[index].isPolluted ? (
          <p>
            {processName} <span style={{ color: "red" }}>(Contaminated)</span>
          </p>
        ) : (
          processName
        );
      },
    }),
    // columnHelper.accessor("processName", {
    //   header: (
    //     <p className={styles["row"]}>
    //       <ProcessIcon />
    //       Name
    //     </p>
    //   ),
    // }),
    columnHelper.accessor("processTypeDescription", {
      header: (
        <p className={styles["row"]}>
          <TypeIcon />
          Type
        </p>
      ),
    }),
    columnHelper.accessor("startDate", {
      header: (
        <p className={styles["row"]}>
          <DateIcon />
          Start Date
        </p>
      ),
      cell: ({ getValue }) => new Date(getValue()).toLocaleString(),
    }),
    columnHelper.accessor("endDate", {
      header: (
        <p className={styles["row"]}>
          <DateIcon />
          End Date
        </p>
      ),
      cell: ({ getValue }) => new Date(getValue()).toLocaleString(),
    }),
    columnHelper.accessor("duration", {
      header: (
        <p className={styles["row"]}>
          <DurationIcon />
          Duration
        </p>
      ),
      cell: ({ getValue }) => millisecondsToDurationString(getValue()),
    }),
    columnHelper.accessor("replicatedTimes", {
      header: (
        <p className={styles["row"]}>
          <ReplicationIcon />
          Times Replicated
        </p>
      ),
      cell: ({ getValue }) => getValue() || 0,
    }),
    columnHelper.display({
      id: "actions",
      header: (
        <p className={styles["row"]}>
          <ActionsIcon />
          Actions
        </p>
      ),
      cell: (props) => {
        const { processId } = props.row.original;
        return (
          <div className={styles["action-row"]}>
            {bionId && (
              <button
                onClick={() => {
                  // setCalibrationIsOpen(true);
                  // setSelectedProcessId(processId);
                  // replicateProcessHandler(processId);
                  if (
                    finishedProcesses.find(
                      (process) => process.processId === processId
                    ).isPolluted
                  ) {
                    setContaminationModalIsOpen({
                      open: true,
                      processId: processId,
                    });
                  } else {
                    replicateProcessHandler(processId);
                  }
                }}
              >
                Replicate
              </button>
            )}
            <Link to={`/processes/${processId}`}>
              <ViewIcon /> View
            </Link>
            <button
              className={styles["scale-btn"]}
              onClick={() => {
                setModalIsOpen(true);
              }}
            >
              <ShareIcon />
              Share! <span>Comming Soon!</span>
            </button>
            <button className={styles["scale-btn"]}>
              <ScaleIcon />
              Scale Up <span>Comming Soon</span>
            </button>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: finishedProcesses,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const closeCalibration = () => {
    setCalibrationIsOpen(false);
    updateCalibrationReq({
      bionId: bionId,
      calibrationActive: false,
      sensorType: "PH",
    });
  };

  const replicateProcessHandler = async (processId) => {
    await startProcessReplicationReq({
      bionId: bionId,
      processId: processId,
    });
    navigate(`/bions/${bionId}`);
  };

  if (!finishedProcesses?.length) return <p>Loading...</p>;

  return (
    <>
      <AnimatePresence>
        {modalIsOpen && (
          <ModalWrapper onClose={setModalIsOpen.bind(null, false)}>
            <div className={styles["finish-process__modal"]}>
              <h4>Discover Exciting Opportunities!</h4>

              <h5>Get in Touch:</h5>
              <p className={styles["info"]}>
                Ready to book a meeting? Click "Get in Touch" to connect with
                us. We've prepared a special link just for you to make booking a
                breeze.
              </p>
              <h5>Take Our Survey:</h5>
              <p className={styles["info"]}>
                Share your thoughts with us! Click the other button to
                participate in our survey. Your feedback is invaluable, and we
                appreciate you taking the time to help us improve.
              </p>

              <div className={styles["action-btns"]}>
                <a
                  href="https://outlook.office.com/bookwithme/user/7eac6cadb9ce4278919ee4af4e1f2cf8%40cultzyme.com/meetingtype/UeR2LWJO0kmSjwOjuU99WA2?getrequesturl=https%3a%2f%2foutlook.office.com%2fows%2fOID%3a7c4a54bc-9b07-4c0b-a84d-ae8464bebcdd%40ae604f48-b816-42f1-bb68-1c522245b804%2fbeta%2fBookWithMe%2fCheckBookable%3fbookingcode%3d02c9c784-4d9a-40ca-b371-2479599848f3%26itemid%3dUeR2LWJO0kmSjwOjuU99WA2%26authtoken%3deyJhbGciOiJSUzI1NiIsImtpZCI6ImszWXdXK3p0c0YrWU9BQkxhK25PUDJZWlJ6TT0iLCJ0eXAiOiJKV1QiLCJ4NXQiOiJWX0IxcjA0SzdsWm9UdDU3YS1CbkVWOUJaQnMifQ.eyJvaWQiOiI3YzRhNTRiYy05YjA3LTRjMGItYTg0ZC1hZTg0NjRiZWJjZGQiLCJ2ZXIiOiJSZXNvdXJjZUxvb3BiYWNrLlVzZXIuVjEiLCJzY3AiOiJPcGVuQm9vay1JbnRlcm5hbC5SZWFkV3JpdGUiLCJyc2NvcGVsZW4iOiIyMTgiLCJyZXNvdXJjZV9zY29wZSI6IntcIlVSTFwiOlwiaWhBcUYrcGVxMEpFYzVsWjdnTEJyclNxN25aSndiMzJpM21GODQ2OUlERT1cIn0iLCJjb3JyaWQiOiIyNzVkYjdmYS0zYzU3LTRmMTktYTVkMS1mZWZhMDUwYjgzZjMiLCJhcHBpZCI6IjE1N2NkZmJmLTczOTgtNGE1Ni05NmMzLWU5M2U5YWIzMDliNSIsImFwcGlkYWNyIjoiMCIsInRpZCI6ImFlNjA0ZjQ4LWI4MTYtNDJmMS1iYjY4LTFjNTIyMjQ1YjgwNCIsImlhdCI6MTcwMTE4MDQxNiwibmJmIjoxNzAxMTgwNDE2LCJleHAiOjE3MDg5NTY0MTYsImlzcyI6Imh0dHBzOi8vcmVzb3VyY2Uuc2VsZi8iLCJhdWQiOiJodHRwczovL291dGxvb2sub2ZmaWNlLmNvbSJ9.RK5NqWHyzIQpxou6CyzWwKm5vRNM3FhhDqiePAe6npULycUK5Npy9HARf-JbIINMy3UOeZA4ttbvtYFt_ssOCWDUMcCViLsPX2PnH9l0Y1YzogOzbZjmkWyjB8NS1pnMgPQPdvPq1ZQzf7edFw3kWqsXqv2ETEIBgyxSrTx6K8JihU4eQKVohfiXfagcvFYWwE5ddEbiEOmNcQr5BtxDb7aS4U_sJy63sWnp9f4QSMni7BY6dUADawHm7dSvWuOPxLD0K_whqQ7r_rYiz47LdehqOHW84SlgO8Y6WFwDLavdVZAQP4ieUzv8PTfrUtu4bni9uFR3iYQWdBGAkno7_w&anonymous"
                  rel="noreferrer"
                  target="_blank"
                >
                  Get in Touch
                </a>
                <a
                  href="https://us21.list-manage.com/survey?u=3c9a75d368a60cd350eab1916&id=4a300d474d&attribution=false"
                  rel="noreferrer"
                  target="_blank"
                >
                  Take Our Survey
                </a>
              </div>
            </div>
          </ModalWrapper>
        )}
        {/* {calibrationIsOpen && (
          <CalibrateModal
            bion={bion}
            onClose={closeCalibration}
            createProcess={replicateProcessHandler}
          />
        )} */}
      </AnimatePresence>
      <AnimatePresence>
        {contaminationModalIsOpen.open && (
          <ModalWrapper
            onClose={setContaminationModalIsOpen.bind(null, {
              open: false,
              processId: null,
            })}
          >
            <div className={styles["finish-process__modal"]}>
              <h4>Contaminated Process</h4>
              <p className={styles["info"]}>
                Are you sure you want to replicate a contaminated process?
              </p>

              <div className={styles["action-btns"]}>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 1 }}
                  transition={{ type: "spring", duration: 0.5, bounce: 0.5 }}
                  onClick={() => {
                    setContaminationModalIsOpen({
                      open: false,
                      processId: null,
                    });
                  }}
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 1 }}
                  transition={{ type: "spring", duration: 0.5, bounce: 0.5 }}
                  onClick={() => {
                    replicateProcessHandler(contaminationModalIsOpen.processId);
                  }}
                >
                  {startProcessReplicationReqIsLoading ? (
                    <LoadingText />
                  ) : (
                    "Replicate"
                  )}
                </motion.button>
              </div>
            </div>
          </ModalWrapper>
        )}
        {/* {calibrationIsOpen && (
          <CalibrateModal
            bion={bion}
            onClose={closeCalibration}
            createProcess={replicateProcessHandler}
          />
        )} */}
      </AnimatePresence>
      <div className={styles["page"]}>
        <h1 className={styles["title"]}>Processes</h1>
        <h2 className={styles["subtitle"]}>
          Explore Your Processes: Your journey of bioprocessing unfolds on this
          page, where a comprehensive table showcases a history of your past
          operations. Gain insights into each process's performance, and take
          control with options to replicate successful procedures, scale up
          operations for increased efficiency, or delve deeper into the details.
          This centralized hub empowers you to manage, replicate, and optimize
          your bioreactor processes with ease. Your bioprocessing legacy begins
          here.
        </h2>

        <div
          className={styles["row"]}
          style={{ justifyContent: "space-between" }}
        >
          <DebouncedInput
            value={globalFilter}
            onChange={(filter) => setGlobalFilter(filter)}
            className={styles["search-input"]}
            placeholder="Search all columns"
          />
          {selectedProcessIds.length ? (
            <div>
              <div>
                <button
                  className={styles["row"]}
                  style={{ color: "red" }}
                  onClick={() => {
                    deleteUserProcessesReq({ processIds: selectedProcessIds });
                    setSelectedProcessIds([]);
                  }}
                >
                  <DeleteIcon />
                  Delete {selectedProcessIds.length}{" "}
                  {selectedProcessIds.length > 1 ? "processes" : "process"}
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <table className={styles["table"]}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    // {...{
                    //   className: header.column.getCanSort()
                    //     ? "cursor-pointer select-non"
                    //     : "",
                    // }}
                  >
                    <div className={styles["row"]}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                  // Code for sorting ASC and DESC sorting
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Files;
